import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, NgZone, ErrorHandler, Input, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core'
import { FormControl, FormGroup, NgForm } from '@angular/forms'

import { deleteField } from '@firebase/firestore'

import { Observable, combineLatest, BehaviorSubject, of, firstValueFrom, Subject, timer } from 'rxjs'
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators'
import { lemonShare } from '../../_jaettu-angular/_rxjs/lemon-share.operator'

import { MatInput } from '@angular/material/input'

import { DateService } from 'app/_shared-core/service/date.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'
import { KirjanpitoJaettuService } from 'app/_jaettu-lemonator/service/kirjanpito-jaettu.service'
import { KlikattuKirjaus } from '../raportit.component'
import {
  RaporttiRequest,
  RaporttiPdfResponse,
  RaporttiTaselaskelmaData,
  RaporttiTaselaskelmaDataResponse,
  RaporttiTuloslaskelmaData,
  RaporttiTuloslaskelmaDataResponse,
  TilinpaatosLiitetiedot,
  TilinpaatosLiitetiedotBinaariliite,
  TilinpaatosLiitetiedotKentat,
  TilinpaatosJakokelpoisenOmanPaaomanLisarivi, OsingonjaonTyyppi,
  VertailutietojenLahde,
  OsingotNostettavissaTyyppi,
  LiitetiedotTallennettuTyojonoData,
  LisatietoKentta,
  TilinpaatosLiitetiedotOsio
} from 'app/_jaettu-lemonator/model/kirjanpito'
import { RaporttiType } from 'app/_jaettu/model/reports-shared'

import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { Tilikausi } from 'app/_jaettu-lemonator/model/asiakas'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DebugService } from 'app/_angular/service/debug.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'

import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop'
import { TiedostojenLataamisService } from 'app/_jaettu-angular/service/tiedostojen-lataamis.service'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { MatDialog } from '@angular/material/dialog'
import { InfoDialog, InfoDialogData } from '../../_jaettu-angular/_components/info.dialog'
import { KnowYourCustomer } from 'app/_jaettu/model/kayttaja'
import { KopioijaPalvelu } from 'app/_jaettu/service/kopioija.service'
import { TilinpaatosTranslationService } from 'app/_jaettu-lemonator/service/tilinpaatos-translation.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { TallennaArvoEvent } from './liitetiedot-checkbox.component'
import { LocalDate, NumberDate, PaivamaaraAikavali } from 'app/_shared-core/model/common'
import { KycUriService } from 'app/_jaettu/service/kyc-uri.service'
import { FirebaseLemonator, FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'
import { TilinpaatosStatus } from 'app/_jaettu-lemonator/model/tilinpaatos'
import { TilinpaatosLiitetiedotJaettuService } from 'app/_jaettu-lemonator/service/tilinpaatos-liitetiedot-jaettu.service'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'
import { AreYouSureDialog } from 'app/_jaettu-angular/_components/are-you-sure.dialog'
import { animate, state, style, transition, trigger } from '@angular/animations'

interface LiitetiedotJaTallentamattomatMuutokset {
  liitetiedot: TilinpaatosLiitetiedot
  tallentamattomat: TilinpaatosLiitetiedot
}

interface LiitetiedotJaTallentamattomatMuutoksetJaLukitusTiedot extends LiitetiedotJaTallentamattomatMuutokset {
  lukittu: boolean
}

interface MainForm {
  loppuu: FormControl<Date>
}

@Component({
  selector: '[app-kirjanpito-tilinpaatos-liitetiedot]',
  templateUrl: './liitetiedot.component.html',
  styleUrls: ['./liitetiedot.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    // the fade-in/fade-out animation.
    trigger('slowInQuickOut', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate('600ms 3s')
      ]),

      // fade out when destroyed. this could also be written as transition('* => void')
      transition(':leave', animate(100, style({ opacity: 0 })))
    ]),
    // the fade-in/fade-out animation.
    trigger('quickInSlowOut', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(100)
      ]),

      // fade out when destroyed. this could also be written as transition('* => void')
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class KirjanpitoTilinpaatosLiitetiedotComponent implements OnInit, OnDestroy {

  @ViewChild('henkilostonMaaraInput', { static: false, read: MatInput }) henkilostonMaaraInput: MatInput
  @ViewChild('osinkoNostettavissaInput', { static: false, read: MatInput }) osinkoNostettavissaInput: MatInput
  @ViewChild('osingonMaaraInput', { static: false, read: MatInput }) osingonMaaraInput: MatInput

  @Input() selectedTilikausiObservable: Observable<Tilikausi>
  @Input() paivitaArvotHiljaisestiObservable: Observable<number>
  @Input() tilinpaatosStatusObservable: Observable<TilinpaatosStatus>
  @Input() nykyisetLiitetiedotTietokannassaObservable: Observable<TilinpaatosLiitetiedot>

  @Output() kirjaustaKlikattiin: EventEmitter<KlikattuKirjaus> = new EventEmitter()

  private _tallentamattomatLiitetiedotSubject: BehaviorSubject<TilinpaatosLiitetiedot> = new BehaviorSubject(this._tilinpaatosLiitetiedotJaettuService.annaTyhjaLiitetiedotObject(null, null))
  private _ngUnsubscribe = new Subject<void>()

  liiteJaLukitustiedotObservable: Observable<LiitetiedotJaTallentamattomatMuutoksetJaLukitusTiedot>
  liitetiedotObservable: Observable<LiitetiedotJaTallentamattomatMuutokset>

  private pvmSaveSuccessSubject: Subject<void> = new Subject<void>()
  pvmSaveSuccessObservable: Observable<boolean> = this.pvmSaveSuccessSubject.pipe(switchMap(() => timer(1000).pipe(map(() => false), startWith(true))))

  private lisatiedotSaveSuccessSubject: Subject<void> = new Subject<void>()
  lisatiedotSaveSuccessObservable: Observable<boolean> = this.lisatiedotSaveSuccessSubject.pipe(switchMap(() => timer(1000).pipe(map(() => false), startWith(true))))
  osionLisarivitSaveSuccessMap: Map<TilinpaatosLiitetiedotOsio, 1> = new Map()

  private _uncachedLiitetiedot: Observable<LiitetiedotJaTallentamattomatMuutokset>
  liitetiedotUriObservable: Observable<string>
  liitetiedotEncodedUriObservable: Observable<string>
  kirjanpitajaOnDevaajaObservable: Observable<boolean> = this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable
  onMikroYritysObservable: Observable<boolean>
  muiltaOsinTekstiObservable: Observable<string>
  jakokelpoinenOmaPaaomaEiRiitaObservable: Observable<boolean>
  osingotSaatetaanMaksaaSamanaVuonnaObservable: Observable<boolean>
  liitteetObservable: Observable<TilinpaatosLiitetiedotBinaariliite[]>
  private _tilikaudetObservable: Observable<{ nykyinen: Tilikausi, edellinen: Tilikausi }>

  readonly osiot = TilinpaatosLiitetiedotOsio
  readonly kentat = TilinpaatosLiitetiedotKentat

  latausVirheObservable: BehaviorSubject<string> = new BehaviorSubject(null)
  namename = 'feopvmsdfg' + Math.random()

  form: FormGroup<MainForm>
  minLoppuuDateObservable: Observable<Date>
  maxLoppuuDateObservable: Observable<Date>

  private _aikavaliSubject: BehaviorSubject<PaivamaaraAikavali> = new BehaviorSubject(null)

  // private _expandedAccountsBehaviorSubject: BehaviorSubject<{ normal: Set<string>, full: Set<string> }> = new BehaviorSubject({ normal: new Set<string>(), full: new Set<string>() })

  // private _tilitMapObservable: Observable<Map<string, Kirjanpitotili>>
  // private _hakuvaihtoehdotSubject: BehaviorSubject<RaporttienHakuvaihtoehdot> = new BehaviorSubject({
  //   alkaa: null,
  //   loppuu: null,
  //   projekti: null,
  //   tiliin: null,
  //   tilista: null,
  //   vapaasanahaku: null
  // })

  constructor(
    private _ngZone: NgZone,
    private _firebase: FirebaseLemonator,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _dateService: DateService,
    private _currencyService: CurrencyService,
    private _asiakasService: AsiakasService,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _kirjanpitoJaettuService: KirjanpitoJaettuService,
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _snackbar: MatSnackBar,
    private _debugService: DebugService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _tiedostojenLataamisService: TiedostojenLataamisService,
    private _tilinpaatosTranslationService: TilinpaatosTranslationService,
    private _timestampService: TimestampService,
    private _dialog: MatDialog,
    private _copyService: KopioijaPalvelu,
    private _formValidationService: FormValidationService,
    private _kycUriService: KycUriService,
    private _tilinpaatosLiitetiedotJaettuService: TilinpaatosLiitetiedotJaettuService,
    private _fileSaverService: FileSaverService,
    private _changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {

    const loppuuCtrl = new FormControl<Date>(null)
    this.form = new FormGroup<MainForm>({
      loppuu: loppuuCtrl
    })

    this.minLoppuuDateObservable = this.selectedTilikausiObservable.pipe(
      map(tilikausi => {
        return tilikausi ? this._dateService.localDateToDate(tilikausi.alkaa) : null
      })
    )

    this.maxLoppuuDateObservable = this.selectedTilikausiObservable.pipe(
      map(tilikausi => {
        return tilikausi ? this._dateService.localDateToDate(tilikausi.loppuu) : null
      })
    )

    // this.maksutapojenTunnisteetJoissaOnKirjauksiaObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this.selectedTilikausiObservable]).pipe(
    //   switchMap(([asiakas, tilikausi]) => {
    //     if (asiakas && tilikausi) {
    //       const request: MaksutavatJoissaOnKirjauksiaRequest = {
    //         asiakasAvain: asiakas.avain,
    //         alkaa: this._dateService.localDateToNumber(tilikausi.alkaa),
    //         loppuu: this._dateService.localDateToNumber(tilikausi.loppuu)
    //       }
    //       const callable = this._firebase.httpsCallable<MaksutavatJoissaOnKirjauksiaRequest, MaksutavatJoissaOnKirjauksiaResponse>('kirjanpitoMaksutavatJoissaPoistamattomiaKirjauksia')
    //       return callable(request)
    //     }
    //     return of<MaksutavatJoissaOnKirjauksiaResponse>({ m: {} })
    //   }),
    //   map(tunnuksetReponse => {
    //     const tunnukset = Object.keys(tunnuksetReponse?.m ?? {})
    //     if (tunnukset.length) {
    //       return tunnukset
    //     }
    //     return null
    //   })
    // )

    this._tilikaudetObservable = combineLatest([this._asiakasService.nykyisenAsiakkaanTilikaudetObservable, this.selectedTilikausiObservable]).pipe(
      map(([tilikaudet, valittu]) => {
        return { nykyinen: valittu, edellinen: this._kirjanpitoJaettuService.annaEdellinenTilikausi(tilikaudet, valittu) }
      })
    )

    this._tilikaudetObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(tilikaudet => {
      this._tallentamattomatLiitetiedotSubject.next(this._tilinpaatosLiitetiedotJaettuService.annaTyhjaLiitetiedotObject(tilikaudet.nykyinen, tilikaudet.edellinen))
    })

    // const sitaEdellinenTilikausiObservable = combineLatest([this._asiakasService.nykyisenAsiakkaanTilikaudetObservable, edellinenTilikausiObservable]).pipe(
    //   map(([tilikaudet, valittu]) => {
    //     return this._kirjanpitoJaettuService.annaEdellinenTilikausi(tilikaudet, valittu)
    //   })
    // )

    const tilikaudenTaseObservable: Observable<RaporttiTaselaskelmaData> = combineLatest([
      this._asiakasService.nykyinenAsiakasAvainObservable,
      this._aikavaliSubject,
      this.paivitaArvotHiljaisestiObservable
    ]).pipe(
      switchMap(([asiakas, aikavali, paivita]) => {

        if (!aikavali) {
          return of<RaporttiTaselaskelmaData>(null)
        }

        console.log('HAE ' + this._dateService.muotoilePaikallinenPaiva(aikavali.start, 'fi') + ' - ' + this._dateService.muotoilePaikallinenPaiva(aikavali.end, 'fi'))

        const haku: RaporttiRequest = {
          a: asiakas.avain,
          k: 'fi',
          w: RaporttiType.TASE,
          s: this._dateService.localDateToNumber(aikavali.start),
          e: this._dateService.localDateToNumber(aikavali.end)
        }

        return this._firebase.functionsCall<RaporttiRequest, RaporttiTaselaskelmaDataResponse>('kirjanpitoRaportitData', haku)
          .then(res => {
            if (res.e) {
              this._errorHandler.handleError(new Error(res.e))
              return null
            }
            return res.data
          }).catch(err => {
            console.error('Failed to fetch report data', err)
            this._errorHandler.handleError(err)
            throw err
          })
      }),
      lemonShare()
    )

    // const edellisenTilikaudenTaseObservable = combineLatest([
    //   this._asiakasService.nykyinenAsiakasAvainObservable,
    //   edellinenTilikausiObservable,
    //   this.paivitaArvotHiljaisestiObservable
    // ]).pipe(
    //   switchMap(([asiakas, edellinenTilikausi, paivita]) => {

    //     if (!edellinenTilikausi) {
    //       return of<RaporttiTaselaskelmaData>(null)
    //     }

    //     const haku: RaporttiRequest = {
    //       a: asiakas.avain,
    //       k: 'fi',
    //       w: RaporttiType.TASE,
    //       s: this._dateService.localDateToNumber(edellinenTilikausi.alkaa),
    //       e: this._dateService.localDateToNumber(edellinenTilikausi.loppuu)
    //     }

    //     const callable = this._firebase.httpsCallable<RaporttiRequest, RaporttiTaselaskelmaDataResponse>('kirjanpitoRaportitData')
    //     return callable(haku).pipe(
    //       map(res => {
    //         return res.data
    //       })
    //     )

    //   }),
    //   lemonShare()
    // )

    // const sitaEdellisenTilikaudenTaseObservable = combineLatest([
    //   this._asiakasService.nykyinenAsiakasAvainObservable,
    //   sitaEdellinenTilikausiObservable,
    //   this.paivitaArvotHiljaisestiObservable
    // ]).pipe(
    //   switchMap(([asiakas, sitaEdellinenTilikausi, paivita]) => {

    //     if (!sitaEdellinenTilikausi) {
    //       return of<RaporttiTaselaskelmaData>(null)
    //     }

    //     const haku: RaporttiRequest = {
    //       a: asiakas.avain,
    //       k: 'fi',
    //       w: RaporttiType.TASE,
    //       s: this._dateService.localDateToNumber(sitaEdellinenTilikausi.alkaa),
    //       e: this._dateService.localDateToNumber(sitaEdellinenTilikausi.loppuu)
    //     }

    //     const callable = this._firebase.httpsCallable<RaporttiRequest, RaporttiTaselaskelmaDataResponse>('kirjanpitoRaportitData')
    //     return callable(haku).pipe(
    //       map(res => {
    //         return res.data
    //       })
    //     )

    //   }),
    //   lemonShare()
    // )

    const tilikaudenTulosObservable: Observable<RaporttiTuloslaskelmaData> = combineLatest([
      this._asiakasService.nykyinenAsiakasAvainObservable,
      this._aikavaliSubject,
      this.paivitaArvotHiljaisestiObservable
    ]).pipe(
      switchMap(([asiakas, aikavali, paivita]) => {

        if (!aikavali) {
          return of<RaporttiTuloslaskelmaData>(null)
        }

        const haku: RaporttiRequest = {
          a: asiakas.avain,
          k: 'fi',
          w: RaporttiType.TULOS,
          s: this._dateService.localDateToNumber(aikavali.start),
          e: this._dateService.localDateToNumber(aikavali.end)
        }

        return this._firebase.functionsCall<RaporttiRequest, RaporttiTuloslaskelmaDataResponse>('kirjanpitoRaportitData', haku)
          .then(res => {
            if (res.e) {
              this._errorHandler.handleError(new Error(res.e))
            }
            return res.data
          }).catch(err => {
            console.error('Failed to fetch report data', err)
            this._errorHandler.handleError(err)
            throw err
          })
      }),
      lemonShare()
    )

    // const edellisenTilikaudenTulosObservable = combineLatest([
    //   this._asiakasService.nykyinenAsiakasAvainObservable,
    //   edellinenTilikausiObservable,
    //   this.paivitaArvotHiljaisestiObservable
    // ]).pipe(
    //   switchMap(([asiakas, edellinenTilikausi, paivita]) => {

    //     if (!edellinenTilikausi) {
    //       return of<RaporttiTuloslaskelmaData>(null)
    //     }

    //     const haku: RaporttiRequest = {
    //       a: asiakas.avain,
    //       k: 'fi',
    //       w: RaporttiType.TULOS,
    //       s: this._dateService.localDateToNumber(edellinenTilikausi.alkaa),
    //       e: this._dateService.localDateToNumber(edellinenTilikausi.loppuu)
    //     }

    //     const callable = this._firebase.httpsCallable<RaporttiRequest, RaporttiTuloslaskelmaDataResponse>('kirjanpitoRaportitData')
    //     return callable(haku).pipe(
    //       map(res => {
    //         return res.data
    //       })
    //     )

    //   }),
    //   lemonShare()
    // )

    // const sitaEdellisenTilikaudenTulosObservable = combineLatest([
    //   this._asiakasService.nykyinenAsiakasAvainObservable,
    //   sitaEdellinenTilikausiObservable,
    //   this.paivitaArvotHiljaisestiObservable
    // ]).pipe(
    //   switchMap(([asiakas, sitaEdellinenTilikausi, paivita]) => {

    //     if (!sitaEdellinenTilikausi) {
    //       return of<RaporttiTuloslaskelmaData>(null)
    //     }

    //     const haku: RaporttiRequest = {
    //       a: asiakas.avain,
    //       k: 'fi',
    //       w: RaporttiType.TULOS,
    //       s: this._dateService.localDateToNumber(sitaEdellinenTilikausi.alkaa),
    //       e: this._dateService.localDateToNumber(sitaEdellinenTilikausi.loppuu)
    //     }

    //     const callable = this._firebase.httpsCallable<RaporttiRequest, RaporttiTuloslaskelmaDataResponse>('kirjanpitoRaportitData')
    //     return callable(haku).pipe(
    //       map(res => {
    //         return res.data
    //       })
    //     )

    //   }),
    //   lemonShare()
    // )

    this.liitetiedotUriObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this.selectedTilikausiObservable]).pipe(
      map(([asiakas, tilikausi]) => {
        if (asiakas && tilikausi) {
          return this._kirjanpitoUriService.annaTilinpaatosLiitetiedotUri(asiakas.avain, tilikausi)
        }
        return ''
      })
    )

    this.liitetiedotEncodedUriObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this.selectedTilikausiObservable]).pipe(
      map(([asiakas, tilikausi]) => {
        if (asiakas && tilikausi) {
          const uri = this._kirjanpitoUriService.annaTilinpaatosLiitetiedotUri(asiakas.avain, tilikausi)
          return this._debugService.createFirestoreLink(uri)
        }
        return ''
      })
    )

    const liitetiedotTietokannastaObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this._tilikaudetObservable]).pipe(
      switchMap(([asiakas, tilikaudet]) => {
        let edellinenObservable: Observable<TilinpaatosLiitetiedot> = of<TilinpaatosLiitetiedot>(null)
        if (asiakas && tilikaudet.edellinen) {
          const previousUri = this._kirjanpitoUriService.annaTilinpaatosLiitetiedotUri(asiakas.avain, tilikaudet.edellinen)
          // console.log('load previous from ' + previousUri)
          edellinenObservable = this._firebase.firestoreDoc<TilinpaatosLiitetiedot>(previousUri).listen()
          // .pipe(
          //   map(original => original ? this._copyService.cloneObjectDeep(original) : original)
          // )
        }
        return combineLatest([this.nykyisetLiitetiedotTietokannassaObservable, edellinenObservable]).pipe(
          map(([nykyinen, edellinen]) => {
            // console.log(nykyinen, edellinen)
            return { tietokannasta: { nykyinen: nykyinen, edellinen: edellinen }, tilikaudet: tilikaudet }
          })
        )
      })
    )

    combineLatest([liitetiedotTietokannastaObservable, this.selectedTilikausiObservable]).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(([perustiedot, tilikausi]) => {

      if (!perustiedot || !tilikausi) {
        this._aikavaliSubject.next(null)
      }

      const tulos: PaivamaaraAikavali = { start: tilikausi.alkaa, end: tilikausi.loppuu }
      if (perustiedot.tietokannasta?.nykyinen?.loppuu) {
        tulos.end = perustiedot.tietokannasta?.nykyinen?.loppuu
      }

      if (
        !this._aikavaliSubject.value ||
        !this._aikavaliSubject.value.start ||
        !this._aikavaliSubject.value.end ||
        this._dateService.compareLocalDates(this._aikavaliSubject.value.start, '!=', tulos.start) ||
        this._dateService.compareLocalDates(this._aikavaliSubject.value.end, '!=', tulos.end)
      ) {
        this._aikavaliSubject.next(tulos)
      }

    })

    const knowYourCustomerObservable: Observable<KnowYourCustomer> = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return of<KnowYourCustomer>(null)
        }
        return this._firebaseLemonaid.firestoreDoc<KnowYourCustomer>(this._kycUriService.getCustomerKycDocUri(asiakas.avain)).listen()
      })
    )

    const osakkeidenLukumaaraObservable: Observable<number> = knowYourCustomerObservable.pipe(
      map(kyc => {
        return kyc?.osakkaat?.map(a => a.osakkeita ?? 0).reduce((a, b) => a + b, 0) || 0
      })
    )

    this._uncachedLiitetiedot = combineLatest([
      liitetiedotTietokannastaObservable, // .pipe(tap(tietokannasta => console.log('VAIHTUI!', tietokannasta))),
      tilikaudenTulosObservable,
      tilikaudenTaseObservable,
      this._tallentamattomatLiitetiedotSubject,
      osakkeidenLukumaaraObservable
    ]).pipe(
      map(([perustiedot, tulos, tase, tallentamattomat, osakkeidenLukumaara]) => {

        let tietokannasta = perustiedot.tietokannasta.nykyinen
        const tilikausi = perustiedot.tilikaudet.nykyinen
        const edellinenTilikausi = perustiedot.tilikaudet.edellinen
        const edellisetTietokannasta = perustiedot.tietokannasta.edellinen

        // If we have saved liitetiedot in database from year, but it lacks the laskelma, create an empty one
        // for the logic.
        if (edellisetTietokannasta && !edellisetTietokannasta.omanPaaomanMuutoksetLaskelma) {
          edellisetTietokannasta.omanPaaomanMuutoksetLaskelma = this._tilinpaatosLiitetiedotJaettuService.annaTyhjaOmaPaaomaLaskelma(edellinenTilikausi)
        }

        if (!tietokannasta) {
          tietokannasta = {
            tekstit: {},
            valitut: {},
            numerot: {},
            osionLisarivit: {},
            lisatietokentat: [],
            omanPaaomanMuutoksetLaskelma: this._tilinpaatosLiitetiedotJaettuService.annaTyhjaOmaPaaomaLaskelma(tilikausi),
            omanPaaomanMuutoksetVertailulaskelma: this._tilinpaatosLiitetiedotJaettuService.annaTyhjaVertailulaskelma(edellinenTilikausi),
            vertailutiedot: null,
            osingonjako: null,
            osingonjakoAnnettuMaara: null,
            osingonjakoEsilaskettuMaara: null,
            osingonjakoEsilaskettuOsinkoPerOsake: null,
            osingonjakoEsilaskettuOsakkeidenLukumaara: null,
            osinkoPerOsake: null,
            osinkojaYhteensa: null,
            liitteet: [],
            jakokelpoinenOmaPaaomaRivit: [],
            avain: null,
            luoja: null,
            luotu: null,
            paivitetty: null,
            paivittaja: null,
            poistettu: null,
            tilintarkastetaan: null,
            osingotNostettavissa: null,
            osingotNostettavissaPvm: null,
            kokoLomakeTallennettu: null,
            allekirjoitetaanLemonaidissa: true
          }
        }

        if (!tietokannasta.tekstit) { tietokannasta.tekstit = {} }
        if (!tietokannasta.valitut) { tietokannasta.valitut = {} }
        if (!tietokannasta.numerot) { tietokannasta.numerot = {} }
        if (!tietokannasta.osionLisarivit) { tietokannasta.osionLisarivit = {} }
        if (!tietokannasta.lisatietokentat) { tietokannasta.lisatietokentat = [] }
        if (!tietokannasta.omanPaaomanMuutoksetLaskelma) { tietokannasta.omanPaaomanMuutoksetLaskelma = this._tilinpaatosLiitetiedotJaettuService.annaTyhjaOmaPaaomaLaskelma(tilikausi) }
        if (!tietokannasta.omanPaaomanMuutoksetVertailulaskelma) { tietokannasta.omanPaaomanMuutoksetVertailulaskelma = this._tilinpaatosLiitetiedotJaettuService.annaTyhjaVertailulaskelma(edellinenTilikausi) }
        if (!tietokannasta.liitteet) { tietokannasta.liitteet = [] }
        if (!tietokannasta.jakokelpoinenOmaPaaomaRivit) { tietokannasta.jakokelpoinenOmaPaaomaRivit = [] }

        if (tallentamattomat.tilintarkastetaan === true || tallentamattomat.tilintarkastetaan === false) {
          tietokannasta.tilintarkastetaan = tallentamattomat.tilintarkastetaan
        }

        if (tallentamattomat.allekirjoitetaanLemonaidissa === true || tallentamattomat.allekirjoitetaanLemonaidissa === false) {
          tietokannasta.allekirjoitetaanLemonaidissa = tallentamattomat.allekirjoitetaanLemonaidissa
        }

        if (tallentamattomat?.jakokelpoinenOmaPaaomaRivit) {
          for (const rivi of tallentamattomat.jakokelpoinenOmaPaaomaRivit) {
            const index = tietokannasta.jakokelpoinenOmaPaaomaRivit.findIndex(a => a.tunnus === rivi.tunnus)
            if (index < 0) {
              tietokannasta.jakokelpoinenOmaPaaomaRivit.push(rivi)
            } else {
              tietokannasta.jakokelpoinenOmaPaaomaRivit[index] = rivi
            }
          }
        }

        if (edellisetTietokannasta) {
          tietokannasta.vertailutiedot = 'aikaisemmasta'
          tietokannasta.omanPaaomanMuutoksetVertailulaskelma = edellisetTietokannasta.omanPaaomanMuutoksetLaskelma
        } else if (tallentamattomat.vertailutiedot) {
          tietokannasta.vertailutiedot = tallentamattomat.vertailutiedot
        }

        if (tietokannasta.vertailutiedot === 'syotetaan-kasin') {
          if (tallentamattomat.omanPaaomanMuutoksetVertailulaskelma) {
            for (const key of Object.keys(tallentamattomat.omanPaaomanMuutoksetVertailulaskelma)) {
              const value = tallentamattomat.omanPaaomanMuutoksetVertailulaskelma[key]
              if (value !== null && value !== undefined) {
                // console.log('vertailu', key, '=>', value)
                tietokannasta.omanPaaomanMuutoksetVertailulaskelma[key] = value
              }
            }
          }
          this._tilinpaatosLiitetiedotJaettuService.paivitaLaskelmanSummat(tietokannasta, tietokannasta.omanPaaomanMuutoksetVertailulaskelma)
        }

        if (tallentamattomat?.tekstit) {
          for (const key of Object.keys(tallentamattomat.tekstit)) {
            const value = tallentamattomat.tekstit[key]
            // console.log('tekstit', key, '=>', value)
            tietokannasta.tekstit[key] = value
          }
        }

        if (tallentamattomat?.valitut) {
          for (const key of Object.keys(tallentamattomat.valitut)) {
            const value = tallentamattomat.valitut[key]
            // console.log('valitut', key, '=>', value)
            tietokannasta.valitut[key] = value
          }
        }

        if (tallentamattomat?.numerot) {
          for (const key of Object.keys(tallentamattomat.numerot)) {
            const value = tallentamattomat.numerot[key]
            // console.log('numerot', key, '=>', value)
            tietokannasta.numerot[key] = value
          }
        }

        if (tallentamattomat?.osionLisarivit) {
          for (const key of Object.keys(tallentamattomat.osionLisarivit ?? {})) {
            for (const lisarivi of tallentamattomat.osionLisarivit[key]) {
              if (!tietokannasta.osionLisarivit[key]) { tietokannasta.osionLisarivit[key] = [] }
              const index = tietokannasta.osionLisarivit[key].findIndex(a => a.tunnus === lisarivi.tunnus)
              if (index < 0) {
                tietokannasta.osionLisarivit[key].push(lisarivi)
              } else {
                tietokannasta.osionLisarivit[key][index] = lisarivi
              }
            }
          }
        }

        if (tallentamattomat?.lisatietokentat) {
          for (const lisatieto of tallentamattomat.lisatietokentat) {
            const index = tietokannasta.lisatietokentat.findIndex(a => a.tunnus === lisatieto.tunnus)
            if (index < 0) {
              tietokannasta.lisatietokentat.push(lisatieto)
            } else {
              tietokannasta.lisatietokentat[index] = lisatieto
            }
          }
        }

        if (tallentamattomat?.liitteet) {
          for (const liite of tallentamattomat.liitteet) {
            const index = tietokannasta.liitteet.findIndex(a => a.avain === liite.avain)
            if (index < 0) {
              tietokannasta.liitteet.push(liite)
            } else {
              tietokannasta.liitteet[index] = liite
            }
          }
        }

        // console.log(tietokannasta.osingotNostettavissa, tietokannasta.osingotNostettavissaPvm)

        if (tallentamattomat?.osingotNostettavissa !== undefined) {
          tietokannasta.osingotNostettavissa = tallentamattomat.osingotNostettavissa
        }

        if (tallentamattomat?.osingotNostettavissaPvm !== undefined) {
          tietokannasta.osingotNostettavissaPvm = tallentamattomat.osingotNostettavissaPvm
        }

        const tilikaudenVoittoTaiTappio = this._currencyService.roundHalfUp(tulos?.r?.find(a => a.a === '3')?.s1 ?? 0, 2)
        const omaPaaomayhteensa = this._currencyService.roundHalfUp(tase?.r?.find(a => a.a === '20')?.s1 ?? 0, 2)
        const paaomalainatYhteensa = this._currencyService.roundHalfUp(tase?.r?.find(a => a.a === '261')?.s1 ?? 0, 2)
        const omaPaaomaJaPaaomalainatYhteensa = omaPaaomayhteensa + paaomalainatYhteensa

        // NOTE! THE ORDER IS IMPORTANT!!
        tietokannasta.omanPaaomanMuutoksetLaskelma = this._tilinpaatosLiitetiedotJaettuService.annaOmanPaaomanMuutoksetLaskelma(tietokannasta, tallentamattomat, tilikausi, tilikaudenVoittoTaiTappio, omaPaaomaJaPaaomalainatYhteensa)
        this._tilinpaatosLiitetiedotJaettuService.paivitaOsingonjakolaskelmat(tietokannasta, tallentamattomat, osakkeidenLukumaara)

        const ret: LiitetiedotJaTallentamattomatMuutokset = { liitetiedot: tietokannasta, tallentamattomat: tallentamattomat }

        return ret
      })
    )

    this.liitetiedotObservable = this._uncachedLiitetiedot.pipe(
      lemonShare()
    )

    this.liiteJaLukitustiedotObservable = combineLatest([this.liitetiedotObservable, this.tilinpaatosStatusObservable]).pipe(
      map(([liitetiedot, status]) => {
        const result: LiitetiedotJaTallentamattomatMuutoksetJaLukitusTiedot = {
          liitetiedot: liitetiedot?.liitetiedot,
          tallentamattomat: liitetiedot?.tallentamattomat,
          lukittu: !!status?.rekisteroity
        }
        return result
      })
    )

    this.osingotSaatetaanMaksaaSamanaVuonnaObservable = combineLatest([this.selectedTilikausiObservable, this.liitetiedotObservable]).pipe(
      map(([tilikausi, data]) => {
        if (!tilikausi || !data) {
          return false
        }
        if (
          data.liitetiedot.osingonjako === 'annettu-maara' ||
          data.liitetiedot.osingonjako === 'esilaskettu-prosentti'
        ) {
          if (data.liitetiedot.osingotNostettavissa === 'yhtiokokouksen-jalkeen' && this._dateService.currentLocalDate().year === tilikausi.loppuu.year) {
            return true
          } else if (data.liitetiedot.osingotNostettavissa === 'pvm') {
            const asLocalDate = this._dateService.numberToLocalDate(data.liitetiedot.osingotNostettavissaPvm)
            if (asLocalDate?.year === tilikausi.loppuu.year) {
              return true
            }
          }
        }
        return false
      })
    )

    this.liitteetObservable = this.liitetiedotObservable.pipe(
      map(data => {
        if (!data?.liitetiedot?.liitteet?.length) {
          return []
        }
        return data.liitetiedot.liitteet.filter(liite => !liite.poistettu)
      })
    )

    this.onMikroYritysObservable = this.liitetiedotObservable.pipe(
      map(liitetiedot => {
        let count = 0
        count += liitetiedot.liitetiedot.valitut.ONKO_MIKROYRITYS_HENKILOSTO_YLI ? 1 : 0
        count += liitetiedot.liitetiedot.valitut.ONKO_MIKROYRITYS_TASEEN_LOPPUSUMMA_YLI ? 1 : 0
        count += liitetiedot.liitetiedot.valitut.ONKO_MIKROYRITYS_LIIKEVAIHTO_YLI ? 1 : 0
        return count < 2
      })
    )

    this.muiltaOsinTekstiObservable = this.liitetiedotObservable.pipe(
      map(liitetiedot => {
        const valittuja = liitetiedot.liitetiedot.valitut.PMA3_1_A_1 ||
          liitetiedot.liitetiedot.valitut.PMA3_1_A_2 ||
          liitetiedot.liitetiedot.valitut.PMA3_1_A_3 ||
          liitetiedot.liitetiedot.valitut.PMA3_1_A_4 ||
          liitetiedot.liitetiedot.valitut.PMA3_1_A_5 ||
          liitetiedot.liitetiedot.valitut.PMA3_1_A_6 ||
          liitetiedot.liitetiedot.valitut.PMA3_1_B_1 ||
          liitetiedot.liitetiedot.valitut.PMA3_1_B_2 ||
          liitetiedot.liitetiedot.valitut.PMA3_1_B_3 ||
          liitetiedot.liitetiedot.valitut.PMA3_1_B_4 ||
          liitetiedot.liitetiedot.valitut.PMA3_1_B_5 ||
          liitetiedot.liitetiedot.valitut.PMA3_1_B_6

        if (valittuja) {
          return this._tilinpaatosTranslationService.localize('PMA3_1.muilta-osin-on-laadittu', 2021, 'fi')
        }

        return this._tilinpaatosTranslationService.localize('PMA3_1.tilinpaatos-on-laadittu', 2021, 'fi')
      })
    )

    this.jakokelpoinenOmaPaaomaEiRiitaObservable = this.liitetiedotObservable.pipe(
      map(data => {
        if (data.liitetiedot.osingonjako === 'ei-jaeta') {
          return false
        }

        const jakokelpoinen = data.liitetiedot.omanPaaomanMuutoksetLaskelma.jakokelpoinenOmaPaaomaYhteensa || 0
        if (data.liitetiedot.osingonjako === 'annettu-maara') {
          const jaettava = data.liitetiedot.osingonjakoAnnettuMaara || 0
          return this._currencyService.roundHalfUp(jaettava, 2) > this._currencyService.roundHalfUp(jakokelpoinen, 2)
        } else if (data.liitetiedot.osingonjako === 'esilaskettu-prosentti') {
          const jaettava = data.liitetiedot.osingonjakoEsilaskettuMaara || 0
          return this._currencyService.roundHalfUp(jaettava, 2) > this._currencyService.roundHalfUp(jakokelpoinen, 2)
        } else if (data.liitetiedot.osingonjako) {
          this._errorHandler.handleError(new Error('Unnown osingonjako type ' + data.liitetiedot.osingonjako))
        }

        return false
      })
    )

    this.liitetiedotObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(liitetiedot => {
      if (liitetiedot.liitetiedot.loppuu) {
        loppuuCtrl.setValue(this._dateService.localDateToDate(liitetiedot.liitetiedot.loppuu), { onlySelf: true })
      } else if (loppuuCtrl.value) {
        loppuuCtrl.setValue(null, { onlySelf: true })
      }
    })

    this.tilinpaatosStatusObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(status => {
      if (!!status?.rekisteroity) {
        loppuuCtrl.disable()
      } else {
        loppuuCtrl.enable()
      }
    })

  }

  async tallennaLoppuu() {
    const paivitys = await this.annaUpdateAihio()
    const update: Partial<TilinpaatosLiitetiedot> = paivitys.update
    if (this.form.get('loppuu').value) {
      update.loppuu = this._dateService.dateToLocalDate(this.form.get('loppuu').value)
    } else {
      update.loppuu = deleteField() as any as LocalDate
    }
    this._firebase.firestoreSetData(paivitys.updateUri, update, { merge: true })
      .then(() => this.pvmSaveSuccessSubject.next())
      .catch(error => this._errorHandler.handleError(error))
  }

  deleteRivi(rivi: TilinpaatosJakokelpoisenOmanPaaomanLisarivi) {
    let found: TilinpaatosJakokelpoisenOmanPaaomanLisarivi = this._tallentamattomatLiitetiedotSubject.value.jakokelpoinenOmaPaaomaRivit.find(a => a.tunnus === rivi.tunnus)
    if (!found) {
      found = {
        kuvaus: rivi.kuvaus,
        maara: rivi.maara,
        poistettu: rivi.poistettu,
        tunnus: rivi.tunnus
      }
      this._tallentamattomatLiitetiedotSubject.value.jakokelpoinenOmaPaaomaRivit.push(found)
    }
    found.poistettu = true
    rivi.poistettu = true
    this._tallentamattomatLiitetiedotSubject.next(this._tallentamattomatLiitetiedotSubject.value)
    this.tallennaJakokelpoinenOmaPaaoma()
  }

  lisaaJakokelpoinenOmaPaaomaRivi() {
    this._tallentamattomatLiitetiedotSubject.value.jakokelpoinenOmaPaaomaRivit.push({
      kuvaus: null,
      maara: null,
      tunnus: this._firebase.firestoreCreateId(),
      poistettu: false
    })
    this._tallentamattomatLiitetiedotSubject.next(this._tallentamattomatLiitetiedotSubject.value)
    this.tallennaJakokelpoinenOmaPaaoma()
  }

  lisaaLisatieto() {
    this._tallentamattomatLiitetiedotSubject.value.lisatietokentat.push({
      otsikko: null,
      teksti: null,
      tunnus: this._firebase.firestoreCreateId(),
      poistettu: false
    })
    this._tallentamattomatLiitetiedotSubject.next(this._tallentamattomatLiitetiedotSubject.value)
    this.tallennaLisatiedot()
  }

  poistaLisatieto(lisatieto: LisatietoKentta) {
    this._dialog.open(AreYouSureDialog, {
      data: {
        rightAction: 'Poista lisätieto',
        text: 'Haluatko varmasti poistaa tämän lisätiedon?'
      }
    }).afterClosed().subscribe(confirmed => { if (confirmed) { this._poistaLisatieto(lisatieto) } })
  }

  private _poistaLisatieto(lisatieto: LisatietoKentta) {
    let found = this._tallentamattomatLiitetiedotSubject.value.lisatietokentat.find(a => a.tunnus === lisatieto.tunnus)
    if (!found) {
      found = {
        otsikko: lisatieto.otsikko,
        teksti: lisatieto.teksti,
        poistettu: lisatieto.poistettu,
        tunnus: lisatieto.tunnus
      }
      this._tallentamattomatLiitetiedotSubject.value.lisatietokentat.push(found)
    }
    found.poistettu = true
    lisatieto.poistettu = true
    this._tallentamattomatLiitetiedotSubject.next(this._tallentamattomatLiitetiedotSubject.value)
    this.tallennaLisatiedot()
  }

  lisatietoMuuttui<K extends keyof LisatietoKentta>(value: LisatietoKentta[K], lisatieto: LisatietoKentta, key: K) {
    let found: LisatietoKentta = this._tallentamattomatLiitetiedotSubject.value.lisatietokentat.find(a => a.tunnus === lisatieto.tunnus)
    if (!found) {
      found = {
        otsikko: lisatieto.otsikko,
        teksti: lisatieto.teksti,
        poistettu: lisatieto.poistettu,
        tunnus: lisatieto.tunnus
      }
      this._tallentamattomatLiitetiedotSubject.value.lisatietokentat.push(found)
    }
    found[key] = value
    lisatieto[key] = value
  }

  osionLisariviMuuttui<K extends keyof LisatietoKentta>(kentta: TilinpaatosLiitetiedotKentat, value: LisatietoKentta[K], lisatieto: LisatietoKentta, key: K) {
    let found = this._tallentamattomatLiitetiedotSubject.value.osionLisarivit?.[kentta]?.find(a => a.tunnus === lisatieto.tunnus)
    if (!found) {
      found = {
        otsikko: lisatieto.otsikko,
        teksti: lisatieto.teksti,
        poistettu: lisatieto.poistettu,
        tunnus: lisatieto.tunnus
      }

      if (!this._tallentamattomatLiitetiedotSubject.value.osionLisarivit[kentta]) {
        this._tallentamattomatLiitetiedotSubject.value.osionLisarivit[kentta] = []
      }
      this._tallentamattomatLiitetiedotSubject.value.osionLisarivit[kentta].push(found)
    }
    found[key] = value
    lisatieto[key] = value
  }

  async tallennaLisatiedot() {
    const paivitys = await this.annaUpdateAihio()
    const update: Partial<TilinpaatosLiitetiedot> = paivitys.update

    const arvo = paivitys.tallennettava.liitetiedot.lisatietokentat
    update.lisatietokentat = arvo

    this._firebase.firestoreSetData(
      paivitys.updateUri, update, { merge: true }
    )
      .then(() => this.lisatiedotSaveSuccessSubject.next())
      .catch(error => this._errorHandler.handleError(error))
  }

  avaaInfoTeksti(teksti: string) {
    const data: InfoDialogData = {
      text: teksti,
      header: ''
    }
    this._dialog.open<InfoDialog, InfoDialogData>(InfoDialog, { data: data })
  }

  // private _annaOmanPaaomanMuutoksetLaskelma(
  //   liitetiedot: LiitetiedotJaTallentamattomatMuutokset,
  //   tilikausi: Tilikausi,
  //   tulos: RaporttiTuloslaskelmaData,
  //   tase: RaporttiTaselaskelmaData,
  //   edellinenTulos: RaporttiTuloslaskelmaData,
  //   edellinenTase: RaporttiTaselaskelmaData
  // ): MuutoksetOmassaPaaomassaLaskelma {

  //   let edellistenTilikausienVoittoTaiTappioTilikaudenAlussa = 0
  //   if (edellinenTase && edellinenTulos) {
  //     edellistenTilikausienVoittoTaiTappioTilikaudenAlussa = (edellinenTase?.r?.find(a => a.a === '2250')?.s1 ?? 0) + (edellinenTulos?.r?.find(a => a.a === '3')?.s1 ?? 0)
  //   } else {
  //     edellistenTilikausienVoittoTaiTappioTilikaudenAlussa = (tase?.r?.find(a => a.a === '2250')?.s1 ?? 0) + (tulos?.r?.find(a => a.a === '3')?.s1 ?? 0)
  //   }

  //   const laskelma: MuutoksetOmassaPaaomassaLaskelma = {
  //     header: this._dateService.muotoilePaikallinenPaiva(tilikausi.loppuu, 'fi'),
  //     osakepaaomaTilikaudenAlussa: edellinenTase?.r?.find(a => a.a === '2000')?.s1 ?? tase?.r?.find(a => a.a === '2000')?.s1 ?? 0,
  //     svopTilikaudenAlussa: edellinenTase?.r?.find(a => a.a === '2060')?.s1 ?? 0,
  //     edellistenTilikausienVoittoTaiTappioTilikaudenAlussa: edellistenTilikausienVoittoTaiTappioTilikaudenAlussa,
  //     tilikaudenVoittoTaiTappio: tulos?.r?.find(a => a.a === '3')?.s1 ?? 0,
  //     edellistenTilikausienVoittoTaiTappioTilikaudenLopussa: 0,
  //     omaPaaomaEiTasmaa: false,
  //     omaPaaomaPlusPaaomalainat: 0,
  //     omaPaaomaYhteensa: 0,
  //     osakeanti: this._annaNumeroJosValittu(TilinpaatosLiitetiedotKentat.OSAKEANTI, liitetiedot),
  //     osakepaaomaTilikaudenLopussa: 0,
  //     osingonjakoTilikaudella: this._annaNumeroJosValittu(TilinpaatosLiitetiedotKentat.OSINKOJEN_MAARA, liitetiedot),
  //     sidottuOmaPaaoma: 0,
  //     svopLisayksetTilikaudenAikana: this._annaNumeroJosValittu(TilinpaatosLiitetiedotKentat.SVOP_LISAYKSET, liitetiedot),
  //     svopTilikaudenLopussa: 0,
  //     tiikaudellaOstetutOmatOsakkeet: this._annaNumeroJosValittu(TilinpaatosLiitetiedotKentat.YHTIO_ON_OSTANUT_OSAKKEITAAN, liitetiedot),
  //     tilikaudellaMyydytOmatOsakkeet: this._annaNumeroJosValittu(TilinpaatosLiitetiedotKentat.YHTIO_ON_MYYNYT_OSAKKEITAAN, liitetiedot),
  //     vapaaOmaPaaoma: 0
  //   }

  //   laskelma.osakepaaomaTilikaudenLopussa = laskelma.osakepaaomaTilikaudenAlussa + laskelma.osakeanti
  //   laskelma.sidottuOmaPaaoma = laskelma.osakepaaomaTilikaudenLopussa

  //   laskelma.edellistenTilikausienVoittoTaiTappioTilikaudenLopussa = laskelma.edellistenTilikausienVoittoTaiTappioTilikaudenAlussa + laskelma.osingonjakoTilikaudella + laskelma.tiikaudellaOstetutOmatOsakkeet - laskelma.tilikaudellaMyydytOmatOsakkeet

  //   laskelma.svopTilikaudenLopussa = laskelma.svopTilikaudenAlussa + laskelma.svopLisayksetTilikaudenAikana

  //   laskelma.vapaaOmaPaaoma = laskelma.svopTilikaudenLopussa + laskelma.edellistenTilikausienVoittoTaiTappioTilikaudenLopussa + laskelma.tilikaudenVoittoTaiTappio

  //   laskelma.omaPaaomaYhteensa = laskelma.vapaaOmaPaaoma + laskelma.sidottuOmaPaaoma
  //   laskelma.omaPaaomaPlusPaaomalainat = laskelma.omaPaaomaYhteensa + this._annaNumeroJosValittu(TilinpaatosLiitetiedotKentat.YHTIOLLA_PAAOMALAINAA, liitetiedot)
  //   laskelma.omaPaaomaEiTasmaa = laskelma.omaPaaomaYhteensa !== tase?.r?.find(a => a.a === '20')?.s1

  //   return laskelma
  // }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  checkboxChanged() {
    this._tallentamattomatLiitetiedotSubject.next(this._tallentamattomatLiitetiedotSubject.value)
  }

  valintaMuuttui(event: boolean, kentta: TilinpaatosLiitetiedotKentat) {
    this._tallentamattomatLiitetiedotSubject.value.valitut[kentta] = !!event
    this._tallentamattomatLiitetiedotSubject.next(this._tallentamattomatLiitetiedotSubject.value)
    const tallennaEvent: TallennaArvoEvent = {
      field: kentta,
      type: 'checkbox'
    }
    this.tallennaMuuttunutArvo(tallennaEvent)
  }

  numeroMuuttui(event: number, kentta: TilinpaatosLiitetiedotKentat) {
    this._tallentamattomatLiitetiedotSubject.value.numerot[kentta] = event
    this._tallentamattomatLiitetiedotSubject.next(this._tallentamattomatLiitetiedotSubject.value)
  }
  tallennaNumero(kentta: TilinpaatosLiitetiedotKentat) {
    const tallennaEvent: TallennaArvoEvent = {
      field: kentta,
      type: 'number'
    }
    this.tallennaMuuttunutArvo(tallennaEvent)
  }

  tekstiMuuttui(event: string, kentta: TilinpaatosLiitetiedotKentat) {
    this._tallentamattomatLiitetiedotSubject.value.tekstit[kentta] = event
  }
  tallennaTeksti(kentta: TilinpaatosLiitetiedotKentat) {
    const tallennaEvent: TallennaArvoEvent = {
      field: kentta,
      type: 'text'
    }
    this.tallennaMuuttunutArvo(tallennaEvent)
  }

  async eiVertailuarvojaArvoMuuttui(event: VertailutietojenLahde) {
    this._tallentamattomatLiitetiedotSubject.value.vertailutiedot = event
    this._tallentamattomatLiitetiedotSubject.next(this._tallentamattomatLiitetiedotSubject.value)

    const paivitys = await this.annaUpdateAihio()
    const update: Partial<TilinpaatosLiitetiedot> = paivitys.update

    const arvo = paivitys.tallennettava.liitetiedot.vertailutiedot
    update.vertailutiedot = arvo === undefined ? this._firebase.firestoreDeleteMarker() : arvo

    this._firebase.firestoreSetData(paivitys.updateUri, update, { merge: true }).then(() => {
      delete paivitys.tallennettava.tallentamattomat.vertailutiedot
    }).catch(error => {
      this._errorHandler.handleError(error)
    })
  }

  vertailuarvoMuuttui() {
    this._tallentamattomatLiitetiedotSubject.next(this._tallentamattomatLiitetiedotSubject.value)
  }

  async tallennaVertailuarvot() {
    const paivitys = await this.annaUpdateAihio()
    const update: Partial<TilinpaatosLiitetiedot> = paivitys.update

    const arvo = paivitys.tallennettava.liitetiedot.omanPaaomanMuutoksetVertailulaskelma
    update.omanPaaomanMuutoksetVertailulaskelma = arvo

    this._firebase.firestoreSetData(paivitys.updateUri, update, { merge: true }).catch(error => {
      this._errorHandler.handleError(error)
    })
  }

  async osingonjaonTyyppiMuuttui(event: OsingonjaonTyyppi) {
    this._tallentamattomatLiitetiedotSubject.value.osingonjako = event
    this._tallentamattomatLiitetiedotSubject.next(this._tallentamattomatLiitetiedotSubject.value)

    const paivitys = await this.annaUpdateAihio()
    const update: Partial<TilinpaatosLiitetiedot> = paivitys.update

    const arvo = paivitys.tallennettava.liitetiedot.osingonjako
    update.osingonjako = arvo === undefined ? this._firebase.firestoreDeleteMarker() : arvo

    this._firebase.firestoreSetData(paivitys.updateUri, update, { merge: true }).then(() => {
      delete paivitys.tallennettava.tallentamattomat.osingonjako
    }).catch(error => {
      this._errorHandler.handleError(error)
    })
  }

  async osingotNostettavissaTyyppiMuuttui(event: OsingotNostettavissaTyyppi) {
    this._tallentamattomatLiitetiedotSubject.value.osingotNostettavissa = event
    this._tallentamattomatLiitetiedotSubject.next(this._tallentamattomatLiitetiedotSubject.value)

    const paivitys = await this.annaUpdateAihio()
    const update: Partial<TilinpaatosLiitetiedot> = paivitys.update

    const arvo = paivitys.tallennettava.liitetiedot.osingotNostettavissa
    update.osingotNostettavissa = arvo === undefined ? this._firebase.firestoreDeleteMarker() : arvo

    this._firebase.firestoreSetData(paivitys.updateUri, update, { merge: true }).then(() => {
      delete paivitys.tallennettava.tallentamattomat.osingotNostettavissa
    }).catch(error => {
      this._errorHandler.handleError(error)
    })
  }

  async automaattitallennaOsingonjaonNostettavissaPaivamaara() {
    const paivitys = await this.annaUpdateAihio()
    const update: Partial<TilinpaatosLiitetiedot> = paivitys.update

    const arvo = paivitys.tallennettava.liitetiedot.osingotNostettavissaPvm
    update.osingotNostettavissaPvm = arvo === undefined ? this._firebase.firestoreDeleteMarker() : arvo

    this._firebase.firestoreSetData(paivitys.updateUri, update, { merge: true }).then(() => {
      delete paivitys.tallennettava.tallentamattomat.osingotNostettavissaPvm
    }).catch(error => {
      this._errorHandler.handleError(error)
    })
  }

  osingonNostettavissaPvmMuuttui(pvm: Date, value: string | null) {

    if (
      value?.trim() &&
      (
        !pvm ||
        !this._dateService.isValidDate(pvm) ||
        pvm.getFullYear() < 2000
      )
    ) {
      return
    }

    const asNumber: NumberDate = this._dateService.dateToNumber(pvm)
    console.log(pvm, asNumber, this._dateService.isNumberDateValid(asNumber))
    this._tallentamattomatLiitetiedotSubject.value.osingotNostettavissaPvm = this._dateService.isNumberDateValid(asNumber) ? asNumber : null
    this._tallentamattomatLiitetiedotSubject.next(this._tallentamattomatLiitetiedotSubject.value)

  }

  osingonMaaraMuuttui(event: number) {
    this._tallentamattomatLiitetiedotSubject.value.osingonjakoAnnettuMaara = event
    this._tallentamattomatLiitetiedotSubject.next(this._tallentamattomatLiitetiedotSubject.value)
  }

  async automaattitallennaOsingonMaara() {
    const paivitys = await this.annaUpdateAihio()
    const update: Partial<TilinpaatosLiitetiedot> = paivitys.update

    const arvo = paivitys.tallennettava.liitetiedot.osingonjakoAnnettuMaara
    update.osingonjakoAnnettuMaara = arvo === undefined ? this._firebase.firestoreDeleteMarker() : arvo

    this._firebase.firestoreSetData(paivitys.updateUri, update, { merge: true }).then(() => {
      delete paivitys.tallennettava.tallentamattomat.osingonjakoAnnettuMaara
    }).catch(error => {
      this._errorHandler.handleError(error)
    })
  }

  jakokelpoinenOmaPaaomaRivinTekstiMuuttui(event: string, rivi: TilinpaatosJakokelpoisenOmanPaaomanLisarivi) {
    let found: TilinpaatosJakokelpoisenOmanPaaomanLisarivi = this._tallentamattomatLiitetiedotSubject.value.jakokelpoinenOmaPaaomaRivit.find(a => a.tunnus === rivi.tunnus)
    if (!found) {
      found = {
        kuvaus: rivi.kuvaus,
        maara: rivi.maara,
        poistettu: rivi.poistettu,
        tunnus: rivi.tunnus
      }
      this._tallentamattomatLiitetiedotSubject.value.jakokelpoinenOmaPaaomaRivit.push(found)
    }
    found.kuvaus = event
    rivi.kuvaus = event
  }

  jakokelpoinenOmaPaaomaRivinSummaMuuttui(event: number, rivi: TilinpaatosJakokelpoisenOmanPaaomanLisarivi) {
    let found: TilinpaatosJakokelpoisenOmanPaaomanLisarivi = this._tallentamattomatLiitetiedotSubject.value.jakokelpoinenOmaPaaomaRivit.find(a => a.tunnus === rivi.tunnus)
    if (!found) {
      found = {
        kuvaus: rivi.kuvaus,
        maara: rivi.maara,
        poistettu: rivi.poistettu,
        tunnus: rivi.tunnus
      }
      this._tallentamattomatLiitetiedotSubject.value.jakokelpoinenOmaPaaomaRivit.push(found)
    }
    found.maara = event
    rivi.maara = event
    this._tallentamattomatLiitetiedotSubject.next(this._tallentamattomatLiitetiedotSubject.value)
  }

  async tallennaJakokelpoinenOmaPaaoma() {
    const paivitys = await this.annaUpdateAihio()
    const update: Partial<TilinpaatosLiitetiedot> = paivitys.update

    const arvo = paivitys.tallennettava.liitetiedot.jakokelpoinenOmaPaaomaRivit
    update.jakokelpoinenOmaPaaomaRivit = arvo

    this._firebase.firestoreSetData(paivitys.updateUri, update, { merge: true }).catch(error => {
      this._errorHandler.handleError(error)
    })
  }

  osingonMaaraInputDisabledChanged(event: boolean) {
    if (!event) {
      this.osingonMaaraInput?.focus()
    }
  }

  henkilostonMaaraInputDisabledChanged(event: boolean) {
    if (!event) {
      this.henkilostonMaaraInput?.focus()
    }
  }

  osingotNostettavissaInputDisabledChanged(event: boolean) {
    if (!event) {
      this.osinkoNostettavissaInput?.focus()
    }
  }

  async annaUpdateAihio(): Promise<{ update: Partial<TilinpaatosLiitetiedot>, tallennettava: LiitetiedotJaTallentamattomatMuutokset, updateUri: string }> {

    const tallennettavaPromise = firstValueFrom(this._uncachedLiitetiedot)
    const tilikausiPromise = firstValueFrom(this.selectedTilikausiObservable)
    const asiakasPromise = firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const kirjanpitajaPromise = firstValueFrom(this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable)
    const [tallennettava, tilikausi, asiakas, kirjanpitaja] = await Promise.all([tallennettavaPromise, tilikausiPromise, asiakasPromise, kirjanpitajaPromise])

    this._tilinpaatosLiitetiedotJaettuService.valmisteleTallennettava(tallennettava.liitetiedot, kirjanpitaja.uid, tilikausi)
    const uri = this._kirjanpitoUriService.annaTilinpaatosLiitetiedotUri(asiakas.avain, tilikausi)

    const update: Partial<TilinpaatosLiitetiedot> = {
      paivittaja: tallennettava.liitetiedot.paivittaja,
      paivitetty: tallennettava.liitetiedot.paivitetty,
      avain: tallennettava.liitetiedot.avain,
      luoja: tallennettava.liitetiedot.luoja,
      luotu: tallennettava.liitetiedot.luotu
    }

    return { tallennettava: tallennettava, update: update, updateUri: uri }

  }

  async tallennaMuuttunutArvo(event: TallennaArvoEvent) {

    const paivitys = await this.annaUpdateAihio()
    const update: Partial<TilinpaatosLiitetiedot> = paivitys.update

    if (event.type === 'text') {
      const arvo = paivitys.tallennettava.liitetiedot.tekstit[event.field]
      update.tekstit = {}
      update.tekstit[event.field] = arvo === undefined ? this._firebase.firestoreDeleteMarker() : arvo
    } else if (event.type === 'number') {
      const arvo = paivitys.tallennettava.liitetiedot.numerot[event.field]
      update.numerot = {}
      update.numerot[event.field] = arvo === undefined ? this._firebase.firestoreDeleteMarker() : arvo
    } else if (event.type === 'checkbox') {
      const arvo = paivitys.tallennettava.liitetiedot.valitut[event.field]
      update.valitut = {}
      update.valitut[event.field] = arvo === undefined ? this._firebase.firestoreDeleteMarker() : arvo
    } else {
      throw new Error('Unknown tyyppi: ' + event.type)
    }

    this._firebase.firestoreSetData(paivitys.updateUri, update, { merge: true }).then(() => {
      if (event.type === 'text') {
        delete paivitys.tallennettava.tallentamattomat.tekstit[event.field]
      } else if (event.type === 'number') {
        delete paivitys.tallennettava.tallentamattomat.numerot[event.field]
      } else if (event.type === 'checkbox') {
        delete paivitys.tallennettava.tallentamattomat.valitut[event.field]
      } else {
        throw new Error('Unknown tyyppi: ' + event.type)
      }
      if (event.doneCallback) { event.doneCallback('success') }
    }).catch(error => {
      this._errorHandler.handleError(error)
      if (event.doneCallback) { event.doneCallback('error', error) }
    })
  }

  // @HostListener('blur')
  // autosaveDocument() {
  //   console.log('BLUR')
  // }

  // async automaattitallenna() {

  //   const tallennettava = await firstValueFrom(this._uncachedLiitetiedot)
  //   const tilikaudet = await firstValueFrom(this._tilikaudetObservable)
  //   const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
  //   const kirjanpitaja = await firstValueFrom(this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable)

  //   if (!asiakas || !tallennettava || !tilikaudet) {
  //     return
  //   }

  //   this._valmisteleTallennettava(tallennettava.liitetiedot, kirjanpitaja, tilikaudet.nykyinen)

  //   const batch = this._firestore.batch()

  //   const uri = this._kirjanpitoUriService.annaTilinpaatosLiitetiedotUri(asiakas.avain, tilikaudet.nykyinen)
  //   batch.set(this._firestore.doc(uri).ref, tallennettava.liitetiedot)

  //   batch.commit().then(() => {
  //     this._tallentamattomatLiitetiedotSubject.next({
  //       tekstit: {},
  //       valitut: {},
  //       numerot: {},
  //       omanPaaomanMuutoksetLaskelma: this._annaTyhjaLaskelma(tilikaudet.nykyinen),
  //       omanPaaomanMuutoksetVertailulaskelma: this._annaTyhjaVertailulaskelma(tilikaudet.edellinen),
  //       vertailutiedot: null,
  //       osingonjako: null,
  //       osingonjakoAnnettuMaara: null,
  //       osingonjakoEsilaskettuMaara: null,
  //       osingonjakoEsilaskettuOsinkoPerOsake: null,
  //       osingonjakoEsilaskettuOsakkeidenLukumaara: null,
  //       osinkoPerOsake: null,
  //       osinkojaYhteensa: null,
  //       liitteet: [],
  //       jakokelpoinenOmaPaaomaRivit: [],
  //       avain: null,
  //       luoja: null,
  //       luotu: null,
  //       paivitetty: null,
  //       paivittaja: null,
  //       poistettu: null,
  //       tilintarkastetaan: null
  //     })

  //   }).catch(error => {
  //     this._ladataanService.lopetaLataaminen()
  //     this._errorHandler.handleError(error)
  //   })

  // }

  private _saveInFlight = false
  async tallenna(data: TilinpaatosLiitetiedot, form: NgForm) {

    if (this._saveInFlight) {
      return
    }

    form.form.updateValueAndValidity()
    this._formValidationService.tarkistaVirheetLapsistaJaItsesta(form.form)
    if (
      !data.osingonjakoEsilaskettuOsakkeidenLukumaara ||
      data.osingonjakoEsilaskettuOsakkeidenLukumaara < 1 ||
      this._formValidationService.onkoLomakkeessaVirheita(form.form)
    ) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(form.form)
      this._formValidationService.naytaEnsimmainenVirhe()
      this._saveInFlight = false
      return
    }

    this._saveInFlight = true
    this._ladataanService.aloitaLataaminen()

    const tallennettava = await firstValueFrom(this._uncachedLiitetiedot)
    const tilikaudet = await firstValueFrom(this._tilikaudetObservable)
    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const kirjanpitaja = await firstValueFrom(this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable)

    if (!asiakas || !tallennettava || !tilikaudet) {
      this._ladataanService.lopetaLataaminen()
      this._saveInFlight = false
      return
    }

    if (tallennettava.liitetiedot.tilintarkastetaan === undefined || tallennettava.liitetiedot.tilintarkastetaan === null) {
      this._ladataanService.lopetaLataaminen()
      this._saveInFlight = false
      return
    }

    if (tallennettava.liitetiedot.allekirjoitetaanLemonaidissa === undefined || tallennettava.liitetiedot.allekirjoitetaanLemonaidissa === null) {
      this._ladataanService.lopetaLataaminen()
      this._saveInFlight = false
      return
    }

    tallennettava.liitetiedot.kokoLomakeTallennettu = true

    this._tilinpaatosLiitetiedotJaettuService.valmisteleTallennettava(tallennettava.liitetiedot, kirjanpitaja.uid, tilikaudet.nykyinen)

    const batch = this._firebase.firestoreBatch()

    const uri = this._kirjanpitoUriService.annaTilinpaatosLiitetiedotUri(asiakas.avain, tilikaudet.nykyinen)
    batch.set(uri, tallennettava.liitetiedot)

    const historiakopio: TilinpaatosLiitetiedot = this._copyService.cloneObjectDeep(tallennettava.liitetiedot)
    historiakopio.avain = this._firebase.firestoreCreateId()
    const historiaUri = this._kirjanpitoUriService.annaTilinpaatosLiitetiedotHistoriaUri(asiakas.avain, tilikaudet.nykyinen, historiakopio.avain)
    batch.set(historiaUri, historiakopio)

    const tilinpaatosLiitetiedotTallennettuTyojonoUri = this._kirjanpitoUriService.annaTilinpaatosLiitetiedotTallennettuTyojonoUri(asiakas.avain, this._firebase.firestoreCreateId())
    const tilinpaatosLiitetiedotTallennettuTyojonoData: LiitetiedotTallennettuTyojonoData = {
      luotu: this._timestampService.now(),
      asiakasAvain: asiakas.avain,
      tilikausiAvain: tilikaudet.nykyinen.avain
    }
    batch.set(tilinpaatosLiitetiedotTallennettuTyojonoUri, tilinpaatosLiitetiedotTallennettuTyojonoData)

    batch.commit().then(() => {
      this._tallentamattomatLiitetiedotSubject.next(this._tilinpaatosLiitetiedotJaettuService.annaTyhjaLiitetiedotObject(tilikaudet.nykyinen, tilikaudet.edellinen))
      this._ladataanService.lopetaLataaminen()
      this._saveInFlight = false
      this._snackbar.open('Liitetiedot tallennettiin onnistuneesti.', 'OK', { duration: 5000, verticalPosition: 'bottom' })
    }).catch(error => {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(error)
      this._snackbar.open('Liitetietojen tallentamisen aikana tapahtui virhe. Ole hyvä ja yritä uudelleen.', 'OK', { duration: 5000, verticalPosition: 'bottom' })
    })

  }

  async downloadPdf() {

    this._ladataanService.aloitaLataaminen()

    const nykyinenAsiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasObservable)
    const tilikausi = await firstValueFrom(this.selectedTilikausiObservable)

    const haku: RaporttiRequest = {
      a: nykyinenAsiakas.avain,
      w: RaporttiType.TILINPAATOS,
      s: this._dateService.localDateToNumber(this._aikavaliSubject.value.start),
      e: this._dateService.localDateToNumber(this._aikavaliSubject.value.end),
      l: this._dateService.dateToLocalDateTime(new Date()),
      k: 'fi',
      ta: tilikausi.avain
    }

    return this._firebase.functionsCall<RaporttiRequest, RaporttiPdfResponse>('kirjanpitoRaportitPdf', haku).then(
      data => {
        if (data.e) {
          switch (data.e) {
            case 'unknown-report': {
              this._snackbar.open('Raporttia ei ole vielä implementoitu.', 'OK')
              break
            }
            case 'view-not-allowed': {
              this._snackbar.open('Käyttäjälla ei ole oikeutta nähdä tätä asiakasta.', 'OK')
              break
            }
            default: {
              this._snackbar.open('Tietojen lataaminen epäonnistui. (' + data.e + ')', 'OK')
            }
          }
        } else {
          if (data.base64File) {
            this._fileSaverService.saveBase64As(data.base64File, data.fileName, 'pdf')
          } else {
            this._snackbar.open('PDF:n lataaminen epäonnistui.')
          }
        }
        this._ladataanService.lopetaLataaminen()
      }
    )
  }

  async deleteAttachment(file: TilinpaatosLiitetiedotBinaariliite) {
    file.poistettu = true
    let found: TilinpaatosLiitetiedotBinaariliite = this._tallentamattomatLiitetiedotSubject.value.liitteet.find(a => a.avain === file.avain)
    if (found) {
      found.poistettu = true
    } else {
      found = {
        avain: file.avain,
        fileType: file.fileType,
        koko: file.koko,
        ladattu: file.ladattu,
        nimi: file.nimi,
        poistettu: file.poistettu
      }
      this._tallentamattomatLiitetiedotSubject.value.liitteet.push(found)
    }
    this._tallentamattomatLiitetiedotSubject.next(this._tallentamattomatLiitetiedotSubject.value)
    this.tallennaLiitteet()
  }

  public async lataa(event: Event) {
    this.latausVirheObservable.next(null)
    const inputTypeFile = event?.target as HTMLInputElement
    if (inputTypeFile?.files) {
      const list: FileList = inputTypeFile.files
      const tiedostot: NgxFileDropEntry[] = this._tiedostojenLataamisService.fileListToNgxFileDropEntries(list)
      try {
        await this._uploadFile(tiedostot)
        this.tallennaLiitteet()
      } catch (err) {
        this._ladataanService.lopetaLataaminen()
        this.latausVirheObservable.next('Lataamisen aikana tapahtui virhe: ' + err)
      }
    }
  }

  private async _uploadFile(tiedostot: NgxFileDropEntry[]) {
    this._ladataanService.aloitaLataaminen()
    const asiakasPromise = firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const valittuTilikausiPromise = firstValueFrom(this.selectedTilikausiObservable)
    const [asiakas, tilikausi] = await Promise.all([asiakasPromise, valittuTilikausiPromise])

    for (const tiedosto of tiedostot) {
      const fileEnding = this._tiedostojenLataamisService.getFileEndingFromNgxFileDropEntry(tiedosto)
      const file = await this._tiedostojenLataamisService.getFile(tiedosto.fileEntry as FileSystemFileEntry)
      const avain = this._firebase.firestoreCreateId()

      const metadata: TilinpaatosLiitetiedotBinaariliite = {
        avain: avain,
        nimi: file.name,
        koko: file.size,
        ladattu: this._timestampService.now(),
        fileType: fileEnding,
        poistettu: false
      }

      const storageUri = this._kirjanpitoUriService.annaTilinpaatosLiitetiedotBinaariliiteStorageUri(asiakas.avain, tilikausi.avain, metadata.avain, metadata.fileType)
      const runningUpload = this._tiedostojenLataamisService.upload(this._firebase, storageUri, file, 'tilinpaatos-liitetiedot-eu')
      await firstValueFrom(runningUpload.doneObservable)

      this._tallentamattomatLiitetiedotSubject.value.liitteet.push(metadata)
    }

    this._tallentamattomatLiitetiedotSubject.next(this._tallentamattomatLiitetiedotSubject.value)

    this._ladataanService.lopetaLataaminen()
  }

  async tallennaLiitteet() {
    const paivitys = await this.annaUpdateAihio()
    const update: Partial<TilinpaatosLiitetiedot> = paivitys.update

    const arvo = paivitys.tallennettava.liitetiedot.liitteet
    update.liitteet = arvo

    this._firebase.firestoreSetData(paivitys.updateUri, update, { merge: true }).catch(error => {
      this._errorHandler.handleError(error)
    })
  }

  async tallennaOsionLisarivi(osio: TilinpaatosLiitetiedotOsio) {
    const paivitys = await this.annaUpdateAihio()
    const update: Partial<TilinpaatosLiitetiedot> = paivitys.update

    const arvo = paivitys.tallennettava.liitetiedot.osionLisarivit[osio]
    if (!update.osionLisarivit) { update.osionLisarivit = {} }
    update.osionLisarivit[osio] = arvo

    this._firebase.firestoreSetData(
      paivitys.updateUri, update, { merge: true }
    ).then(() => this._updateKentanLisatiedotSaveSuccess(osio))
      .catch(error => this._errorHandler.handleError(error))
  }

  private _updateKentanLisatiedotSaveSuccess(osio: TilinpaatosLiitetiedotOsio) {
    this.osionLisarivitSaveSuccessMap.set(osio, 1)
    setTimeout(() => {
      this.osionLisarivitSaveSuccessMap.delete(osio)
      this._changeDetectorRef.markForCheck()
    }, 1000)
  }

  lisaaOsionLisarivi(osio: TilinpaatosLiitetiedotOsio) {
    if (!this._tallentamattomatLiitetiedotSubject.value.osionLisarivit) { this._tallentamattomatLiitetiedotSubject.value.osionLisarivit = {} }
    if (!this._tallentamattomatLiitetiedotSubject.value.osionLisarivit[osio]) { this._tallentamattomatLiitetiedotSubject.value.osionLisarivit[osio] = [] }
    this._tallentamattomatLiitetiedotSubject.value.osionLisarivit[osio].push({
      otsikko: null,
      teksti: null,
      tunnus: this._firebase.firestoreCreateId(),
      poistettu: false
    })
    this._tallentamattomatLiitetiedotSubject.next(this._tallentamattomatLiitetiedotSubject.value)
    this.tallennaOsionLisarivi(osio)
  }

  poistaOsionLisarivi(osio: TilinpaatosLiitetiedotOsio, lisatieto: LisatietoKentta) {
    this._dialog.open(AreYouSureDialog, {
      data: {
        rightAction: 'Poista lisätieto',
        text: 'Haluatko varmasti poistaa tämän lisätiedon?'
      }
    }).afterClosed().subscribe(confirmed => { if (confirmed) { this._poistaOsionLisarivi(osio, lisatieto) } })
  }

  private _poistaOsionLisarivi(osio: TilinpaatosLiitetiedotOsio, lisatieto: LisatietoKentta) {
    let found = this._tallentamattomatLiitetiedotSubject.value.osionLisarivit?.[osio]?.find(a => a.tunnus === lisatieto.tunnus)
    if (!found) {
      found = {
        otsikko: lisatieto.otsikko,
        teksti: lisatieto.teksti,
        poistettu: lisatieto.poistettu,
        tunnus: lisatieto.tunnus
      }
      if (!this._tallentamattomatLiitetiedotSubject.value.osionLisarivit[osio]) {
        this._tallentamattomatLiitetiedotSubject.value.osionLisarivit[osio] = []
      }
      this._tallentamattomatLiitetiedotSubject.value.osionLisarivit[osio].push(found)
    }
    found.poistettu = true
    lisatieto.poistettu = true
    this._tallentamattomatLiitetiedotSubject.next(this._tallentamattomatLiitetiedotSubject.value)
    this.tallennaOsionLisarivi(osio)
  }

}
