<div class="list-page kapea" style="max-width: 1500px;">
  <app-asiakas-otsikko otsikko="Ajastetut työt" paluuUrl="/asiakkaat" [asiakas]="asiakas"></app-asiakas-otsikko>

  <p class="dark-gray-text" style="width: 65em; margin: 3em 0;">Täällä voit selailla Lemonatorin työjonossa olevia ajastettuja töitä. Ajastettujen töiden tarkoituksena on automatisoida toimintaa sekä siten vähentää manuaalisesti tehtävää työtä. Ajastetut työt tapahtuvat automaattisesti, eikä niihin voi tehdä muutoksia itsenäisesti.</p>

  <form [formGroup]="hakuform" novalidate>

    <div class="ylaosan-kontrollit" style="margin: 0; justify-content: space-between; align-items: center;">
      <div class="hakukontrollit" style="align-items: baseline;">

        <mat-form-field floatPlaceholder="always" style="width: 570px; margin-right: 1.5vw;">
          <mat-label>Tyyppi</mat-label>
          <mat-select formControlName="tyyppi">
            <mat-option [value]="'all'">Kaikki</mat-option>
            <mat-option *ngFor="let type of ajastetunTyonTyyppi" [value]="type.tyyppi">
              {{ type.teksti }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-radio-group formControlName="tulossaVaiHistoria">
          <mat-radio-button [value]="'tulossa'">Tulossa</mat-radio-button>
          <mat-radio-button [value]="'historia'">Historia</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </form>

  <div style="width:100%;">
    <div style="position: relative; width: 100%;">
      <mat-table mat-table multiTemplateDataRows matSort [dataSource]="dataSource" [matSortDisableClear]="true" style="min-height: 94px;">

        <ng-container matColumnDef="ajoaika">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 180px;"> Ajoaika </th>
          <td mat-cell class="mat-mdc-cell" *matCellDef="let row" style="min-width: 180px;">
            <tr>
              <td>{{ (row.ajoaika.toDate() | ldatetime | async) || 'Ei saatavilla' }}</td>
            </tr>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- <ng-container matColumnDef="tyyppi">
          <th mat-header-cell *matHeaderCellDef style="min-width: 300px;"> Tyyppi </th>
          <td mat-cell class="mat-mdc-cell" *matCellDef="let row" style="min-width: 300px;">
            <tr>
              <td>{{ row.tyyppi || 'Ei saatavilla' }}</td>
            </tr>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container> -->

        <ng-container matColumnDef="selite">
          <th mat-header-cell *matHeaderCellDef style="min-width: 400px;"> Selite </th>
          <td mat-cell class="mat-mdc-cell" *matCellDef="let row" style="min-width: 400px;">
            <tr>
              <td>{{ row.selite || 'Ei saatavilla' }}</td>
            </tr>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <mat-header-row *matHeaderRowDef="naytettavatKolumnit"></mat-header-row>
        <mat-row (click)="katsoTiedot(row)" *matRowDef="let row; columns: naytettavatKolumnit;"></mat-row>

      </mat-table>

      <mat-paginator [length]="dataSource.data?.length" [pageSize]="25" [pageSizeOptions]="[20, 50, 200]">
      </mat-paginator>
    </div>
  </div>
</div>