import {
  AsiakkaanTuote,
  AsiakkaanTuoteLaskussa,
  EmailLahetysStatus,
  EmailLahetysStatusKoodi,
  UUDEN_LASKUN_AVAIN,
  Lasku,
  LaskuBase,
  LaskunAsiakas,
  LaskunTila,
  LaskuTulostamistyojononMerkinta,
  LaskuEmailtyojononMerkinta,
  TuoteAvaimet,
  TypeaheadAsiakasIlmanAvainta,
  TypeaheadTuoteIlmanAvainta,
  LaskuToimintaloki,
  LaskunToimintalokiTyyppi,
  LaskunToimintalokiSpostiParametrit,
  EI_LASKUNUMEROA_NUMERO,
  LaskuReskontra,
  LaskunSahkpostipohjanVari,
  LaskuSahkoinentyojononMerkinta,
  LaskunSahkoinenOsoite,
  LaskuSahkoinenLahetysStatusKoodi,
  LaskunToimintalokiSahkoinenParametrit,
  LaskunLahetystapa,
  LaskunLahetystyyppi,
  LaskuTasapainoitaJaLahetaTyojonomerkinta,
  LaskuPostgresTyojonoData,
  LaskutoimintalokiMerkinta,
  LaskuPerintaEmailtyojononMerkinta,
  LaskuVientiLemonatoriintyojononMerkinta
} from '../../model/lasku'

import { LaskuSharedService, ReskontraService } from './lasku-shared.service'
import { LaskuUriService } from './lasku-uri.service'
import { LaskuIndeksoija } from './lasku.indeksoija'
import { LaskuKopioija } from './lasku.kopioija'
import { CurrencyService } from '../../../_shared-core/service/currency.service'
import { TranslationService } from '../translation.service'
import { TimestampProviderBase } from '../../../_shared-core/service/timestamp-provider.interface'
import { StringService } from '../../../_shared-core/service/string.service'
import { ViitenumeroService } from '../../../_shared-core/service/viitenumero.service'
import { DateService } from '../../../_shared-core/service/date.service'

import { Big } from 'big.js'

import { KayttajanTiedot } from '../../model/kayttaja'


/**
 * An options object that configures the behavior of `set()` calls in
 * `DocumentReference`, `WriteBatch` and `Transaction`. These calls can be
 * configured to perform granular merges instead of overwriting the target
 * documents in their entirety.
 */
export interface SetOptions {
  /**
   * Changes the behavior of a set() call to only replace the values specified
   * in its data argument. Fields omitted from the set() call remain
   * untouched.
   */
  readonly merge?: boolean

  /**
   * Changes the behavior of set() calls to only replace the specified field
   * paths. Any field path that is not specified is ignored and remains
   * untouched.
   *
   * It is an error to pass a SetOptions object to a set() call that is
   * missing a value for any of the fields specified here.
   */
  readonly mergeFields?: (string)[]
}

export interface FirestoreWriteBatch {
  set(doc: FirestoreDoc, data: any, options?: SetOptions)
  update(doc: FirestoreDoc, data: any)
  delete(doc: FirestoreDoc)
  commit(): Promise<any>
}

export interface FirestoreDoc {

}

export interface FirestoreProvider {
  annaUusiBatch(): FirestoreWriteBatch
  annaDoc(uri: string): FirestoreDoc
  annaUusiAvain(): string
  annaDeleteArvo(): any // TODO: This is FieldValue, but is a different class depending where imported from.
}

export interface BatchManipulator {
  manipulate(firestoreProvider: FirestoreProvider, batch: FirestoreWriteBatch, juurilasku: Lasku, kasiteltava: LaskuBase)
}

export class LaskuTallennusService {

  constructor(
    private shared: LaskuSharedService,
    private laskuKopioija: LaskuKopioija,
    private laskuIndeksoija: LaskuIndeksoija,
    private currencyService: CurrencyService,
    private dateService: DateService,
    private laskuUriService: LaskuUriService,
    private translationService: TranslationService,
    private timestampService: TimestampProviderBase,
    private reskontraService: ReskontraService,
    private firestoreProvider: FirestoreProvider,
    private viitenumeroService: ViitenumeroService,
    private stringService: StringService
  ) {

  }

  lisaaAsiakasBatchiin(asiakasId: string, asiakas: LaskunAsiakas, batch: FirestoreWriteBatch) {
    // Aseta avain
    asiakas.avain = asiakas.avain ? asiakas.avain : this.firestoreProvider.annaUusiAvain()

    // Luo reffit
    const uri = this.laskuUriService.getLaskunAsiakkaanUri(asiakasId, asiakas.avain)
    const ref = this.firestoreProvider.annaDoc(uri)
    const typeaheadUri = this.laskuUriService.getTypeaheadAsiakasDokumentinUri(asiakasId)
    const typeaheadRef = this.firestoreProvider.annaDoc(typeaheadUri)

    // Create typeahead data
    const typeaheadDataContainer = {}
    if (asiakas.poistettu) {
      typeaheadDataContainer[asiakas.avain] = this.firestoreProvider.annaDeleteArvo()
    } else {
      const typeaheadData: TypeaheadAsiakasIlmanAvainta = {
        nimi: asiakas.nimi,
        ytunnus: asiakas.ytunnus
      }
      typeaheadDataContainer[asiakas.avain] = typeaheadData
    }

    // Update typeahead
    const options: SetOptions = { merge: true }
    batch.set(typeaheadRef, typeaheadDataContainer, options)

    // Save asiakas
    asiakas.date = this.timestampService.now()
    const copied = this.laskuKopioija.copyAsiakas(asiakas)
    copied['haku'] = this.laskuIndeksoija.luoHakutiedotAsiakkaalle(asiakas)
    batch.set(ref, copied)
  }

  private lisaaHistoriaMerkinta(kayttajanTiedot: KayttajanTiedot, juurilasku: Lasku, batch: FirestoreWriteBatch) {

    const historiaAvain = this.firestoreProvider.annaUusiAvain()
    const historiaUri = this.laskuUriService.getLaskuHistoriaUri(kayttajanTiedot.asiakasId, juurilasku.avain, historiaAvain)
    const historiaRef = this.firestoreProvider.annaDoc(historiaUri)

    const kopioitu = this.laskuKopioija.copyLasku(juurilasku)
    delete kopioitu['haku']
    kopioitu.date = this.timestampService.now()
    kopioitu['tallentaja'] = kayttajanTiedot.uid

    batch.set(historiaRef, kopioitu)

  }

  private lisaaToimintalokiMerkinta(kayttajanTiedot: KayttajanTiedot, tyyppi: LaskunToimintalokiTyyppi, juurilasku: Lasku, kasiteltava: LaskuBase, batch: FirestoreWriteBatch, parametrit?: LaskunToimintalokiSahkoinenParametrit | LaskunToimintalokiSpostiParametrit) {
    const toimintalokiAvain = this.firestoreProvider.annaUusiAvain()
    const toimintalokiUri = this.laskuUriService.getLaskuToimintalokiUri(kayttajanTiedot.asiakasId, juurilasku.avain, toimintalokiAvain)
    const toimintalokiRef = this.firestoreProvider.annaDoc(toimintalokiUri)

    const lokidata: LaskuToimintaloki = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      lasku_uid: kasiteltava.avain,
      pvm: this.timestampService.now(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      tekija_uid: kayttajanTiedot.uid,
      toiminto: tyyppi,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      lasku_nro: this.shared.annaMuotoiltuLaskunumero(juurilasku, kasiteltava),
      parametrit: parametrit ?? null
    }

    if (kayttajanTiedot.euid) {
      lokidata.emuloija_uid = kayttajanTiedot.euid
    }

    batch.set(toimintalokiRef, lokidata)
  }

  lisaaTuoteBatchiin(asiakasId: string, tuote: AsiakkaanTuote, batch: FirestoreWriteBatch) {
    // Aseta avain
    tuote.$key = tuote.$key ? tuote.$key : this.firestoreProvider.annaUusiAvain()

    // Luo reffit
    const uri = this.laskuUriService.getTuoteUri(asiakasId, tuote)
    const ref = this.firestoreProvider.annaDoc(uri)
    const typeaheadUri = this.laskuUriService.getTypeaheadTuoteDokumentinUri(asiakasId)
    const typeaheadRef = this.firestoreProvider.annaDoc(typeaheadUri)

    // Create typeahead data
    const typeaheadDataContainer = {}
    if (tuote.poistettu) {
      typeaheadDataContainer[tuote.$key] = this.firestoreProvider.annaDeleteArvo()
    } else {
      const typeaheadData: TypeaheadTuoteIlmanAvainta = {
        nimi: tuote.nimi
      }
      typeaheadDataContainer[tuote.$key] = typeaheadData
    }

    // Update typeahead
    const options: SetOptions = { merge: true }
    batch.set(typeaheadRef, typeaheadDataContainer, options)

    // Tallenna tuote
    tuote.date = this.timestampService.now()
    const copied = this.laskuKopioija.copyTuote(tuote)
    copied['haku'] = this.laskuIndeksoija.luoHakutiedotTuotteelle(tuote)
    batch.set(ref, copied)
  }

  lahetaSahkoinenUudelleen(kayttajanTiedot: KayttajanTiedot, juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[]): Promise<void> {

    const laskuUri = this.laskuUriService.getLaskuUri(kayttajanTiedot.asiakasId, juurilasku.avain)
    const tyojonoUri = this.laskuUriService.getTyojonoLaskuSahkoinenlUri(kayttajanTiedot.asiakasId, this.firestoreProvider.annaUusiAvain())
    const batch = this.firestoreProvider.annaUusiBatch()

    kasiteltava.sahkoinen.status = LaskuSahkoinenLahetysStatusKoodi.PROSESSOIDAAN

    // Lisää lokimerkinnät
    const parametrit: LaskunToimintalokiSahkoinenParametrit = {
      osoite: kasiteltava.sahkoinen.osoite
    }
    this.lisaaToimintalokiMerkinta(kayttajanTiedot, LaskunToimintalokiTyyppi.LAHETETTY_SAHKOINEN, juurilasku, kasiteltava, batch, parametrit)
    this.lisaaHistoriaMerkinta(kayttajanTiedot, juurilasku, batch)

    // Päivitä virheellinen email Lemonaid asiakkaassa
    const laskunAsiakas = kasiteltava.asiakas
    laskunAsiakas.sahkoinenosoite = kasiteltava.sahkoinen.osoite
    laskunAsiakas.viimeisinLaskuLahetetty = LaskunLahetystapa.SAHKOINEN

    // Tallenna lasku
    const copied = this.annaTallennettavaKopio(juurilasku, kasiteltava, reskontra)
    const laskuRef = this.firestoreProvider.annaDoc(laskuUri)
    batch.set(laskuRef, copied)

    // Tallenna tyojono
    const tyojonomerkinta: LaskuSahkoinentyojononMerkinta = {
      laskuAvain: juurilasku.avain,
      kasiteltavaAvain: kasiteltava.avain,
      asiakasAvain: kayttajanTiedot.asiakasAvain,
      asiakasId: kayttajanTiedot.asiakasId,
      uudelleenyrityksia: 0,
      aloitettu: this.timestampService.now()
    }
    const tyojonomerkintaUri = this.firestoreProvider.annaDoc(tyojonoUri)
    batch.set(tyojonomerkintaUri, tyojonomerkinta)

    // Tallenna Postgres työjono
    this.lisaaPostgresTyojonoBatchiin(juurilasku, kayttajanTiedot, batch)

    this.lisaaAsiakasBatchiin(kayttajanTiedot.asiakasId, laskunAsiakas, batch)

    return batch.commit()

  }

  lahetaEmailUudelleen(kayttajanTiedot: KayttajanTiedot, juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[], vastaanottaja: EmailLahetysStatus, vanhaEmail: string): Promise<void> {

    const laskuUri = this.laskuUriService.getLaskuUri(kayttajanTiedot.asiakasId, juurilasku.avain)
    const tyojonoUri = this.laskuUriService.getTyojonoLaskuEmailUri(kayttajanTiedot.asiakasId, this.firestoreProvider.annaUusiAvain())
    const batch = this.firestoreProvider.annaUusiBatch()

    vastaanottaja.status = EmailLahetysStatusKoodi.PROSESSOIDAAN

    // Lisää lokimerkinnät
    const parametrit: LaskunToimintalokiSpostiParametrit = {
      emailit: [vastaanottaja.email]
    }
    this.lisaaToimintalokiMerkinta(kayttajanTiedot, LaskunToimintalokiTyyppi.LAHETETTY_SPOSTI, juurilasku, kasiteltava, batch, parametrit)
    this.lisaaHistoriaMerkinta(kayttajanTiedot, juurilasku, batch)

    // Päivitä virheellinen email Lemonaid asiakkaassa
    const laskunAsiakas = kasiteltava.asiakas
    if (laskunAsiakas.laskunVastaanottajat) {
      const index = laskunAsiakas.laskunVastaanottajat.indexOf(vanhaEmail)
      if (index > -1) {
        laskunAsiakas.laskunVastaanottajat.splice(index, 1)
      }
    } else {
      laskunAsiakas.laskunVastaanottajat = []
    }
    laskunAsiakas.laskunVastaanottajat.push(vastaanottaja.email)
    laskunAsiakas.viimeisinLaskuLahetetty = LaskunLahetystapa.SAHKOPOSTI

    // Tallenna lasku
    const copied = this.annaTallennettavaKopio(juurilasku, kasiteltava, reskontra)
    const laskuRef = this.firestoreProvider.annaDoc(laskuUri)
    batch.set(laskuRef, copied)

    // Tallenna tyojono
    const tyojonomerkinta: LaskuEmailtyojononMerkinta = {
      kasiteltavaAvain: kasiteltava.avain,
      laskuAvain: juurilasku.avain,
      asiakasAvain: kayttajanTiedot.asiakasAvain,
      asiakasId: kayttajanTiedot.asiakasId,
      uudelleenyrityksia: 0,
      aloitettu: this.timestampService.now()
    }
    const tyojonomerkintaUri = this.firestoreProvider.annaDoc(tyojonoUri)
    batch.set(tyojonomerkintaUri, tyojonomerkinta)

    this.lisaaAsiakasBatchiin(kayttajanTiedot.asiakasId, laskunAsiakas, batch)

    return batch.commit()

  }

  peruutaLuottotappiomerkinta(kayttajanTiedot: KayttajanTiedot, juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[]): Promise<void> {

    // const tyojonoId = this.firestoreProvider.annaUusiAvain()
    const laskuUri = this.laskuUriService.getLaskuUri(kayttajanTiedot.asiakasId, juurilasku.avain)
    const batch = this.firestoreProvider.annaUusiBatch()

    this.shared.paivitaLaskunTila(juurilasku, reskontra)

    // Lisää lokimerkinnät
    this.lisaaToimintalokiMerkinta(kayttajanTiedot, LaskunToimintalokiTyyppi.LUOTTOTAPPIO_MERKINTA_PERUUTETTU, juurilasku, kasiteltava, batch, null)
    this.lisaaHistoriaMerkinta(kayttajanTiedot, juurilasku, batch)

    // Tallenna lasku
    const copied = this.annaTallennettavaKopio(juurilasku, kasiteltava, reskontra)
    const laskuRef = this.firestoreProvider.annaDoc(laskuUri)
    batch.set(laskuRef, copied)

    // Tallenna Postgres työjono
    this.lisaaPostgresTyojonoBatchiin(juurilasku, kayttajanTiedot, batch)

    // Vie Lemonatoriin
    this.lisaaLemonatoriinVientiTyojonoBatchiin(juurilasku, kasiteltava, kayttajanTiedot, batch)

    return batch.commit()

  }

  mitatoiLasku(kayttajanTiedot: KayttajanTiedot, juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[]): Promise<LaskuBase> {

    const tyojonoId = this.firestoreProvider.annaUusiAvain()
    const tyojonoUri = this.laskuUriService.getTyojonoLaskuTulostusUri(kayttajanTiedot.asiakasId, tyojonoId)
    const laskuUri = this.laskuUriService.getLaskuUri(kayttajanTiedot.asiakasId, juurilasku.avain)
    const batch = this.firestoreProvider.annaUusiBatch()

    // Kopioi lasku
    const copied = this.laskuKopioija.copyLasku(juurilasku)
    const copiedKasiteltava = this.laskuKopioija.kopioiLaskuMuokattavaksi(kasiteltava, null)

    // Sanitization:
    copiedKasiteltava.email = null
    copiedKasiteltava.sahkoinen = null

    // Aseta avaimet
    copied.avain = copied.avain && copied.avain !== UUDEN_LASKUN_AVAIN ? copied.avain : this.firestoreProvider.annaUusiAvain()
    copiedKasiteltava.avain = copiedKasiteltava.avain && copiedKasiteltava.avain !== UUDEN_LASKUN_AVAIN ? copiedKasiteltava.avain : this.firestoreProvider.annaUusiAvain()

    if (!copied.korvaus) {
      copied.korvaus = []
    }
    copied.korvaus.push(copiedKasiteltava)

    // Asetetaan käsiteltävä lukkoon, jotta sitä ei voi enää muokata
    copiedKasiteltava.lukossa = true
    copiedKasiteltava.print = {
      done: null,
      start: this.timestampService.now()
    }

    // Muuta tila
    copied.tila = LaskunTila.mitatoity

    // Nollaa summat
    const summat = this.shared.annaLaskunSummat(copiedKasiteltava)
    for (const alv of summat.alvErittely) {

      // eslint-disable-next-line @typescript-eslint/naming-convention
      const nimi = this.translationService.lokalisoi('lasku.mitatointirivin-nimi', copiedKasiteltava.kieli, { 'alv-kanta': this.shared.annaAlvNimi(copiedKasiteltava, alv.alvKanta) })

      const tuote: AsiakkaanTuoteLaskussa = {
        alv: {},
        avain: TuoteAvaimet.MITATOINTI,
        date: this.timestampService.now(),
        hinta: 0,
        nimi: nimi
      }

      copiedKasiteltava.tuotteet.push({
        ale: null,
        maara: 1,
        alv: alv.alvKanta,
        hinta: this.currencyService.muutaBigDecimalRahaksi(new Big('0').minus(alv.alvitonSumma)),
        tuote: tuote
      })

    }

    // Ja päivitä summat
    this.shared.paivitaRyppaanJaKasiteltavanSummat(copied, copiedKasiteltava, reskontra)

    // Lisää merkintä toimintalokiin
    this.lisaaToimintalokiMerkinta(kayttajanTiedot, LaskunToimintalokiTyyppi.MITATOITY, copied, copiedKasiteltava, batch)
    this.lisaaHistoriaMerkinta(kayttajanTiedot, juurilasku, batch)

    // Tallenna tyojono
    const tyojonomerkinta: LaskuTulostamistyojononMerkinta = {
      laskuAvain: copied.avain,
      kasiteltavaAvain: copiedKasiteltava.avain,
      asiakasAvain: kayttajanTiedot.asiakasAvain,
      asiakasId: kayttajanTiedot.asiakasId,
      uudelleenyrityksia: 0,
      aloitettu: this.timestampService.now()
    }
    const tyojonomerkintaUri = this.firestoreProvider.annaDoc(tyojonoUri)
    batch.set(tyojonomerkintaUri, tyojonomerkinta)

    // Tallenna lasku
    copied['haku'] = this.laskuIndeksoija.luoHakutiedotLaskulle(copied, copiedKasiteltava, reskontra)
    const laskuRef = this.firestoreProvider.annaDoc(laskuUri)
    batch.set(laskuRef, copied)

    // Tallenna Postgres työjono
    this.lisaaPostgresTyojonoBatchiin(juurilasku, kayttajanTiedot, batch)

    return batch.commit().then(() => {
      return copiedKasiteltava
    })

  }

  laskeReskontraUudelleen(kayttajanTiedot: KayttajanTiedot, juurilasku: Lasku, reskontra: LaskuReskontra[]): Promise<void> {

    const laskuUri = this.laskuUriService.getLaskuUri(kayttajanTiedot.asiakasId, juurilasku.avain)
    const batch = this.firestoreProvider.annaUusiBatch()

    const kasiteltava = this.shared.annaViimeisinEiLuonnosLasku(juurilasku)

    this.shared.paivitaRyppaanJaKasiteltavanSummat(juurilasku, kasiteltava, reskontra)
    this.shared.paivitaLaskunTila(juurilasku, reskontra)

    // Tallenna lasku
    const copied = this.annaTallennettavaKopio(juurilasku, kasiteltava, reskontra)
    const laskuRef = this.firestoreProvider.annaDoc(laskuUri)
    batch.set(laskuRef, copied)

    // Tallenna Postgres työjono
    this.lisaaPostgresTyojonoBatchiin(juurilasku, kayttajanTiedot, batch)
    return batch.commit()

  }

  indeksoiUudelleen(kayttajanTiedot: KayttajanTiedot, juurilasku: Lasku, reskontra: LaskuReskontra[]): Promise<void> {

    const laskuUri = this.laskuUriService.getLaskuUri(kayttajanTiedot.asiakasId, juurilasku.avain)
    const batch = this.firestoreProvider.annaUusiBatch()

    const kasiteltava = this.shared.annaViimeisinEiLuonnosLasku(juurilasku)

    this.shared.paivitaRyppaanJaKasiteltavanSummat(juurilasku, kasiteltava, reskontra)

    // Tallenna lasku
    const copied = this.annaTallennettavaKopio(juurilasku, kasiteltava, reskontra)
    const laskuRef = this.firestoreProvider.annaDoc(laskuUri)
    batch.set(laskuRef, copied)

    // Tallenna Postgres työjono
    this.lisaaPostgresTyojonoBatchiin(juurilasku, kayttajanTiedot, batch)
    return batch.commit()

  }

  merkitseLuottotappioksi(kayttajanTiedot: KayttajanTiedot, juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[]): Promise<void> {

    // const tyojonoId = this.firestoreProvider.annaUusiAvain()
    const laskuUri = this.laskuUriService.getLaskuUri(kayttajanTiedot.asiakasId, juurilasku.avain)
    const batch = this.firestoreProvider.annaUusiBatch()

    juurilasku.tila = LaskunTila.luottotappio

    // Lisää lokimerkinnät
    this.lisaaToimintalokiMerkinta(kayttajanTiedot, LaskunToimintalokiTyyppi.MERKITTY_LUOTTOTAPPIOKSI, juurilasku, kasiteltava, batch, null)
    this.lisaaHistoriaMerkinta(kayttajanTiedot, juurilasku, batch)

    // Tallenna lasku
    const copied = this.annaTallennettavaKopio(juurilasku, kasiteltava, reskontra)
    const laskuRef = this.firestoreProvider.annaDoc(laskuUri)
    batch.set(laskuRef, copied)

    // Tallenna Postgres työjono
    this.lisaaPostgresTyojonoBatchiin(juurilasku, kayttajanTiedot, batch)

    // Vie Lemonatoriin
    this.lisaaLemonatoriinVientiTyojonoBatchiin(juurilasku, kasiteltava, kayttajanTiedot, batch)

    return batch.commit()

  }

  tallennaKommentti(kayttajanTiedot: KayttajanTiedot, juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[], kommentti: string): Promise<void> {

    // const tyojonoId = this.firestoreProvider.annaUusiAvain()
    const laskuUri = this.laskuUriService.getLaskuUri(kayttajanTiedot.asiakasId, juurilasku.avain)
    const batch = this.firestoreProvider.annaUusiBatch()

    if (this.stringService.removeAllWhiteSpaces(kommentti)) {
      juurilasku.kommentti = kommentti.trim()
    } else {
      delete juurilasku.kommentti
    }

    // Lisää lokimerkinnät
    this.lisaaToimintalokiMerkinta(kayttajanTiedot, LaskunToimintalokiTyyppi.KOMMENTTI_PAIVITETTY, juurilasku, kasiteltava, batch, null)
    this.lisaaHistoriaMerkinta(kayttajanTiedot, juurilasku, batch)

    // Tallenna lasku
    const copied = this.annaTallennettavaKopio(juurilasku, kasiteltava, reskontra)
    const laskuRef = this.firestoreProvider.annaDoc(laskuUri)
    batch.set(laskuRef, copied)

    // Tallenna Postgres työjono
    this.lisaaPostgresTyojonoBatchiin(juurilasku, kayttajanTiedot, batch)

    return batch.commit()

  }

  lisaaReskontraMerkinta(kayttajanTiedot: KayttajanTiedot, juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[], suoritus: number): Promise<void> {

    const suoritettuBig = new Big(suoritus).round(2, Big.roundHalfUp)
    const now = this.timestampService.now()
    const localDate = this.dateService.timestampToLocalDate(now)
    const numberDate = this.dateService.localDateToNumber(localDate)
    const reskontramerkinta: LaskuReskontra = {
      avain: this.firestoreProvider.annaUusiAvain(),
      tekijaUid: kayttajanTiedot.uid,
      suoritus: this.currencyService.muutaBigDecimalRahaksi(suoritettuBig),
      pvm: now,
      pvml: localDate,
      p: numberDate,
      luotu: now,
      tyyppi: 'kasin'
    }

    reskontra.push(reskontramerkinta)
    this.shared.paivitaLaskunTila(juurilasku, reskontra)

    // Päivitä summat
    this.shared.paivitaRyppaanJaKasiteltavanSummat(juurilasku, kasiteltava, reskontra)

    const laskuUri = this.laskuUriService.getLaskuUri(kayttajanTiedot.asiakasId, juurilasku.avain)
    const batch = this.firestoreProvider.annaUusiBatch()

    // Tallenna lasku
    const copied = this.annaTallennettavaKopio(juurilasku, kasiteltava, reskontra)
    const laskuRef = this.firestoreProvider.annaDoc(laskuUri)
    batch.set(laskuRef, copied)

    // Tallenna reskontramerkintä
    const reskontramerkintaUri = this.laskuUriService.getLaskuReskontraUri(kayttajanTiedot.asiakasId, juurilasku.avain, reskontramerkinta.avain)
    const reskontramerkintaDoc = this.firestoreProvider.annaDoc(reskontramerkintaUri)
    batch.set(reskontramerkintaDoc, reskontramerkinta)

    // Tallenna Postgres työjono
    this.lisaaPostgresTyojonoBatchiin(juurilasku, kayttajanTiedot, batch)
    return batch.commit()

  }

  annaEdellinenEiNollaReskontramerkinta(reskontra: LaskuReskontra[]): null | LaskuReskontra {
    if (reskontra && reskontra.length > 0) {
      for (let i = reskontra.length; i > 0; i--) {
        const merkinta = reskontra[i - 1]
        const suoritus = new Big(merkinta.suoritus)
        if (!suoritus.round(2, Big.roundHalfUp).eq(new Big('0'))) {
          return merkinta
        }
      }
    }
    return null
  }

  merkitseLaskuTaysinMaksetuksi(kayttajanTiedot: KayttajanTiedot, juurilasku: Lasku, reskontra: LaskuReskontra[]): Promise<void> {

    const viimeinenTavallinen = this.shared.annaViimeisinTavallinenLasku(juurilasku)
    const summatJaTila = this.shared.annaLaskunSummatJaTilaLaskulleReskontralla(juurilasku, reskontra)

    // const roundedYhteensa = summatJaTila.laskunSummat.yhteensaKaikki.round(2, Big.roundHalfUp)
    // const roundedReskontra = summatJaTila.reskontrasumma.round(2, Big.roundHalfUp)

    const avoinna = summatJaTila.avoinna.round(2, Big.roundHalfUp)

    let suoritus = new Big('0')
    if (avoinna.eq(new Big('0'))) {
      const edellinenEiNolla = this.annaEdellinenEiNollaReskontramerkinta(reskontra)
      if (edellinenEiNolla) {
        suoritus = suoritus.minus(edellinenEiNolla.suoritus)
      }
    } else {
      suoritus = avoinna
    }

    const maara = this.currencyService.muutaBigDecimalRahaksi(suoritus)

    return this.lisaaReskontraMerkinta(kayttajanTiedot, juurilasku, viimeinenTavallinen, reskontra, maara)
  }

  poistaLuonnos(kayttajanTiedot: KayttajanTiedot, juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[]): Promise<void> {

    // Sanitization:
    kasiteltava.email = null
    kasiteltava.print = null
    kasiteltava.sahkoinen = null
    kasiteltava.nro = EI_LASKUNUMEROA_NUMERO

    const laskuUri = this.laskuUriService.getLaskuUri(kayttajanTiedot.asiakasId, juurilasku.avain)

    const batch = this.firestoreProvider.annaUusiBatch()

    if (juurilasku.avain === kasiteltava.avain) {
      // Tämä on ensimmäinen lasku, poista koko hakuindeksi, jolloin lasku ei näy hauissa, eli on käyttäjälle poistettu
      const copied = this.laskuKopioija.copyLasku(juurilasku)
      copied['haku'] = null
      const laskuRef = this.firestoreProvider.annaDoc(laskuUri)
      batch.set(laskuRef, copied)
    } else {
      const poistettavaKasiteltava = juurilasku.korvaus[juurilasku.korvaus.length - 1]
      if (poistettavaKasiteltava.avain === kasiteltava.avain) {
        juurilasku.korvaus.pop()
      }

      if (juurilasku.korvaus.length > 0) {
        kasiteltava = juurilasku.korvaus[juurilasku.korvaus.length - 1]
      } else {
        kasiteltava = juurilasku
      }

      // Päivitä summat
      this.shared.paivitaRyppaanJaKasiteltavanSummat(juurilasku, kasiteltava, reskontra)

      // Aseta tila
      this.shared.paivitaLaskunTila(juurilasku, reskontra)

      const copied = this.annaTallennettavaKopio(juurilasku, kasiteltava, reskontra)
      const laskuRef = this.firestoreProvider.annaDoc(laskuUri)
      batch.set(laskuRef, copied)
    }

    // Lisää lokimerkinnät
    this.lisaaToimintalokiMerkinta(kayttajanTiedot, LaskunToimintalokiTyyppi.LUONNOS_POISTETTU, juurilasku, kasiteltava, batch)
    this.lisaaHistoriaMerkinta(kayttajanTiedot, juurilasku, batch)

    return batch.commit()

  }

  tallennaLuonnos(kayttajanTiedot: KayttajanTiedot, juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[]): Promise<void> {

    // Sanitization:
    kasiteltava.email = null
    kasiteltava.print = null
    kasiteltava.sahkoinen = null

    // Poista spesiaalit nollarivit
    kasiteltava.tuotteet = kasiteltava.tuotteet.filter(tuote => {
      return ((!tuote || !tuote.tuote) || (tuote.tuote.avain !== TuoteAvaimet.HUOMAUTUSKULU && tuote.tuote.avain !== TuoteAvaimet.KORKOKULU)) || Math.floor(tuote.hinta * 100) !== 0
    })

    // console.log('TÄÄSSÄÄ' + kasiteltava.avain)

    // Aseta avaimet
    juurilasku.avain = juurilasku.avain && juurilasku.avain !== UUDEN_LASKUN_AVAIN ? juurilasku.avain : this.firestoreProvider.annaUusiAvain()
    kasiteltava.avain = kasiteltava.avain && kasiteltava.avain !== UUDEN_LASKUN_AVAIN ? kasiteltava.avain : this.firestoreProvider.annaUusiAvain()

    // const tyojonoId = this.firestoreProvider.annaUusiAvain()
    const laskuUri = this.laskuUriService.getLaskuUri(kayttajanTiedot.asiakasId, juurilasku.avain)
    // const tyojonoUri = this.laskuUriService.getTyojonoLaskuTulostusUri(kayttajanTiedot.asiakasId, tyojonoId)
    const batch = this.firestoreProvider.annaUusiBatch()

    // Päivitä summat
    this.shared.paivitaRyppaanJaKasiteltavanSummat(juurilasku, kasiteltava, reskontra)

    // Aseta tila
    juurilasku.tila = LaskunTila.luonnos

    // Lisää batchiin tuotteet ja asiakas
    // this.paivitaTuotteetJaAsiakas(kasiteltava, kayttajanTiedot, batch)

    // Lisää lokimerkinnät
    const luontiTyyppi = kasiteltava.avain === UUDEN_LASKUN_AVAIN ? LaskunToimintalokiTyyppi.LUONNOS_LUOTU : LaskunToimintalokiTyyppi.LUONNOS_TALLENNETTU
    this.lisaaToimintalokiMerkinta(kayttajanTiedot, luontiTyyppi, juurilasku, kasiteltava, batch)
    this.lisaaHistoriaMerkinta(kayttajanTiedot, juurilasku, batch)

    // Tallenna lasku
    const copied = this.annaTallennettavaKopio(juurilasku, kasiteltava, reskontra)
    copied.nro = EI_LASKUNUMEROA_NUMERO
    const laskuRef = this.firestoreProvider.annaDoc(laskuUri)
    batch.set(laskuRef, copied)

    return batch.commit()

  }

  merkitseLaskuLahetetyksiTulostamalla(kayttajanTiedot: KayttajanTiedot, juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[], batchManipulator?: BatchManipulator, tasapainoitaEnsinKayttaenViitenumeroa?: string): Promise<void> {
    const luontiTyyppi = juurilasku.avain === kasiteltava.avain ? LaskunToimintalokiTyyppi.LUOTU : LaskunToimintalokiTyyppi.MUOKATTU
    const merkinnat: LaskutoimintalokiMerkinta[] = [
      { toiminto: luontiTyyppi },
      { toiminto: LaskunToimintalokiTyyppi.LAHETETTY_TULOSTA }
    ]

    return this._merkitseLaskuLahetetyksiTulostamallaLokiMerkinnnoilla(kayttajanTiedot, juurilasku, kasiteltava, reskontra, merkinnat, batchManipulator, tasapainoitaEnsinKayttaenViitenumeroa)
  }

  merkitseLaskuLahetetyksiTulostamallaToisenHyvaksyntajonoon(kayttajanTiedot: KayttajanTiedot, osoite: LaskunSahkoinenOsoite, juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[], batchManipulator?: BatchManipulator, tasapainoitaEnsinKayttaenViitenumeroa?: string): Promise<void> {
    const luontiTyyppi = juurilasku.avain === kasiteltava.avain ? LaskunToimintalokiTyyppi.LUOTU : LaskunToimintalokiTyyppi.MUOKATTU
    const parametrit: LaskunToimintalokiSahkoinenParametrit = {
      osoite: osoite
    }
    const merkinnat: LaskutoimintalokiMerkinta[] = [
      { toiminto: luontiTyyppi },
      { toiminto: LaskunToimintalokiTyyppi.LAHETETTY_TULOSTA_TOISEN_HYVAKSYNTAJONOON_ODOTTAMAAN_HYVAKSYNTAA, parametrit: parametrit }
    ]
    return this._merkitseLaskuLahetetyksiTulostamallaLokiMerkinnnoilla(kayttajanTiedot, juurilasku, kasiteltava, reskontra, merkinnat, batchManipulator, tasapainoitaEnsinKayttaenViitenumeroa)
  }

  private _merkitseLaskuLahetetyksiTulostamallaLokiMerkinnnoilla(kayttajanTiedot: KayttajanTiedot, juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[], lokimerkinnat: LaskutoimintalokiMerkinta[], batchManipulator?: BatchManipulator, tasapainoitaEnsinKayttaenViitenumeroa?: string): Promise<void> {

    // Sanitization:
    kasiteltava.email = null
    kasiteltava.sahkoinen = null

    // Poista spesiaalit nollarivit
    kasiteltava.tuotteet = kasiteltava.tuotteet.filter(tuote => {
      return (tuote.tuote.avain !== TuoteAvaimet.HUOMAUTUSKULU && tuote.tuote.avain !== TuoteAvaimet.KORKOKULU) || Math.floor(tuote.hinta * 100) !== 0
    })

    // Aseta avaimet
    juurilasku.avain = juurilasku.avain && juurilasku.avain !== UUDEN_LASKUN_AVAIN ? juurilasku.avain : this.firestoreProvider.annaUusiAvain()
    kasiteltava.avain = kasiteltava.avain && kasiteltava.avain !== UUDEN_LASKUN_AVAIN ? kasiteltava.avain : this.firestoreProvider.annaUusiAvain()

    const tyojonoId = this.firestoreProvider.annaUusiAvain()
    const laskuUri = this.laskuUriService.getLaskuUri(kayttajanTiedot.asiakasId, juurilasku.avain)
    const tyojonoUri = this.laskuUriService.getTyojonoLaskuTulostusUri(kayttajanTiedot.asiakasId, tyojonoId)
    const batch = this.firestoreProvider.annaUusiBatch()

    // Päivitä summat
    this.shared.paivitaRyppaanJaKasiteltavanSummat(juurilasku, kasiteltava, reskontra)

    // Asetetaan käsiteltävä lukkoon, jotta sitä ei voi enää muokata
    kasiteltava.lukossa = true
    kasiteltava.print = {
      done: null,
      start: this.timestampService.now()
    }

    // console.log(kasiteltava.print.start, typeof kasiteltava.print.start, kasiteltava.print.start.constructor.name)

    // Aseta tila
    this.shared.paivitaLaskunTila(juurilasku, reskontra)

    // Lisää batchiin tuotteet ja asiakas
    this.paivitaTuotteetJaAsiakas(kayttajanTiedot.asiakasId, kasiteltava, LaskunLahetystapa.ITSE, batch)

    // Lisää lokimerkinnät
    for (const lokimerkinta of lokimerkinnat) {
      this.lisaaToimintalokiMerkinta(kayttajanTiedot, lokimerkinta.toiminto, juurilasku, kasiteltava, batch, lokimerkinta.parametrit)
    }
    this.lisaaHistoriaMerkinta(kayttajanTiedot, juurilasku, batch)

    this.tiedotaPerinnassaOlevanLaskunMuutoksestaTarvittaessa(kayttajanTiedot, juurilasku, kasiteltava, batch, this.firestoreProvider)

    // Tallenna lasku
    const copied = this.annaTallennettavaKopio(juurilasku, kasiteltava, reskontra)
    const laskuRef = this.firestoreProvider.annaDoc(laskuUri)
    batch.set(laskuRef, copied)

    // Tallenna tyojono
    if (tasapainoitaEnsinKayttaenViitenumeroa) {
      const tyojonomerkinta: LaskuTasapainoitaJaLahetaTyojonomerkinta = {
        kanava: LaskunLahetystapa.ITSE,
        viitenumero: tasapainoitaEnsinKayttaenViitenumeroa,
        kasiteltavaAvain: kasiteltava.avain,
        laskuAvain: juurilasku.avain,
        uudelleenyrityksia: 0,
        aloitettu: this.timestampService.now(),
        asiakasId: kayttajanTiedot.asiakasId,
        asiakasAvain: kayttajanTiedot.asiakasAvain
      }
      const tasapainoitusUri = this.laskuUriService.getTyojonoLaskuTasapainoitaJaLahetaUri(kayttajanTiedot.asiakasId, tyojonoId)
      const tyojoRef = this.firestoreProvider.annaDoc(tasapainoitusUri)
      batch.set(tyojoRef, tyojonomerkinta)
    } else {
      const tyojonomerkinta: LaskuTulostamistyojononMerkinta = {
        laskuAvain: juurilasku.avain,
        kasiteltavaAvain: kasiteltava.avain,
        asiakasAvain: kayttajanTiedot.asiakasAvain,
        asiakasId: kayttajanTiedot.asiakasId,
        uudelleenyrityksia: 0,
        aloitettu: this.timestampService.now()
      }
      const tyojonomerkintaUri = this.firestoreProvider.annaDoc(tyojonoUri)
      batch.set(tyojonomerkintaUri, tyojonomerkinta)
    }

    // Tallenna Postgres työjono
    this.lisaaPostgresTyojonoBatchiin(juurilasku, kayttajanTiedot, batch)

    // if (copied.print && copied.print.start) {
    //   console.log(copied.print.start, typeof copied.print.start, copied.print.start.constructor.name)
    // }

    if (batchManipulator) {
      batchManipulator.manipulate(this.firestoreProvider, batch, juurilasku, kasiteltava)
    }

    return batch.commit()

  }

  merkitseLaskuLahetetyksiSahkoisesti(kayttajanTiedot: KayttajanTiedot, osoite: LaskunSahkoinenOsoite, juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[], batchManipulator?: BatchManipulator, tasapainoitaEnsinKayttaenViitenumeroa?: string): Promise<void> {
    const luontiTyyppi = juurilasku.avain === kasiteltava.avain ? LaskunToimintalokiTyyppi.LUOTU : LaskunToimintalokiTyyppi.MUOKATTU
    const parametrit: LaskunToimintalokiSahkoinenParametrit = {
      osoite: osoite
    }
    const merkinnat: LaskutoimintalokiMerkinta[] = [
      { toiminto: luontiTyyppi },
      { toiminto: LaskunToimintalokiTyyppi.LAHETETTY_SAHKOINEN, parametrit: parametrit }
    ]
    return this._merkitseLaskuLahetetyksiSahkoisestiLokimerkinnoilla(kayttajanTiedot, osoite, juurilasku, kasiteltava, reskontra, merkinnat, batchManipulator, tasapainoitaEnsinKayttaenViitenumeroa)
  }

  merkitseLaskuLahetetyksiSahkoisestiToisenHyvaksyntajonosta(kayttajanTiedot: KayttajanTiedot, osoite: LaskunSahkoinenOsoite, juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[], batchManipulator?: BatchManipulator, tasapainoitaEnsinKayttaenViitenumeroa?: string): Promise<void> {
    const parametrit: LaskunToimintalokiSahkoinenParametrit = {
      osoite: osoite
    }
    const merkinnat: LaskutoimintalokiMerkinta[] = [
      { toiminto: LaskunToimintalokiTyyppi.LAHETETTY_SAHKOISESTI_TOISEN_HYVAKSYNTAJONOSTA_HYVAKSYNNAN_JALKEEN, parametrit: parametrit }
    ]
    return this._merkitseLaskuLahetetyksiSahkoisestiLokimerkinnoilla(kayttajanTiedot, osoite, juurilasku, kasiteltava, reskontra, merkinnat, batchManipulator, tasapainoitaEnsinKayttaenViitenumeroa)
  }

  private _merkitseLaskuLahetetyksiSahkoisestiLokimerkinnoilla(kayttajanTiedot: KayttajanTiedot, osoite: LaskunSahkoinenOsoite, juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[], lokimerkinnat: LaskutoimintalokiMerkinta[], batchManipulator?: BatchManipulator, tasapainoitaEnsinKayttaenViitenumeroa?: string): Promise<void> {

    // Sanitization:
    kasiteltava.email = null
    kasiteltava.print = null

    // Poista spesiaalit nollarivit
    kasiteltava.tuotteet = kasiteltava.tuotteet.filter(tuote => {
      return (tuote.tuote.avain !== TuoteAvaimet.HUOMAUTUSKULU && tuote.tuote.avain !== TuoteAvaimet.KORKOKULU) || Math.floor(tuote.hinta * 100) !== 0
    })

    // Aseta avaimet
    juurilasku.avain = juurilasku.avain && juurilasku.avain !== UUDEN_LASKUN_AVAIN ? juurilasku.avain : this.firestoreProvider.annaUusiAvain()
    kasiteltava.avain = kasiteltava.avain && kasiteltava.avain !== UUDEN_LASKUN_AVAIN ? kasiteltava.avain : this.firestoreProvider.annaUusiAvain()

    const tyojonoId = this.firestoreProvider.annaUusiAvain()
    const laskuUri = this.laskuUriService.getLaskuUri(kayttajanTiedot.asiakasId, juurilasku.avain)
    const tyojonoUri = this.laskuUriService.getTyojonoLaskuSahkoinenlUri(kayttajanTiedot.asiakasId, tyojonoId)
    const batch = this.firestoreProvider.annaUusiBatch()

    // Päivitä summat
    this.shared.paivitaRyppaanJaKasiteltavanSummat(juurilasku, kasiteltava, reskontra)

    // Asetetaan käsiteltävä lukkoon, jotta sitä ei voi enää muokata
    kasiteltava.lukossa = true
    kasiteltava.sahkoinen = {
      done: null,
      start: this.timestampService.now(),
      osoite: osoite,
      status: LaskuSahkoinenLahetysStatusKoodi.PROSESSOIDAAN
    }

    // console.log(kasiteltava.print.start, typeof kasiteltava.print.start, kasiteltava.print.start.constructor.name)

    // Aseta tila
    this.shared.paivitaLaskunTila(juurilasku, reskontra)

    kasiteltava.asiakas.sahkoinenosoite = osoite

    // Lisää batchiin tuotteet ja asiakas
    this.paivitaTuotteetJaAsiakas(kayttajanTiedot.asiakasId, kasiteltava, LaskunLahetystapa.SAHKOINEN, batch)

    // Lisää lokimerkinnät
    for (const lokimerkinta of lokimerkinnat) {
      this.lisaaToimintalokiMerkinta(kayttajanTiedot, lokimerkinta.toiminto, juurilasku, kasiteltava, batch, lokimerkinta.parametrit)
    }
    this.lisaaHistoriaMerkinta(kayttajanTiedot, juurilasku, batch)

    this.tiedotaPerinnassaOlevanLaskunMuutoksestaTarvittaessa(kayttajanTiedot, juurilasku, kasiteltava, batch, this.firestoreProvider)

    // Tallenna lasku
    const copied = this.annaTallennettavaKopio(juurilasku, kasiteltava, reskontra)
    const laskuRef = this.firestoreProvider.annaDoc(laskuUri)
    batch.set(laskuRef, copied)


    // Tallenna tyojono
    if (tasapainoitaEnsinKayttaenViitenumeroa) {
      const tyojonomerkinta: LaskuTasapainoitaJaLahetaTyojonomerkinta = {
        kanava: LaskunLahetystapa.SAHKOINEN,
        viitenumero: tasapainoitaEnsinKayttaenViitenumeroa,
        kasiteltavaAvain: kasiteltava.avain,
        laskuAvain: juurilasku.avain,
        uudelleenyrityksia: 0,
        aloitettu: this.timestampService.now(),
        asiakasId: kayttajanTiedot.asiakasId,
        asiakasAvain: kayttajanTiedot.asiakasAvain
      }
      const tasapainoitusUri = this.laskuUriService.getTyojonoLaskuTasapainoitaJaLahetaUri(kayttajanTiedot.asiakasId, tyojonoId)
      const tyojoRef = this.firestoreProvider.annaDoc(tasapainoitusUri)
      batch.set(tyojoRef, tyojonomerkinta)
    } else {
      // Tallenna tyojono
      const tyojonomerkinta: LaskuSahkoinentyojononMerkinta = {
        laskuAvain: juurilasku.avain,
        kasiteltavaAvain: kasiteltava.avain,
        asiakasAvain: kayttajanTiedot.asiakasAvain,
        asiakasId: kayttajanTiedot.asiakasId,
        uudelleenyrityksia: 0,
        aloitettu: this.timestampService.now()
      }
      const tyojonomerkintaUri = this.firestoreProvider.annaDoc(tyojonoUri)
      batch.set(tyojonomerkintaUri, tyojonomerkinta)
    }

    // Tallenna Postgres työjono
    this.lisaaPostgresTyojonoBatchiin(juurilasku, kayttajanTiedot, batch)

    if (batchManipulator) {
      batchManipulator.manipulate(this.firestoreProvider, batch, juurilasku, kasiteltava)
    }

    // if (copied.print && copied.print.start) {
    //   console.log(copied.print.start, typeof copied.print.start, copied.print.start.constructor.name)
    // }

    return batch.commit()

  }

  lahetaLaskuUudelleen(kayttajanTiedot: KayttajanTiedot, juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[]): Promise<void> {

    const tyojonoId = this.firestoreProvider.annaUusiAvain()
    const laskuUri = this.laskuUriService.getLaskuUri(kayttajanTiedot.asiakasId, juurilasku.avain)
    const batch = this.firestoreProvider.annaUusiBatch()

    if (kasiteltava.sahkoinen) {

      // Asetetaan sähköiset lähetystiedot
      kasiteltava.sahkoinen.done = null
      kasiteltava.sahkoinen.status = LaskuSahkoinenLahetysStatusKoodi.PROSESSOIDAAN
      kasiteltava.sahkoinen.start = this.timestampService.now()

      // Lisää lokimerkinnät
      const parametrit: LaskunToimintalokiSahkoinenParametrit = {
        osoite: kasiteltava.sahkoinen.osoite
      }
      this.lisaaToimintalokiMerkinta(kayttajanTiedot, LaskunToimintalokiTyyppi.LAHETETTY_SAHKOINEN, juurilasku, kasiteltava, batch, parametrit)

      // Lisää työjonomerkinnät
      const tyojonomerkinta: LaskuSahkoinentyojononMerkinta = {
        laskuAvain: juurilasku.avain,
        kasiteltavaAvain: kasiteltava.avain,
        asiakasAvain: kayttajanTiedot.asiakasAvain,
        asiakasId: kayttajanTiedot.asiakasId,
        uudelleenyrityksia: 0,
        aloitettu: this.timestampService.now()
      }
      const tyojonoUri = this.laskuUriService.getTyojonoLaskuSahkoinenlUri(kayttajanTiedot.asiakasId, tyojonoId)
      const tyojonomerkintaUri = this.firestoreProvider.annaDoc(tyojonoUri)
      batch.set(tyojonomerkintaUri, tyojonomerkinta)

    } else if (kasiteltava.email) {

      // Asetetaan email tiedot:
      kasiteltava.email.start = this.timestampService.now()
      kasiteltava.email.done = null
      for (const vastaanottaja of kasiteltava.email.vastaanottajat) {
        vastaanottaja.status = EmailLahetysStatusKoodi.PROSESSOIDAAN
      }

      // Lisää lokimerkinnät
      const parametrit: LaskunToimintalokiSpostiParametrit = {
        emailit: kasiteltava.email.vastaanottajat.map(vastaanottaja => { return vastaanottaja.email })
      }
      this.lisaaToimintalokiMerkinta(kayttajanTiedot, LaskunToimintalokiTyyppi.LAHETETTY_SPOSTI, juurilasku, kasiteltava, batch, parametrit)

      // Lisää työjonomerkinnät
      const tyojonomerkinta: LaskuEmailtyojononMerkinta = {
        kasiteltavaAvain: kasiteltava.avain,
        laskuAvain: juurilasku.avain,
        asiakasAvain: kayttajanTiedot.asiakasAvain,
        asiakasId: kayttajanTiedot.asiakasId,
        uudelleenyrityksia: 0,
        aloitettu: this.timestampService.now()
      }
      const tyojonoUri = this.laskuUriService.getTyojonoLaskuEmailUri(kayttajanTiedot.asiakasId, tyojonoId)
      const tyojoRef = this.firestoreProvider.annaDoc(tyojonoUri)
      batch.set(tyojoRef, tyojonomerkinta)

    } else {
      return Promise.resolve()
    }

    // Tallenna Postgres työjono
    this.lisaaPostgresTyojonoBatchiin(juurilasku, kayttajanTiedot, batch)

    this.lisaaHistoriaMerkinta(kayttajanTiedot, juurilasku, batch)

    // Tallenna lasku
    const tallennettava = this.annaTallennettavaKopio(juurilasku, kasiteltava, reskontra)
    const laskuRef = this.firestoreProvider.annaDoc(laskuUri)
    batch.set(laskuRef, tallennettava)

    return batch.commit()

  }

  merkitseLaskunMaksumuistutusLahetetyksiSahkoisesti(kayttajanTiedot: KayttajanTiedot, osoite: LaskunSahkoinenOsoite, juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[], batchManipulator?: BatchManipulator): Promise<void> {

    // Sanitization:
    kasiteltava.email = null
    kasiteltava.print = null

    // Poista spesiaalit nollarivit
    kasiteltava.tuotteet = kasiteltava.tuotteet.filter(tuote => {
      return (tuote.tuote.avain !== TuoteAvaimet.HUOMAUTUSKULU && tuote.tuote.avain !== TuoteAvaimet.KORKOKULU) || Math.floor(tuote.hinta * 100) !== 0
    })

    // Aseta avaimet
    juurilasku.avain = juurilasku.avain && juurilasku.avain !== UUDEN_LASKUN_AVAIN ? juurilasku.avain : this.firestoreProvider.annaUusiAvain()
    kasiteltava.avain = kasiteltava.avain && kasiteltava.avain !== UUDEN_LASKUN_AVAIN ? kasiteltava.avain : this.firestoreProvider.annaUusiAvain()

    const tyojonoId = this.firestoreProvider.annaUusiAvain()
    const laskuUri = this.laskuUriService.getLaskuUri(kayttajanTiedot.asiakasId, juurilasku.avain)
    const tyojonoUri = this.laskuUriService.getTyojonoLaskuSahkoinenlUri(kayttajanTiedot.asiakasId, tyojonoId)
    const batch = this.firestoreProvider.annaUusiBatch()

    // Päivitä summat
    this.shared.paivitaRyppaanJaKasiteltavanSummat(juurilasku, kasiteltava, reskontra)

    // Asetetaan käsiteltävä lukkoon, jotta sitä ei voi enää muokata
    kasiteltava.lukossa = true
    kasiteltava.sahkoinen = {
      done: null,
      start: this.timestampService.now(),
      osoite: osoite,
      status: LaskuSahkoinenLahetysStatusKoodi.PROSESSOIDAAN
    }

    // console.log(kasiteltava.print.start, typeof kasiteltava.print.start, kasiteltava.print.start.constructor.name)

    // Aseta tila
    this.shared.paivitaLaskunTila(juurilasku, reskontra)

    kasiteltava.asiakas.sahkoinenosoite = osoite

    // Lisää batchiin tuotteet ja asiakas
    this.paivitaTuotteetJaAsiakas(kayttajanTiedot.asiakasId, kasiteltava, LaskunLahetystapa.SAHKOINEN, batch)

    // Lisää lokimerkinnät
    const parametrit: LaskunToimintalokiSahkoinenParametrit = {
      osoite: kasiteltava.sahkoinen.osoite
    }
    const luontiTyyppi = juurilasku.avain === kasiteltava.avain ? LaskunToimintalokiTyyppi.LUOTU : LaskunToimintalokiTyyppi.MUOKATTU
    this.lisaaToimintalokiMerkinta(kayttajanTiedot, luontiTyyppi, juurilasku, kasiteltava, batch)
    this.lisaaToimintalokiMerkinta(kayttajanTiedot, LaskunToimintalokiTyyppi.LAHETETTY_MAKSUMUISTUTUS_SAHKOINEN, juurilasku, kasiteltava, batch, parametrit)
    this.lisaaHistoriaMerkinta(kayttajanTiedot, juurilasku, batch)

    // Tallenna lasku
    const copied = this.annaTallennettavaKopio(juurilasku, kasiteltava, reskontra)
    const laskuRef = this.firestoreProvider.annaDoc(laskuUri)
    batch.set(laskuRef, copied)

    // Tallenna tyojono
    const tyojonomerkinta: LaskuSahkoinentyojononMerkinta = {
      laskuAvain: juurilasku.avain,
      kasiteltavaAvain: kasiteltava.avain,
      asiakasAvain: kayttajanTiedot.asiakasAvain,
      asiakasId: kayttajanTiedot.asiakasId,
      uudelleenyrityksia: 0,
      aloitettu: this.timestampService.now()
    }
    const tyojonomerkintaUri = this.firestoreProvider.annaDoc(tyojonoUri)
    batch.set(tyojonomerkintaUri, tyojonomerkinta)

    // Tallenna Postgres työjono
    this.lisaaPostgresTyojonoBatchiin(juurilasku, kayttajanTiedot, batch)

    // if (copied.print && copied.print.start) {
    //   console.log(copied.print.start, typeof copied.print.start, copied.print.start.constructor.name)
    // }

    if (batchManipulator) {
      batchManipulator.manipulate(this.firestoreProvider, batch, juurilasku, kasiteltava)
    }

    return batch.commit()

  }

  paivitaTuotteetJaAsiakas(asiakasId: string, kasiteltava: LaskuBase, lahetystapa: LaskunLahetystapa, batch: FirestoreWriteBatch) {
    // Päivitä asiakkaan tuotteet
    for (const laskunTuote of kasiteltava.tuotteet) {
      if (
        laskunTuote.tuote.avain !== TuoteAvaimet.HUOMAUTUSKULU &&
        laskunTuote.tuote.avain !== TuoteAvaimet.KORKOKULU
      ) {
        // Aseta hinta ja id
        laskunTuote.tuote.hinta = laskunTuote.hinta
        laskunTuote.tuote.avain = laskunTuote.tuote.avain ? laskunTuote.tuote.avain : this.firestoreProvider.annaUusiAvain()
        // Muunna asiakkaan tuotteeksi ja lisää batchiin
        const asiakkaanTuote = this.laskuKopioija.teeAsiakkaanTuoteLaskuntuotteesta(laskunTuote)
        if (!asiakkaanTuote.alv) {
          asiakkaanTuote.alv = {}
        }
        asiakkaanTuote.alv[kasiteltava.tyyppi] = laskunTuote.alv.tunniste
        this.lisaaTuoteBatchiin(asiakasId, asiakkaanTuote, batch)
      }
    }
    // Päivitä laskun asiakas
    kasiteltava.asiakas.avain = kasiteltava.asiakas.avain ? kasiteltava.asiakas.avain : this.firestoreProvider.annaUusiAvain()
    kasiteltava.asiakas.viimeisinLaskuLahetetty = lahetystapa
    const asiakas = this.laskuKopioija.copyAsiakas(kasiteltava.asiakas)
    asiakas.laskunKieli = kasiteltava.kieli
    asiakas.laskunTyyppi = kasiteltava.tyyppi
    asiakas.laskunValuutta = kasiteltava.valuutta
    this.lisaaAsiakasBatchiin(asiakasId, asiakas, batch)
  }

  merkitseLaskunMaksumuistutusLahetetyksiTulostamalla(kayttajanTiedot: KayttajanTiedot, juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[], batchManipulator?: BatchManipulator): Promise<void> {

    // Sanitization:
    kasiteltava.email = null
    kasiteltava.sahkoinen = null

    // Poista spesiaalit nollarivit
    kasiteltava.tuotteet = kasiteltava.tuotteet.filter(tuote => {
      return (tuote.tuote.avain !== TuoteAvaimet.HUOMAUTUSKULU && tuote.tuote.avain !== TuoteAvaimet.KORKOKULU) || Math.floor(tuote.hinta * 100) !== 0
    })

    // Aseta avaimet
    juurilasku.avain = juurilasku.avain && juurilasku.avain !== UUDEN_LASKUN_AVAIN ? juurilasku.avain : this.firestoreProvider.annaUusiAvain()
    kasiteltava.avain = kasiteltava.avain && kasiteltava.avain !== UUDEN_LASKUN_AVAIN ? kasiteltava.avain : this.firestoreProvider.annaUusiAvain()

    // Luo urit
    const tyojonoId = this.firestoreProvider.annaUusiAvain()
    const laskuUri = this.laskuUriService.getLaskuUri(kayttajanTiedot.asiakasId, juurilasku.avain)
    const tyojonoUri = this.laskuUriService.getTyojonoLaskuTulostusUri(kayttajanTiedot.asiakasId, tyojonoId)
    const batch = this.firestoreProvider.annaUusiBatch()

    // Päivitä summat
    this.shared.paivitaRyppaanJaKasiteltavanSummat(juurilasku, kasiteltava, reskontra)

    // Asetetaan käsiteltävä lukkoon, jotta sitä ei voi enää muokata
    kasiteltava.lukossa = true
    kasiteltava.print = {
      done: null,
      start: this.timestampService.now()
    }

    // Aseta tila
    this.shared.paivitaLaskunTila(juurilasku, reskontra)

    // Lisää batchiin tuotteet ja asiakas
    this.paivitaTuotteetJaAsiakas(kayttajanTiedot.asiakasId, kasiteltava, LaskunLahetystapa.ITSE, batch)

    // Lisää lokimerkinnät
    const luontiTyyppi = juurilasku.avain === kasiteltava.avain ? LaskunToimintalokiTyyppi.LUOTU : LaskunToimintalokiTyyppi.MUOKATTU
    this.lisaaToimintalokiMerkinta(kayttajanTiedot, luontiTyyppi, juurilasku, kasiteltava, batch)
    this.lisaaToimintalokiMerkinta(kayttajanTiedot, LaskunToimintalokiTyyppi.LAHETETTY_MAKSUMUISTUTUS_TULOSTA, juurilasku, kasiteltava, batch)
    this.lisaaHistoriaMerkinta(kayttajanTiedot, juurilasku, batch)

    // Tallenna lasku
    const copied = this.annaTallennettavaKopio(juurilasku, kasiteltava, reskontra)
    const laskuRef = this.firestoreProvider.annaDoc(laskuUri)
    batch.set(laskuRef, copied)

    // Tallenna tyojono
    const tyojonomerkinta: LaskuTulostamistyojononMerkinta = {
      laskuAvain: juurilasku.avain,
      kasiteltavaAvain: kasiteltava.avain,
      asiakasAvain: kayttajanTiedot.asiakasAvain,
      asiakasId: kayttajanTiedot.asiakasId,
      uudelleenyrityksia: 0,
      aloitettu: this.timestampService.now()
    }
    const tyojoRef = this.firestoreProvider.annaDoc(tyojonoUri)
    batch.set(tyojoRef, tyojonomerkinta)

    // Tallenna Postgres työjono
    this.lisaaPostgresTyojonoBatchiin(juurilasku, kayttajanTiedot, batch)

    if (batchManipulator) {
      batchManipulator.manipulate(this.firestoreProvider, batch, juurilasku, kasiteltava)
    }

    return batch.commit()
  }

  merkitseLaskunMaksumuistutusLahetetyksiSpostilla(
    kayttajanTiedot: KayttajanTiedot,
    templateId: number,
    juurilasku: Lasku,
    kasiteltava: LaskuBase,
    reskontra: LaskuReskontra[],
    vastaanottajat: EmailLahetysStatus[],
    aihe: string,
    otsikko: string,
    teksti: string,
    slogan: string,
    paivitaLahettajatAsiakkaaseen: boolean,
    batchManipulator?: BatchManipulator
  ): Promise<void> {

    // Sanitization:
    kasiteltava.print = null
    kasiteltava.sahkoinen = null

    // Poista spesiaalit nollarivit
    kasiteltava.tuotteet = kasiteltava.tuotteet.filter(tuote => {
      return (tuote.tuote.avain !== TuoteAvaimet.HUOMAUTUSKULU && tuote.tuote.avain !== TuoteAvaimet.KORKOKULU) || Math.floor(tuote.hinta * 100) !== 0
    })

    // Aseta avaimet
    juurilasku.avain = juurilasku.avain && juurilasku.avain !== UUDEN_LASKUN_AVAIN ? juurilasku.avain : this.firestoreProvider.annaUusiAvain()
    kasiteltava.avain = kasiteltava.avain && kasiteltava.avain !== UUDEN_LASKUN_AVAIN ? kasiteltava.avain : this.firestoreProvider.annaUusiAvain()

    // Luo urit
    const tyojonoId = this.firestoreProvider.annaUusiAvain()
    const tyojonoUri = this.laskuUriService.getTyojonoLaskuEmailUri(kayttajanTiedot.asiakasId, tyojonoId)
    const laskuUri = this.laskuUriService.getLaskuUri(kayttajanTiedot.asiakasId, juurilasku.avain)
    const batch = this.firestoreProvider.annaUusiBatch()

    // Päivitä summat
    this.shared.paivitaRyppaanJaKasiteltavanSummat(juurilasku, kasiteltava, reskontra)

    // Asetetaan käsiteltävä lukkoon, jotta sitä ei voi enää muokata
    kasiteltava.lukossa = true

    // Asetetaan email tiedot:
    kasiteltava.email = {
      start: this.timestampService.now(),
      done: null,
      aihe: aihe,
      otsikko: otsikko,
      teksti: teksti,
      slogan: slogan,
      template: templateId,
      vastaanottajat: vastaanottajat
    }

    // Aseta tila
    this.shared.paivitaLaskunTila(juurilasku, reskontra)

    // Lisää batchiin tuotteet ja asiakas
    if (paivitaLahettajatAsiakkaaseen) {
      kasiteltava.asiakas.laskunVastaanottajat = vastaanottajat.map(vastaanottaja => { return vastaanottaja.email })
    }
    this.paivitaTuotteetJaAsiakas(kayttajanTiedot.asiakasId, kasiteltava, LaskunLahetystapa.SAHKOPOSTI, batch)

    // Lisää lokimerkinnät
    const luontiTyyppi = juurilasku.avain === kasiteltava.avain ? LaskunToimintalokiTyyppi.LUOTU : LaskunToimintalokiTyyppi.MUOKATTU
    this.lisaaToimintalokiMerkinta(kayttajanTiedot, luontiTyyppi, juurilasku, kasiteltava, batch)
    const parametrit: LaskunToimintalokiSpostiParametrit = {
      emailit: vastaanottajat.map(vastaanottaja => { return vastaanottaja.email })
    }
    this.lisaaToimintalokiMerkinta(kayttajanTiedot, LaskunToimintalokiTyyppi.LAHETETTY_MAKSUMUISTUTUS_SPOSTI, juurilasku, kasiteltava, batch, parametrit)
    this.lisaaHistoriaMerkinta(kayttajanTiedot, juurilasku, batch)

    // Tallenna lasku
    const copied = this.annaTallennettavaKopio(juurilasku, kasiteltava, reskontra)
    const laskuRef = this.firestoreProvider.annaDoc(laskuUri)
    batch.set(laskuRef, copied)

    // Tallenna tyojono
    const tyojonomerkinta: LaskuEmailtyojononMerkinta = {
      kasiteltavaAvain: kasiteltava.avain,
      laskuAvain: juurilasku.avain,
      asiakasAvain: kayttajanTiedot.asiakasAvain,
      asiakasId: kayttajanTiedot.asiakasId,
      uudelleenyrityksia: 0,
      aloitettu: this.timestampService.now()
    }
    const tyojoRef = this.firestoreProvider.annaDoc(tyojonoUri)
    batch.set(tyojoRef, tyojonomerkinta)

    // Tallenna Postgres työjono
    this.lisaaPostgresTyojonoBatchiin(juurilasku, kayttajanTiedot, batch)

    if (batchManipulator) {
      batchManipulator.manipulate(this.firestoreProvider, batch, juurilasku, kasiteltava)
    }

    return batch.commit()
  }

  merkitseLaskuLahetetyksiSpostilla(
    kayttajanTiedot: KayttajanTiedot,
    templateId: number,
    juurilasku: Lasku,
    kasiteltava: LaskuBase,
    reskontra: LaskuReskontra[],
    vastaanottajat: EmailLahetysStatus[],
    aihe: string,
    otsikko: string,
    teksti: string,
    slogan: string,
    tasapainoitaEnsinKayttaenViitenumeroa: string,
    manipulator?: BatchManipulator,
    varit?: { [key in LaskunSahkpostipohjanVari]: string },
    footer?: 'footer1' | 'footer2',
    logoInFooter?: boolean,
    lisahuomautus?: string
  ): Promise<void> {

    // Sanitization:
    kasiteltava.print = null
    kasiteltava.sahkoinen = null

    // Poista spesiaalit nollarivit
    kasiteltava.tuotteet = kasiteltava.tuotteet.filter(tuote => {
      return (tuote.tuote.avain !== TuoteAvaimet.HUOMAUTUSKULU && tuote.tuote.avain !== TuoteAvaimet.KORKOKULU) || Math.floor(tuote.hinta * 100) !== 0
    })

    // Aseta avaimet
    juurilasku.avain = juurilasku.avain && juurilasku.avain !== UUDEN_LASKUN_AVAIN ? juurilasku.avain : this.firestoreProvider.annaUusiAvain()
    kasiteltava.avain = kasiteltava.avain && kasiteltava.avain !== UUDEN_LASKUN_AVAIN ? kasiteltava.avain : this.firestoreProvider.annaUusiAvain()

    // Luo urit
    const tyojonoId = this.firestoreProvider.annaUusiAvain()
    const laskuUri = this.laskuUriService.getLaskuUri(kayttajanTiedot.asiakasId, juurilasku.avain)
    const batch = this.firestoreProvider.annaUusiBatch()

    // Päivitä summat
    this.shared.paivitaRyppaanJaKasiteltavanSummat(juurilasku, kasiteltava, reskontra)

    // Asetetaan käsiteltävä lukkoon, jotta sitä ei voi enää muokata
    kasiteltava.lukossa = true

    // Asetetaan email tiedot:
    kasiteltava.email = {
      start: this.timestampService.now(),
      done: null,
      aihe: aihe,
      otsikko: otsikko,
      teksti: teksti,
      slogan: slogan,
      template: templateId,
      vastaanottajat: vastaanottajat,
      vari: varit,
      footer: footer,
      logoInFooter: logoInFooter,
      lisahuomautus: lisahuomautus
    }

    // Aseta tila
    this.shared.paivitaLaskunTila(juurilasku, reskontra)

    // Lisää batchiin tuotteet ja asiakas
    kasiteltava.asiakas.laskunVastaanottajat = vastaanottajat.map(vastaanottaja => { return vastaanottaja.email })
    this.paivitaTuotteetJaAsiakas(kayttajanTiedot.asiakasId, kasiteltava, LaskunLahetystapa.SAHKOPOSTI, batch)

    // Lisää lokimerkinnät
    const luontiTyyppi = juurilasku.avain === kasiteltava.avain ? LaskunToimintalokiTyyppi.LUOTU : LaskunToimintalokiTyyppi.MUOKATTU
    this.lisaaToimintalokiMerkinta(kayttajanTiedot, luontiTyyppi, juurilasku, kasiteltava, batch)
    const parametrit: LaskunToimintalokiSpostiParametrit = {
      emailit: vastaanottajat.map(vastaanottaja => { return vastaanottaja.email })
    }
    this.lisaaToimintalokiMerkinta(kayttajanTiedot, LaskunToimintalokiTyyppi.LAHETETTY_SPOSTI, juurilasku, kasiteltava, batch, parametrit)
    this.lisaaHistoriaMerkinta(kayttajanTiedot, juurilasku, batch)

    this.tiedotaPerinnassaOlevanLaskunMuutoksestaTarvittaessa(kayttajanTiedot, juurilasku, kasiteltava, batch, this.firestoreProvider)

    // Tallenna lasku
    const copied = this.annaTallennettavaKopio(juurilasku, kasiteltava, reskontra)
    const laskuRef = this.firestoreProvider.annaDoc(laskuUri)
    batch.set(laskuRef, copied)

    // Tallenna tyojono
    if (tasapainoitaEnsinKayttaenViitenumeroa) {
      const tyojonomerkinta: LaskuTasapainoitaJaLahetaTyojonomerkinta = {
        kanava: LaskunLahetystapa.SAHKOPOSTI,
        viitenumero: tasapainoitaEnsinKayttaenViitenumeroa,
        kasiteltavaAvain: kasiteltava.avain,
        laskuAvain: juurilasku.avain,
        uudelleenyrityksia: 0,
        aloitettu: this.timestampService.now(),
        asiakasId: kayttajanTiedot.asiakasId,
        asiakasAvain: kayttajanTiedot.asiakasAvain
      }
      const tyojonoUri = this.laskuUriService.getTyojonoLaskuTasapainoitaJaLahetaUri(kayttajanTiedot.asiakasId, tyojonoId)
      const tyojoRef = this.firestoreProvider.annaDoc(tyojonoUri)
      batch.set(tyojoRef, tyojonomerkinta)
    } else {
      const tyojonomerkinta: LaskuEmailtyojononMerkinta = {
        kasiteltavaAvain: kasiteltava.avain,
        laskuAvain: juurilasku.avain,
        asiakasAvain: kayttajanTiedot.asiakasAvain,
        asiakasId: kayttajanTiedot.asiakasId,
        uudelleenyrityksia: 0,
        aloitettu: this.timestampService.now()
      }
      const tyojonoUri = this.laskuUriService.getTyojonoLaskuEmailUri(kayttajanTiedot.asiakasId, tyojonoId)
      const tyojoRef = this.firestoreProvider.annaDoc(tyojonoUri)
      batch.set(tyojoRef, tyojonomerkinta)
    }

    // Tallenna Postgres työjono
    this.lisaaPostgresTyojonoBatchiin(juurilasku, kayttajanTiedot, batch)

    if (manipulator) {
      manipulator.manipulate(this.firestoreProvider, batch, juurilasku, kasiteltava)
    }

    return batch.commit()

  }

  merkitseLaskunPerintaLahetetyksiSpostilla(
    kayttajanTiedot: KayttajanTiedot,
    reskontra: LaskuReskontra[],
    juurilasku: Lasku,
    kasiteltava: LaskuBase
  ): Promise<void> {

    // Luo urit
    const tyojonoId = this.firestoreProvider.annaUusiAvain()
    const tyojonoUri = this.laskuUriService.getTyojonoPerintaLaskuEmailUri(kayttajanTiedot.asiakasId, tyojonoId)
    const laskuUri = this.laskuUriService.getLaskuUri(kayttajanTiedot.asiakasId, juurilasku.avain)
    const batch = this.firestoreProvider.annaUusiBatch()

    // Merkitse siirretyksi perintään
    juurilasku.perintatiedot = {
      lahetettyPeruutusFrontend: null,
      lahetettyPeruutus: null,
      lahetettyFrontend: this.timestampService.now(),
      lahetetty: null,
      lahetykset: null,
      vastaanottaja: null, // Ei vielä lähetetty oikeasti.
      status: 'ok',
      success: 'Save on frontend'
    }

    // Tallenna lasku
    const copied = this.annaTallennettavaKopio(juurilasku, kasiteltava, reskontra)
    const laskuRef = this.firestoreProvider.annaDoc(laskuUri)
    batch.set(laskuRef, copied)

    this.lisaaToimintalokiMerkinta(kayttajanTiedot, LaskunToimintalokiTyyppi.PERINTA_LAHETETTY, juurilasku, juurilasku, batch)
    this.lisaaHistoriaMerkinta(kayttajanTiedot, juurilasku, batch)

    // Tallenna tyojono
    const tyojonomerkinta: LaskuPerintaEmailtyojononMerkinta = {
      kasiteltavaAvain: juurilasku.avain,
      laskuAvain: juurilasku.avain,
      asiakasAvain: kayttajanTiedot.asiakasAvain,
      asiakasId: kayttajanTiedot.asiakasId,
      kayttajaAvain: kayttajanTiedot.uid,
      uudelleenyrityksia: 0,
      aloitettu: this.timestampService.now()
    }

    // Tallenna Postgres työjono
    this.lisaaPostgresTyojonoBatchiin(juurilasku, kayttajanTiedot, batch)

    const tyojoRef = this.firestoreProvider.annaDoc(tyojonoUri)
    batch.set(tyojoRef, tyojonomerkinta)

    return batch.commit()
  }

  merkitseLaskunPerintaPerutuksiSpostilla(
    kayttajanTiedot: KayttajanTiedot,
    reskontra: LaskuReskontra[],
    juurilasku: Lasku
  ): Promise<void> {

    // Luo urit
    const tyojonoId = this.firestoreProvider.annaUusiAvain()
    const tyojonoUri = this.laskuUriService.getTyojonoPerintaLaskunPeruutusEmailUri(kayttajanTiedot.asiakasId, tyojonoId)
    const laskuUri = this.laskuUriService.getLaskuUri(kayttajanTiedot.asiakasId, juurilasku.avain)
    const batch = this.firestoreProvider.annaUusiBatch()

    juurilasku.perintatiedot.lahetettyPeruutusFrontend = this.timestampService.now()

    // Tallenna lasku
    const copied = this.annaTallennettavaKopio(juurilasku, juurilasku, reskontra)
    const laskuRef = this.firestoreProvider.annaDoc(laskuUri)
    batch.set(laskuRef, copied)

    this.lisaaToimintalokiMerkinta(kayttajanTiedot, LaskunToimintalokiTyyppi.POISTETTU_PERINNASTA, juurilasku, juurilasku, batch)
    this.lisaaHistoriaMerkinta(kayttajanTiedot, juurilasku, batch)

    // Tallenna tyojono
    const tyojonomerkinta: LaskuPerintaEmailtyojononMerkinta = {
      kasiteltavaAvain: juurilasku.avain,
      laskuAvain: juurilasku.avain,
      asiakasAvain: kayttajanTiedot.asiakasAvain,
      asiakasId: kayttajanTiedot.asiakasId,
      kayttajaAvain: kayttajanTiedot.uid,
      uudelleenyrityksia: 0,
      aloitettu: this.timestampService.now()
    }

    // Tallenna Postgres työjono
    this.lisaaPostgresTyojonoBatchiin(juurilasku, kayttajanTiedot, batch)

    const tyojoRef = this.firestoreProvider.annaDoc(tyojonoUri)
    batch.set(tyojoRef, tyojonomerkinta)

    return batch.commit()

  }

  tiedotaPerinnassaOlevanLaskunMuutoksestaTarvittaessa(
    kayttajanTiedot: KayttajanTiedot,
    juurilasku: Lasku,
    kasiteltava: LaskuBase,
    batch: FirestoreWriteBatch,
    firestoreProvider: FirestoreProvider
  ): void {

    if (juurilasku?.perintatiedot && juurilasku.perintatiedot.lahetettyFrontend && kasiteltava && !juurilasku.perintatiedot.lahetykset[kasiteltava.avain] && !juurilasku.perintatiedot.lahetettyPeruutusFrontend) {

      juurilasku.perintatiedot.lahetykset[kasiteltava.avain] = {
        kasiteltavaAvain: kasiteltava.avain,
        lahetettyFrontend: this.timestampService.now(),
        lahetetty: null
      }

      // Luo urit
      const tyojonoId = this.firestoreProvider.annaUusiAvain()
      const tyojonoUri = this.laskuUriService.getTyojonoTiedotaPerinnassaOlevanLaskunMuutoksestaTarvittaessaUri(kayttajanTiedot.asiakasId, tyojonoId)

      this.lisaaToimintalokiMerkinta(kayttajanTiedot, LaskunToimintalokiTyyppi.PERINTA_LAHETETTY_LISATIEDOT_HYVITYS, juurilasku, kasiteltava, batch)
      this.lisaaHistoriaMerkinta(kayttajanTiedot, juurilasku, batch)

      // Tallenna tyojono
      const tyojonomerkinta: LaskuPerintaEmailtyojononMerkinta = {
        kasiteltavaAvain: kasiteltava.avain,
        laskuAvain: juurilasku.avain,
        asiakasAvain: kayttajanTiedot.asiakasAvain,
        asiakasId: kayttajanTiedot.asiakasId,
        kayttajaAvain: kayttajanTiedot.uid,
        uudelleenyrityksia: 0,
        aloitettu: this.timestampService.now()
      }

      const tyojoRef = firestoreProvider.annaDoc(tyojonoUri)
      batch.set(tyojoRef, tyojonomerkinta)

    }
  }

  annaTallennettavaKopio(juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[]): Lasku {

    // Tämä normalisoi viitenumeron hakuja varten
    if (juurilasku.viitenumero) {
      const muotoiltu = this.viitenumeroService.muotoileViitenumero(juurilasku.viitenumero) // Poistaa etunollat jne.
      const whitespacePoistettu = this.stringService.removeAllWhiteSpaces(muotoiltu)
      if (whitespacePoistettu) {
        juurilasku.viitenumero = whitespacePoistettu
      } else {
        throw new Error('Viitenumero ' + juurilasku.viitenumero + ' katosi!')
      }
    }

    const copied = this.laskuKopioija.copyLasku(juurilasku)
    copied['haku'] = this.laskuIndeksoija.luoHakutiedotLaskulle(juurilasku, kasiteltava, reskontra)

    if (!copied.lahetystyyppi) {
      copied.lahetystyyppi = LaskunLahetystyyppi.KERTA
    }
    return copied

  }
  lisaaPostgresTyojonoBatchiin(lasku: Lasku, kayttajanTiedot: KayttajanTiedot, batch: FirestoreWriteBatch): void {
    const tyojonoData: LaskuPostgresTyojonoData = {
      laskuAvain: lasku.avain,
      asiakasId: kayttajanTiedot.asiakasId,
      uudelleenyrityksia: 0,
      aloitettu: this.timestampService.now()
    }
    const tyojonoId = this.firestoreProvider.annaUusiAvain()
    const bqTyojonoDoc = this.firestoreProvider.annaDoc(this.laskuUriService.getTyojonoLaskuPostgresUri(kayttajanTiedot.asiakasAvain, tyojonoId))
    batch.set(bqTyojonoDoc, tyojonoData)
  }

  lisaaLemonatoriinVientiTyojonoBatchiin(juurilasku: Lasku, kasiteltava: LaskuBase, kayttajanTiedot: KayttajanTiedot, batch: FirestoreWriteBatch): void {
    const uudenMerkinnanTiedot: LaskuVientiLemonatoriintyojononMerkinta = {
      aloitettu: this.timestampService.now(),
      lahetaSlack: false,
      kasiteltavaAvain: kasiteltava.avain,
      laskuAvain: juurilasku.avain,
      asiakasAvain: kayttajanTiedot.asiakasAvain,
      asiakasId: kayttajanTiedot.asiakasId,
      uudelleenyrityksia: 0
    }
    const lemonatoriinAvain = this.firestoreProvider.annaUusiAvain()
    const lemonatoriinUri = this.laskuUriService.getTyojonoLaskuVientiLemonatoriinUri(kayttajanTiedot.asiakasId, lemonatoriinAvain)
    const lemonatoriinDoc = this.firestoreProvider.annaDoc(lemonatoriinUri)
    batch.set(lemonatoriinDoc, uudenMerkinnanTiedot)
  }

}
