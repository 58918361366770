import { LocalMonth, LocalDate, NumberDate } from '../../_shared-core/model/common'
import { Tyossaolojakso, TuntilistaPaivaRivi, KuukaudenTyoajanseuranta } from '../../_jaettu-lemonator/model/tyoajanseuranta'
import { DateService } from '../../_shared-core/service/date.service'
import { PyhapaivatService } from '../../_shared-core/service/pyhapaiva.service'
import { Kirjanpitaja, KirjanpitajaWorkTimeChange } from '../model/kirjanpitaja'
import { CurrencyService } from '../../_shared-core/service/currency.service'

export class TyoajanseurantaService {

  // eslint-disable-next-line @typescript-eslint/naming-convention
  STANDARD_WORK_DAY_LENGTH = 8

  constructor(
    private _dateService: DateService,
    private _pyhapaivatService: PyhapaivatService,
    private _currencyService: CurrencyService
  ) { }

  /**
   * @param selectedDate Set point in time for which the work time settings are found. Defaults to current date if not filled.
   * @returns The latest active work time change. Null if none found.
   */
  getActiveWorkTimeSettings(kirjanpitaja: Kirjanpitaja, selectedDate?: NumberDate): KirjanpitajaWorkTimeChange {
    if (!kirjanpitaja) {
      return null
    }

    const activeDate = selectedDate ?? this._dateService.currentNumberDate()

    /** Default to 100% work time if no active settings found. */
    let currentlyActiveSetting: KirjanpitajaWorkTimeChange = {
      validFrom: 1001,
      percentage: 100,
      changedAt: null,
      changedBy: null
    }

    for (const workTimeChange of kirjanpitaja?.workTimeSettings || []) {

      if ((!currentlyActiveSetting || workTimeChange.validFrom >= currentlyActiveSetting.validFrom) &&
        workTimeChange.validFrom <= activeDate) {

        currentlyActiveSetting = workTimeChange
      }
    }

    return currentlyActiveSetting
  }

  getWorkDayLengthOfAccountantOnThatDay(kirjanpitaja: Kirjanpitaja, selectedDate: NumberDate): number {
    const activeWorkTimeSettings = this.getActiveWorkTimeSettings(kirjanpitaja, selectedDate)

    return this._currencyService.roundHalfUp(this.STANDARD_WORK_DAY_LENGTH * (activeWorkTimeSettings.percentage / 100), 2)
  }



  annaKuukaudenTyoajanseurantaCollection(kirjanpitajanAvain: string) {
    return 'kirjanpitajat/' + kirjanpitajanAvain + '/kirjanpitajan-tuntilistat/'
  }
  /**
  * Month number must be in format yymm
  * @param kuukausi
  */
  annaKuukaudenTyoajanseurantaUri(kirjanpitajanAvain: string, kuukausi: number) {
    return this.annaKuukaudenTyoajanseurantaCollection(kirjanpitajanAvain) + kuukausi
  }
  annaKirjaamattomiaTuntejaIndicatorUri(kirjanpitajanAvain: string) {
    return 'kirjanpitajat/' + kirjanpitajanAvain + '/kirjaamattomia-tunteja/' + kirjanpitajanAvain
  }

  getWorkHourRecalculationWorkQueue(kirjanpitajaAvain: string, tyojonoAvain: string) {
    return 'tyojono/' + kirjanpitajaAvain + '/recalc-work-hours/' + tyojonoAvain
  }


  calculateTyotunnit(jaksot: Tyossaolojakso[]) {
    let tyotunnit = 0
    for (const jakso of jaksot) {
      const minuuttejaValissa = this._dateService.minuuttejaValissaPaikallinen(jakso.alkaa, jakso.loppuu)
      tyotunnit += minuuttejaValissa / 60
    }
    return tyotunnit
  }
  onkoPyhaTaiViikonloppu(pvm: LocalDate) {
    const paivanNimi = this._pyhapaivatService.onkoPyhapaiva(pvm) ? 'Pyhä' : this._dateService.annaPaivanNimiPaikallinen(pvm, 'fi')
    return ['Sunnuntai', 'Lauantai', 'Pyhä'].includes(paivanNimi)
  }

  luoTyhjaKuukausiData(kuukausi: LocalMonth, kirjanpitajanAvain: string, edellisenKuukaudenSeuranta: KuukaudenTyoajanseuranta): KuukaudenTyoajanseuranta {
    const paivaRivit: TuntilistaPaivaRivi[] = []
    const paiviaKuukaudessa = this._dateService.annaPaivienMaaraKuukaudessa(kuukausi)
    for (let i = 1; i <= paiviaKuukaudessa; i++) {
      const paiva: LocalDate = { year: kuukausi.year, month: kuukausi.month, day: i }
      const tyhjaRivi: TuntilistaPaivaRivi = {
        pvm: paiva,
        paivanNimi: this._dateService.annaPaivanNimiPaikallinen(paiva, 'fi'),
        tyossaolojaksot: [],
        poissaolo: null,
        lisatiedot: null
      }
      paivaRivit.push(tyhjaRivi)
    }
    const tyhjaKuukausi: KuukaudenTyoajanseuranta = {
      kirjanpitajaAvain: kirjanpitajanAvain,
      kuukausi: this._dateService.localMonthToNumber(kuukausi),
      paivaRivit: paivaRivit,
      kuukaudenLiukuma: 0,
      jaljellaOleviaLomapaivia: edellisenKuukaudenSeuranta?.jaljellaOleviaLomapaivia || 0,
      sairausloma: 0,
      kuukaudessaKaytetytLomapaivat: 0,
      kumulatiivinenLiukuma: edellisenKuukaudenSeuranta?.kumulatiivinenLiukuma || 0,
    }
    return tyhjaKuukausi
  }
  getLomaPeriodStart(currentMonth: LocalMonth) {
    if (currentMonth.month < 4) {
      const prevYear = this._dateService.lisaaKuukausiaLocalMonth(currentMonth, -12)
      return {
        year: prevYear.year,
        month: 4
      } as LocalMonth
    }
    return {
      year: currentMonth.year,
      month: 4
    }
  }
  getLomaPeriodEnd(currentMonth: LocalMonth) {
    if (currentMonth.month < 4) {
      return {
        year: currentMonth.year,
        month: 3
      } as LocalMonth
    }
    const nextYear = this._dateService.lisaaKuukausiaLocalMonth(currentMonth, 12)
    return {
      year: nextYear.year,
      month: 3
    }
  }

}
