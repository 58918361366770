import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core'

import { Asiakas } from '../../_jaettu-lemonator/model/asiakas'

import { AsiakasService } from '../../_angular/service/asiakas/asiakas.service'
import { DateService } from '../../_shared-core/service/date.service'


import { Subject, Observable, of as observableof, BehaviorSubject, combineLatest } from 'rxjs'
import { takeUntil, switchMap, map } from 'rxjs/operators'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'
import { BankConsentLog, BankCustomerConsent } from 'app/_jaettu/model/banks'
import { BankConsentUriService } from 'app/_jaettu/service/bank-consent-uri.service'
import { DebugService } from 'app/_angular/service/debug.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'

@Component({
  templateUrl: './bank-connections.component.html',
  styleUrls: ['./bank-connections.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BankConnectionsComponent implements OnInit, OnDestroy {

  asiakas: Asiakas = null
  namename = 'asdf ' + Math.random()

  consentHtmlObservable: Observable<string>
  userLevelConsentsObservable: Observable<string>
  logsObservable: Observable<BankConsentLog[]>

  kirjanpitajaOnDevaajaObservable: Observable<boolean>
  consentUriObservable: Observable<string>
  consentUrlObservable: Observable<string>

  private _historianMaaraSubject: BehaviorSubject<number> = new BehaviorSubject(50)
  historianMaaraObservable = this._historianMaaraSubject.asObservable()

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    private _asiakasService: AsiakasService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _dateService: DateService,
    private _bankConsentUriService: BankConsentUriService,
    private _debugService: DebugService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService
  ) {

  }

  ngOnInit() {

    // Debug thingies
    this.kirjanpitajaOnDevaajaObservable = this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable
    this.consentUriObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      map(asiakas => {
        if (asiakas?.avain) {
          return this._bankConsentUriService.getBankConsentUri(asiakas.avain)
        }
        return ''
      })
    )
    this.consentUrlObservable = this.consentUriObservable.pipe(
      map(uri => {
        return this._debugService.createFirestoreLinkLemonaid(uri)
      })
    )

    this._asiakasService.nykyinenAsiakasObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(result => {
      this.asiakas = result
    })

    const bankCustomerConsentObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas?.avain) {
          return observableof<BankCustomerConsent>(null)
        }
        const uri = this._bankConsentUriService.getBankConsentUri(asiakas.avain)
        return this._firebaseLemonaid.firestoreDoc<BankCustomerConsent>(uri).listen()
      })
    )

    this.consentHtmlObservable = bankCustomerConsentObservable.pipe(
      map(consent => {
        if (!consent) {
          return '<p>Käyttäjillä ei ole pankkiyhteysroolia</p>'
        }
        if (!consent.dialogsToShow?.length &&
          !consent.forceDialogToShow?.length) {
          return '<p>Kaikki maksutavoissa olevat sähköiset tiliotteet toimivat.</p>'
        }
        let output = '<p>Pankkiyhteysikkuna on päällä:<br/><br/>'
        if (consent.forceDialogToShow?.length) {
          for (const d of consent.forceDialogToShow) {
            output += d + ' (pakotetusti päällä)'
          }
        }
        if (consent.dialogsToShow.length) {
          for (const d of consent.dialogsToShow) {
            output += // '<p>' + d.dialogToShow?.toUpperCase() + ' ' +
              'Pankin tunnus ' + (d.providerId ?? 'Tuntematon') + ', vanhentunut ' + this._dateService.muotoile(this._dateService.numberTimestampToDate(d.connectionLost), 'dd.MM.yyyy HH:mm:ss')
              + '<br/>' +
              (d.ibansMissing ? d.ibansMissing?.join(', ') : '')
              + '</p>'
          }
        }
        return output
      })
    )

    this.logsObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this.historianMaaraObservable]).pipe(
      switchMap(([asiakas, historianMaara]) => {
        if (!asiakas) {
          return observableof<BankConsentLog[]>([])
        }
        const uri = this._bankConsentUriService.getBankConsentLogsCollection(asiakas.avain)
        return this._firebaseLemonaid.firestoreCollection<BankConsentLog>(uri).orderBy('changedTime', 'desc').limit(Number(historianMaara)).listen()
      })
    )

  }

  historianMaaraChanged(limit: string) {
    this._historianMaaraSubject.next(+limit)
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
