import { Component, OnInit, Inject } from '@angular/core'
import { Validators, FormGroup, FormControl } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { NaytettavaKirjaus } from '../kirjanpito.component'

import { DateService } from 'app/_shared-core/service/date.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { Asiakas } from 'app/_jaettu-lemonator/model/asiakas'
import { Kirjanpitotili, Kirjausrivi } from 'app/_jaettu-lemonator/model/kirjanpito'
import { AsiakasJaettuService } from 'app/_jaettu-lemonator/service/asiakas-jaettu.service'
import { map, Observable, startWith } from 'rxjs'
import { CurrencyService } from 'app/_shared-core/service/currency.service'

export interface KirjausPoistotDialogData {
  naytettavaKirjaus: NaytettavaKirjaus
  automaattisenPoistonTili: Kirjanpitotili,
  automaattisenPoistonTilinKirjausRivi: Kirjausrivi,
  asiakas: Asiakas
}

export interface KirjausPoistotDialogResult {
  luoPoistokirjaukset: boolean
  suhteutaPoisto: boolean
  jaannosViimeiselleKuukaudelle: boolean
}

interface PoistonTiedot {
  tili: string
  lisays: number
  yhteensa: string
  kuukausia: number
  poistoPerKuukausi: string
}

@Component({
  templateUrl: './kirjaus.poistot.dialog.html'
})
export class KirjausPoistotDialog implements OnInit {

  form: FormGroup<{
    luoPoistokirjaukset: FormControl<boolean>,
    suhteutaPoisto: FormControl<boolean>,
    jaannosViimeiselleKuukaudelle: FormControl<boolean>,
  }>
  virhe: string = ''
  poistonTiedotObservable: Observable<PoistonTiedot | null>


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: KirjausPoistotDialogData,
    private _dialogRef: MatDialogRef<KirjausPoistotDialog, KirjausPoistotDialogResult>,
    private _dateService: DateService,
    private _formValidationService: FormValidationService,
    private _asiakasJaettuService: AsiakasJaettuService,
    private _currencyService: CurrencyService
  ) {
    this.form = new FormGroup({
      'luoPoistokirjaukset': new FormControl(true, [Validators.required]),
      'suhteutaPoisto': new FormControl(false, [Validators.required]),
      'jaannosViimeiselleKuukaudelle': new FormControl(false, [Validators.required]),
    })

    const kirjauksenPaivamaara = this.data.naytettavaKirjaus.uusiPaivamaara || this.data.naytettavaKirjaus.kirjaus.p

    const tilikausi = this._asiakasJaettuService.annaTilikausiPaivalleNumber(data.asiakas, kirjauksenPaivamaara)
    const tilikauttaJaljella = this._dateService.kuukausiaValissaPaikallinen(tilikausi.loppuu, this._dateService.numberToLocalDate(kirjauksenPaivamaara)) || 1

    this.poistonTiedotObservable = this.form.valueChanges.pipe(startWith(this.form.value), map((value) => {
      if (!value.luoPoistokirjaukset) {
        return null
      }

      const automaattisenPoistonTili = this.data.automaattisenPoistonTili

      const lisays = this.data.automaattisenPoistonTilinKirjausRivi.k

      let poistoYhteensa: number

      if (value.suhteutaPoisto) {
        // Poiston määrä vähenee jäjellä olevien kuukausien suhteessa. 
        // Esim. yllä olevassa esimerkissä 5 kuukautta jäljellä, joten koko poiston määrä on 208,33 (2000x0,25/12x5) 
        // ja kuukausittaisen poiston määrä on 41,67
        poistoYhteensa = lisays * (automaattisenPoistonTili.poistotiedot.poistoprosentti / 100) / 12 * tilikauttaJaljella
      } else {
        poistoYhteensa = lisays * (automaattisenPoistonTili.poistotiedot.poistoprosentti / 100)
      }

      const poistoPerKuukausi = poistoYhteensa / tilikauttaJaljella

      if (value.jaannosViimeiselleKuukaudelle) {
        poistoYhteensa = lisays
      }

      return {
        tili: `${automaattisenPoistonTili.numero} ${automaattisenPoistonTili.nimi} (${automaattisenPoistonTili.poistotiedot.poistoprosentti}%)`,
        lisays: lisays,
        yhteensa: this._currencyService.formatoiRahaIlmanValuuttaSymbolia(poistoYhteensa, 'fi'),
        kuukausia: tilikauttaJaljella,
        poistoPerKuukausi: this._currencyService.formatoiRahaIlmanValuuttaSymbolia(poistoPerKuukausi, 'fi')
      }
    }))
  }

  ngOnInit() { }

  save() {
    this.form.updateValueAndValidity()
    if (this.form.invalid || !this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    const { luoPoistokirjaukset, suhteutaPoisto, jaannosViimeiselleKuukaudelle } = this.form.value
    this._dialogRef.close({
      luoPoistokirjaukset: luoPoistokirjaukset,
      suhteutaPoisto: suhteutaPoisto,
      jaannosViimeiselleKuukaudelle: jaannosViimeiselleKuukaudelle
    })
  }

}
