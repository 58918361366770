<h1 class="raportti-header" style="margin-top: 20px;">ALV-laskelma</h1>
<ng-container *ngIf="dataObservable | async; let data">
  <ng-container *ngIf="!(loadingSubject | async) else loading">

    <div style="display: flex; justify-content: space-between; align-items: flex-end;">
      <div style="font-size: 16px; font-weight: bold; line-height: 24px;">{{(hakuvaihtoehdotObservable | async)?.alkaa| lnumberpvml:'fi'}} - {{(hakuvaihtoehdotObservable | async)?.loppuu | lnumberpvml:'fi'}}</div>
      <div *ngIf="lastSucessfullyUpdated" class="gray-text" style="font-size: 11px; line-height: 13px;">Päivitetty {{ lastSucessfullyUpdated | ltimestamptime | async }}</div>
    </div>

    <h3 style="margin-top: 2em;">Myynnit</h3>
    <!-- <button class="secondarybutton" mat-button (click)="createCsvExportFile()" type="button">Luo OmaVero siirtotiedosto</button> -->
    <table class="report-table" (click)="handleClick($event, data.myynnit, 'myynti')">
      <!-- Note: this is needed to be able to use table-layout: fixed with nth-child(x) selectors for widths. -->
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th>Verollinen</th>
          <th>Veroton</th>
          <th>Vero</th>
        </tr>
      </thead>
      <tbody *ngFor="let alvData of data.myynnit; trackBy: trackAccountRowByAccountNumberFn">
        <tr>
          <td colspan="5" style="font-weight: bold;">&nbsp;&nbsp;&nbsp;&nbsp;{{alvData.n}}</td>
        </tr>
        <ng-container *ngFor="let row of alvData.rivit">
          <tr [attr.data-tnro]="row.a" [attr.] [attr.data-vat]="row.alv" class="r">
            <td class="n" style="cursor: pointer;">&nbsp;&nbsp;{{row.n}} <kirjanpito-raportit-tilirivin-tunniste [row]="row" [tilitMapObservable]="tilitMapObservable"></kirjanpito-raportit-tilirivin-tunniste></td>
            <td style="width: 20px;"></td>
            <td>{{row.s | number:'1.2-2'}}</td>
            <td>{{row.b | number:'1.2-2'}}</td>
            <td>{{row.t | number:'1.2-2'}}</td>
          </tr>
          <tr *ngIf="row.e">
            <td colspan="5" style="padding-left: 20px;">
              <div *ngIf="row.d1.length == 0 && row.d2.length == 0">
                Tilille ei löytynyt yhtään vientiä.
              </div>
              <div style="font-weight: bold;">Veroton</div>
              <table *ngIf="row.d1.length > 0" style="width: 850px;">
                <thead>
                  <tr>
                    <td>Laji</td>
                    <td>Tosite</td>
                    <td>Pvm</td>
                    <td>Selite</td>
                    <td>ALV</td>
                    <td>Debet</td>
                    <td>Kredit</td>
                    <td>Saldo</td>
                  </tr>
                </thead>
                <tbody [attr.data-n]="row.a">
                  <tr *ngFor="let d of row.d1; let i = index;" [attr.data-i]="i" [class.laajennettu]="d.l">
                    <td [class.projekti-dot]="d.b" [class]="d.b ? 'a' + d.b : null">{{d.ma}}</td>
                    <td [attr.data-n]="d.n" style="cursor: pointer;">{{d.n}}</td>
                    <td>{{d.p | lnumberpvmlnarrow:'fi'}}</td>
                    <td>
                      <span *ngIf="d.a" class="blue-text space-after">{{d.a}}&nbsp;</span>
                      <span *ngIf="d.c" class="cursive-text space-after">{{d.c}}&nbsp;</span>
                      {{d.r}} {{d.e}}
                    </td>
                    <td>{{d.v}}</td>
                    <td>{{d.d | number:'1.2-2'}}</td>
                    <td>{{d.k | number:'1.2-2'}}</td>
                    <td>{{d.s | number:'1.2-2'}}</td>
                  </tr>
                  <!-- <tr class="loppusaldo">
                    <td></td>
                    <td></td>
                    <td colspan="2">Yhteensä</td>
                    <td></td>
                    <td>{{row.dds | number:'1.2-2'}}</td>
                    <td>{{row.dks | number:'1.2-2'}}</td>
                    <td>{{row.dks - row.dds | number:'1.2-2'}}</td>
                  </tr> -->
                </tbody>
              </table>

              <div style="font-weight: bold;">Vero</div>
              <table *ngIf="row.d2.length > 0" style="width: 850px;">
                <thead>
                  <tr>
                    <td>Laji</td>
                    <td>Tosite</td>
                    <td>Pvm</td>
                    <td>Selite</td>
                    <td>ALV</td>
                    <td>Debet</td>
                    <td>Kredit</td>
                    <td>Saldo</td>
                  </tr>
                </thead>
                <tbody [attr.data-n]="row.a">
                  <tr *ngFor="let d of row.d2; let i = index;" [attr.data-i]="i" [class.laajennettu]="d.l">
                    <td [class.projekti-dot]="d.b" [class]="d.b ? 'a' + d.b : null">{{d.ma}}</td>
                    <td [attr.data-n]="d.n" style="cursor: pointer;">{{d.n}}</td>
                    <td>{{d.p | lnumberpvmlnarrow:'fi'}}</td>
                    <td>
                      <span *ngIf="d.a" class="blue-text space-after">{{d.a}}&nbsp;</span>
                      <span *ngIf="d.c" class="cursive-text space-after">{{d.c}}&nbsp;</span>
                      {{d.r}} {{d.e}}
                    </td>
                    <td>{{d.v}}</td>
                    <td>{{d.d | number:'1.2-2'}}</td>
                    <td>{{d.k | number:'1.2-2'}}</td>
                    <td>{{d.s | number:'1.2-2'}}</td>
                  </tr>
                  <!-- <tr class="loppusaldo">
                    <td></td>
                    <td></td>
                    <td colspan="2">Yhteensä</td>
                    <td></td>
                    <td>{{row.dds | number:'1.2-2'}}</td>
                    <td>{{row.dks | number:'1.2-2'}}</td>
                    <td>{{row.dks - row.dds | number:'1.2-2'}}</td>
                  </tr> -->
                </tbody>
              </table>

              <div style="font-weight: bold;">Verollinen yhteensä {{row.t + row.b | number:'1.2-2'}}</div>
            </td>
          </tr>
        </ng-container>
        <tr class="r">
          <td class="n">&nbsp;&nbsp;{{alvData.n}} yhteensä</td>
          <td style="width: 20px;"></td>
          <td style="border-top: 1px solid black">{{alvData.s | number:'1.2-2'}}</td>
          <td style="border-top: 1px solid black">{{alvData.b | number:'1.2-2'}}</td>
          <td style="border-top: 1px solid black">{{alvData.t | number:'1.2-2'}}</td>
        </tr>
        <tr class="r">
          <td colspan="5">&nbsp;</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td colspan="5">&nbsp;</td>
        </tr>
        <tr class="r">
          <td class="n" style="font-weight: bold;">Myynnit yhteensä</td>
          <td style="width: 20px;"></td>
          <td style="font-weight: bold;">{{data.myyntiSums.s | number:'1.2-2'}}</td>
          <td style="font-weight: bold;">{{data.myyntiSums.b | number:'1.2-2'}}</td>
          <td style="font-weight: bold;">{{data.myyntiSums.t | number:'1.2-2'}}</td>
        </tr>
      </tbody>
    </table>

    <h3 style="margin-top: 2em;">Ostot</h3>
    <table class="report-table" (click)="handleClick($event, data.ostot, 'osto')">
      <!-- Note: this is needed to be able to use table-layout: fixed with nth-child(x) selectors for widths. -->
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th>Verollinen</th>
          <th>Veroton</th>
          <th>Vero</th>
        </tr>
      </thead>
      <tbody *ngFor="let alvData of data.ostot; trackBy: trackAccountRowByAccountNumberFn">
        <tr>
          <td colspan="5" style="font-weight: bold;">&nbsp;&nbsp;&nbsp;&nbsp;{{alvData.n}}</td>
        </tr>
        <tr *ngFor="let row of alvData.rivit" [attr.data-tnro]="row.a" [attr.data-vat]="row.alv" class="r">
          <td class="n">&nbsp;&nbsp;{{row.n}} <kirjanpito-raportit-tilirivin-tunniste [row]="row" [tilitMapObservable]="tilitMapObservable"></kirjanpito-raportit-tilirivin-tunniste></td>
          <td style="width: 20px;"></td>
          <td>{{row.s | number:'1.2-2'}}</td>
          <td>{{row.b | number:'1.2-2'}}</td>
          <td>{{row.t | number:'1.2-2'}}</td>
        </tr>
        <tr class="r">
          <td class="n">&nbsp;&nbsp;{{alvData.n}} yhteensä</td>
          <td style="width: 20px;"></td>
          <td style="border-top: 1px solid black">{{alvData.s | number:'1.2-2'}}</td>
          <td style="border-top: 1px solid black">{{alvData.b | number:'1.2-2'}}</td>
          <td style="border-top: 1px solid black">{{alvData.t | number:'1.2-2'}}</td>
        </tr>
        <tr class="r">
          <td colspan="5">&nbsp;</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td colspan="5">&nbsp;</td>
        </tr>
        <tr class="r">
          <td class="n" style="font-weight: bold;">Ostot yhteensä</td>
          <td style="width: 20px;"></td>
          <td style="font-weight: bold;">{{data.ostoSums.s | number:'1.2-2'}}</td>
          <td style="font-weight: bold;">{{data.ostoSums.b | number:'1.2-2'}}</td>
          <td style="font-weight: bold;">{{data.ostoSums.t | number:'1.2-2'}}</td>
        </tr>
        <tr class="r">
          <td class="n" colspan="4" style="font-weight: bold;">Vero yhteensä</td>
          <td style="font-weight: bold;">{{data.myyntiSums.t + data.ostoSums.t| number:'1.2-2'}}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</ng-container>
<ng-template #loading>
  <mat-spinner style="margin-left: auto; margin-right: auto; margin-top: 20px;"></mat-spinner>
</ng-template>