<h1 class="raportti-header" style="margin-top: 20px;">
  Erityisjärjestelmämyynnit
</h1>
<div *ngIf="lastSucessfullyUpdated" class="gray-text" style="font-size: 11px; text-align: right; line-height: 13px;">Päivitetty {{ lastSucessfullyUpdated | ltimestamptime | async }}</div>
<ng-container *ngIf="ossDataObservable | async; let data">
  <ng-container *ngIf="!(loadingSubject | async) else loading">
    <div style="font-size: 16px; font-weight: bold; line-height: 24px;">{{(hakuvaihtoehdotObservable | async)?.alkaa| lnumberpvml:'fi'}} - {{(hakuvaihtoehdotObservable | async)?.loppuu | lnumberpvml:'fi'}}</div>
    <button class="secondarybutton" mat-button (click)="createCsvExportFile()" type="button">Luo OmaVero siirtotiedosto</button>
    <table class="report-table" (click)="handleClick($event, data)">
      <!-- Note: this is needed to be able to use table-layout: fixed with nth-child(x) selectors for widths. -->
      <thead>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </thead>
      <tbody *ngFor="let row of data.r; trackBy: trackAccountRowByAccountNumberFn">
        <ng-container *ngIf="row.l === 1">
          <tr style="line-height: 30px;">
            <td colspan="5">&nbsp;</td>
          </tr>
          <tr class="rran">
            <td colspan="5">{{row.n}} <kirjanpito-raportit-tilirivin-tunniste [row]="row" [tilitMapObservable]="tilitMapObservable"></kirjanpito-raportit-tilirivin-tunniste></td>
          </tr>
          <tr class="rrab">
            <td>&nbsp;&nbsp;Maa</td>
            <td>Prosentti</td>
            <td>Veroton</td>
            <td>Vero</td>
            <td>Verollinen</td>
          </tr>
        </ng-container>
        <ng-container *ngIf="row.l === 2">
          <tr [attr.data-tnro]="row.a" [attr.data-vat]="row.v" class="r">
            <td class="n">&nbsp;&nbsp;{{row.n}} <kirjanpito-raportit-tilirivin-tunniste [row]="row" [tilitMapObservable]="tilitMapObservable"></kirjanpito-raportit-tilirivin-tunniste></td>
            <td>{{row.p | number:'1.0-2'}}%</td>
            <td>{{row.w | number:'1.2-2'}}</td>
            <td>{{row.w * (row.p/100) | number:'1.2-2'}}</td>
            <td>{{row.w * (1 + (row.p/100)) | number:'1.2-2'}}</td>
          </tr>
          <tr *ngIf="row.e && row.d == undefined" class="report-row-lvl-{{row.l}}">
            <td colspan="4">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </td>
          </tr>
          <tr *ngIf="row.e && row.d">
            <td colspan="5">
              <table style="margin: 10px; width: calc(100% - 20px); border-spacing: 0;">
                <thead>
                  <tr>
                    <th>Laji</th>
                    <th>Tosite</th>
                    <th>Pvm</th>
                    <th>Selite</th>
                    <th>ALV</th>
                    <th>Debet</th>
                    <th>Kredit</th>
                    <th>Saldo</th>
                  </tr>
                </thead>
                <tbody [attr.data-n]="row.n">
                  <tr *ngFor="let d of row.d; let i = index" [attr.data-i]="i" class="r" [class.laajennettu]="d.l">
                    <td [class.projekti-dot]="d.b" [class]="d.b ? 'a' + d.b : null">{{d.ma}}</td>
                    <td [attr.data-n]="d.n">{{d.n}}</td>
                    <td>{{d.p | lnumberpvmlnarrow:'fi'}}</td>
                    <td>
                      <span *ngIf="d.a" class="blue-text">{{d.a}}</span>
                      <span *ngIf="d.c" class="cursive-text">{{d.c}}</span>
                      {{d.r}} {{d.e}}
                    </td>
                    <td>{{d.v}}</td>
                    <td>{{d.d| number:'1.2-2'}}</td>
                    <td>{{d.k | number:'1.2-2'}}</td>
                    <td>{{d.s | number:'1.2-2'}}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </ng-container>
</ng-container>
<ng-template #loading>
  <mat-spinner style="margin-left: auto; margin-right: auto; margin-top: 20px;"></mat-spinner>
</ng-template>