import { DateService } from '../../_shared-core/service/date.service'
import { CurrencyService } from '../../_shared-core/service/currency.service'
import { Tilikausi } from '../model/asiakas'
import { MuutoksetOmassaPaaomassaLaskelma, TilinpaatosLiitetiedot, TilinpaatosLiitetiedotKentat } from '../model/kirjanpito'
import { TimestampProviderBase } from '../../_shared-core/service/timestamp-provider.interface'

export class TilinpaatosLiitetiedotJaettuService {

  constructor(
    private _dateService: DateService,
    private _currencyService: CurrencyService,
    private _timestampService: TimestampProviderBase
  ) { }

  paivitaOsingonjakolaskelmat(liitetiedot: TilinpaatosLiitetiedot, tallentamattomat: TilinpaatosLiitetiedot, osakkeidenLukumaara: number) {

    liitetiedot.osingonjakoEsilaskettuOsakkeidenLukumaara = osakkeidenLukumaara

    const rawSum = this._currencyService.roundHalfUp(0.08 * liitetiedot.omanPaaomanMuutoksetLaskelma.omaPaaomaYhteensa, 2)
    const osinkoPerOsake = this._currencyService.roundHalfUp(rawSum / osakkeidenLukumaara, 10)
    liitetiedot.osingonjakoEsilaskettuOsinkoPerOsake = osinkoPerOsake
    liitetiedot.osingonjakoEsilaskettuMaara = rawSum
    // liitetiedot.osingonjakoEsilaskettuMaara = this._currencyService.roundHalfUp(osinkoPerOsake * osakkeidenLukumaara, 2)

    if (tallentamattomat?.osingonjako) { liitetiedot.osingonjako = tallentamattomat.osingonjako }

    if (tallentamattomat?.osingonjakoAnnettuMaara !== null && tallentamattomat?.osingonjakoAnnettuMaara !== undefined) { liitetiedot.osingonjakoAnnettuMaara = tallentamattomat.osingonjakoAnnettuMaara }
    if (tallentamattomat?.osingonjakoEsilaskettuMaara !== null && tallentamattomat?.osingonjakoEsilaskettuMaara !== undefined) { liitetiedot.osingonjakoEsilaskettuMaara = tallentamattomat.osingonjakoEsilaskettuMaara }

    if (liitetiedot.osingonjako === 'ei-jaeta') {
      liitetiedot.osinkoPerOsake = 0
      liitetiedot.osinkojaYhteensa = 0
    } else if (liitetiedot.osingonjako === 'annettu-maara') {
      liitetiedot.osinkoPerOsake = this._currencyService.roundHalfUp(liitetiedot.osingonjakoAnnettuMaara / osakkeidenLukumaara, 10)
      liitetiedot.osinkojaYhteensa = liitetiedot.osingonjakoAnnettuMaara
    } else if (liitetiedot.osingonjako === 'esilaskettu-prosentti') {
      liitetiedot.osinkoPerOsake = osinkoPerOsake
      liitetiedot.osinkojaYhteensa = liitetiedot.osingonjakoEsilaskettuMaara
    }

    if (tallentamattomat?.osinkoPerOsake !== null && tallentamattomat?.osinkoPerOsake !== undefined) { liitetiedot.osinkoPerOsake = tallentamattomat?.osinkoPerOsake }
    if (tallentamattomat?.osinkojaYhteensa !== null && tallentamattomat?.osinkojaYhteensa !== undefined) { liitetiedot.osinkojaYhteensa = tallentamattomat?.osinkojaYhteensa }
  }

  annaOmanPaaomanMuutoksetLaskelma(
    liitetiedot: TilinpaatosLiitetiedot,
    tallentamattomat: TilinpaatosLiitetiedot,
    tilikausi: Tilikausi,
    tilikaudenVoittoTaiTappio: number,
    omaPaaomaJaPaaomalainatYhteensa: number
  ): MuutoksetOmassaPaaomassaLaskelma {

    let edellistenVoittoTaiTappio = tallentamattomat?.omanPaaomanMuutoksetVertailulaskelma?.edellistenTilikausienVoittoTaiTappioTilikaudenLopussa ?? liitetiedot.omanPaaomanMuutoksetVertailulaskelma.edellistenTilikausienVoittoTaiTappioTilikaudenLopussa
    edellistenVoittoTaiTappio += tallentamattomat?.omanPaaomanMuutoksetVertailulaskelma?.tilikaudenVoittoTaiTappio ?? liitetiedot.omanPaaomanMuutoksetVertailulaskelma.tilikaudenVoittoTaiTappio

    const laskelma: MuutoksetOmassaPaaomassaLaskelma = {
      header: this._dateService.muotoilePaikallinenPaiva(liitetiedot.loppuu ?? tilikausi.loppuu, 'fi'),
      osakepaaomaTilikaudenAlussa: tallentamattomat?.omanPaaomanMuutoksetVertailulaskelma?.osakepaaomaTilikaudenLopussa ?? liitetiedot.omanPaaomanMuutoksetVertailulaskelma.osakepaaomaTilikaudenLopussa,
      svopTilikaudenAlussa: tallentamattomat?.omanPaaomanMuutoksetVertailulaskelma?.svopTilikaudenLopussa ?? liitetiedot.omanPaaomanMuutoksetVertailulaskelma.svopTilikaudenLopussa,
      edellistenTilikausienVoittoTaiTappioTilikaudenAlussa: edellistenVoittoTaiTappio,
      tilikaudenVoittoTaiTappio: tilikaudenVoittoTaiTappio,
      edellistenTilikausienVoittoTaiTappioTilikaudenLopussa: 0,
      omaPaaomaEiTasmaa: false,
      omaPaaomaPlusPaaomalainat: 0,
      omaPaaomaYhteensa: 0,
      osakeanti: this._annaNumeroJosValittu(TilinpaatosLiitetiedotKentat.OSAKEANTI, liitetiedot, tallentamattomat),
      osakepaaomaTilikaudenLopussa: 0,
      osingonjakoTilikaudella: this._annaNumeroJosValittu(TilinpaatosLiitetiedotKentat.OSINKOJEN_MAARA, liitetiedot, tallentamattomat),
      edellistenTilikausienVirheidenOikaisut: this._annaNumeroJosValittu(TilinpaatosLiitetiedotKentat.EDELLISEN_TILIKAUDEN_OIKAISUT, liitetiedot, tallentamattomat),
      sidottuOmaPaaoma: 0,
      svopLisayksetTilikaudenAikana: this._annaNumeroJosValittu(TilinpaatosLiitetiedotKentat.SVOP_LISAYKSET, liitetiedot, tallentamattomat),
      svopVahennyksetTilikaudenAikana: this._annaNumeroJosValittu(TilinpaatosLiitetiedotKentat.SVOP_VAHENNYKSET, liitetiedot, tallentamattomat),
      svopTilikaudenLopussa: 0,
      tilikaudellaOstetutOmatOsakkeet: this._annaNumeroJosValittu(TilinpaatosLiitetiedotKentat.YHTIO_ON_OSTANUT_OSAKKEITAAN, liitetiedot, tallentamattomat),
      tilikaudellaMyydytOmatOsakkeet: this._annaNumeroJosValittu(TilinpaatosLiitetiedotKentat.YHTIO_ON_MYYNYT_OSAKKEITAAN, liitetiedot, tallentamattomat),
      vapaaOmaPaaoma: 0,
      jakokelpoinenOmaPaaomaYhteensa: 0,
      osakepaaomaTilikaudenAlussaNakyvissa: true,
      osakeantiNakyvissa: true,
      osakepaaomaTilikaudenLopussaNakyvissa: true,
      sidottuOmaPaaomaNakyvissa: true,
      svopTilikaudenAlussaNakyvissa: true,
      svopLisayksetTilikaudenAikanaNakyvissa: true,
      svopVahennyksetTilikaudenAikanaNakyvissa: true,
      svopTilikaudenLopussaNakyvissa: true,
      edellistenTilikausienVoittoTaiTappioTilikaudenAlussaNakyvissa: true,
      osingonjakoTilikaudellaNakyvissa: true,
      edellistenTilikausienVirheidenOikaisutNakyvissa: true,
      tilikaudellaOstetutOmatOsakkeetNakyvissa: true,
      tilikaudellaMyydytOmatOsakkeetNakyvissa: true,
      edellistenTilikausienVoittoTaiTappioTilikaudenLopussaNakyvissa: true,
      tilikaudenVoittoTaiTappioNakyvissa: true,
      vapaaOmaPaaomaNakyvissa: true,
      omaPaaomaYhteensaNakyvissa: true
    }

    this.paivitaLaskelmanSummat(liitetiedot, laskelma)

    if (liitetiedot.vertailutiedot === 'ensimmainen-tilikausi') {
      this._paivitaLaskelmarivienNakyvyydetSummienPerusteella(laskelma)
    } else {
      this._paivitaLaskelmarivienNakyvyydetSummienPerusteella(laskelma, liitetiedot.omanPaaomanMuutoksetVertailulaskelma)
    }

    laskelma.omaPaaomaPlusPaaomalainat = laskelma.omaPaaomaYhteensa + this._annaNumeroJosValittu(TilinpaatosLiitetiedotKentat.YHTIOLLA_PAAOMALAINAA, liitetiedot, tallentamattomat)

    // console.log(this._currencyService.roundHalfUp(laskelma.omaPaaomaYhteensa, 2), this._currencyService.roundHalfUp(tase?.r?.find(a => a.a === '20')?.s1, 2))
    laskelma.omaPaaomaEiTasmaa = this._currencyService.roundHalfUp(laskelma.omaPaaomaPlusPaaomalainat, 2) !== this._currencyService.roundHalfUp(omaPaaomaJaPaaomalainatYhteensa, 2)

    return laskelma

  }

  paivitaLaskelmanSummat(liitetiedot: TilinpaatosLiitetiedot, laskelma: MuutoksetOmassaPaaomassaLaskelma) {
    laskelma.osakepaaomaTilikaudenLopussa = (laskelma.osakepaaomaTilikaudenAlussa ?? 0) + (laskelma.osakeanti ?? 0)
    laskelma.sidottuOmaPaaoma = (laskelma.osakepaaomaTilikaudenLopussa ?? 0)

    laskelma.edellistenTilikausienVoittoTaiTappioTilikaudenLopussa = (laskelma.edellistenTilikausienVoittoTaiTappioTilikaudenAlussa ?? 0) - (laskelma.osingonjakoTilikaudella ?? 0) - (laskelma.tilikaudellaOstetutOmatOsakkeet ?? 0) + (laskelma.tilikaudellaMyydytOmatOsakkeet ?? 0) + (laskelma.edellistenTilikausienVirheidenOikaisut ?? 0)

    laskelma.svopTilikaudenLopussa = (laskelma.svopTilikaudenAlussa ?? 0) + (laskelma.svopLisayksetTilikaudenAikana ?? 0) - (laskelma.svopVahennyksetTilikaudenAikana ?? 0)

    laskelma.vapaaOmaPaaoma = (laskelma.svopTilikaudenLopussa ?? 0) + (laskelma.edellistenTilikausienVoittoTaiTappioTilikaudenLopussa ?? 0) + (laskelma.tilikaudenVoittoTaiTappio ?? 0)

    laskelma.omaPaaomaYhteensa = (laskelma.vapaaOmaPaaoma ?? 0) + (laskelma.sidottuOmaPaaoma ?? 0)

    laskelma.jakokelpoinenOmaPaaomaYhteensa = (laskelma.svopTilikaudenLopussa ?? 0) + (laskelma.edellistenTilikausienVoittoTaiTappioTilikaudenLopussa ?? 0) + (laskelma.tilikaudenVoittoTaiTappio ?? 0)
    if (liitetiedot.jakokelpoinenOmaPaaomaRivit) {
      for (const rivi of liitetiedot.jakokelpoinenOmaPaaomaRivit) {
        if (!rivi.poistettu) {
          laskelma.jakokelpoinenOmaPaaomaYhteensa += rivi.maara ?? 0
        }
      }
    }
  }

  private _annaNumeroJosValittu(field: TilinpaatosLiitetiedotKentat, liitetiedot: TilinpaatosLiitetiedot, tallentamattomat: TilinpaatosLiitetiedot): number {
    if (liitetiedot?.valitut[field] || tallentamattomat?.valitut[field]) { // Miksi tallentamattomia ei tarkistettu?
      return this._annaNumero(field, liitetiedot, tallentamattomat)
    }
    return 0
  }

  private _annaNumero(field: TilinpaatosLiitetiedotKentat, liitetiedot: TilinpaatosLiitetiedot, tallentamattomat: TilinpaatosLiitetiedot): number {
    return tallentamattomat?.numerot[field] ?? liitetiedot.numerot[field] ?? 0
  }

  private _paivitaLaskelmarivienNakyvyydetSummienPerusteella(laskelma: MuutoksetOmassaPaaomassaLaskelma, vertailulaskelma?: MuutoksetOmassaPaaomassaLaskelma) {

    // Alkuarvot
    laskelma.osakepaaomaTilikaudenAlussaNakyvissa = false
    laskelma.osakeantiNakyvissa = false
    laskelma.osakepaaomaTilikaudenLopussaNakyvissa = false
    laskelma.sidottuOmaPaaomaNakyvissa = false
    laskelma.svopTilikaudenAlussaNakyvissa = false
    laskelma.svopLisayksetTilikaudenAikanaNakyvissa = false
    laskelma.svopVahennyksetTilikaudenAikanaNakyvissa = false
    laskelma.svopTilikaudenLopussaNakyvissa = false
    laskelma.edellistenTilikausienVoittoTaiTappioTilikaudenAlussaNakyvissa = false
    laskelma.osingonjakoTilikaudellaNakyvissa = false
    laskelma.edellistenTilikausienVirheidenOikaisutNakyvissa = false
    laskelma.tilikaudellaOstetutOmatOsakkeetNakyvissa = false
    laskelma.tilikaudellaMyydytOmatOsakkeetNakyvissa = false
    laskelma.edellistenTilikausienVoittoTaiTappioTilikaudenLopussaNakyvissa = false
    laskelma.tilikaudenVoittoTaiTappioNakyvissa = false
    laskelma.vapaaOmaPaaomaNakyvissa = false
    laskelma.omaPaaomaYhteensaNakyvissa = false

    this._paivitaLaskelmarivienNakyvyydetSummienPerusteellaYhdestaLaskelmasta(laskelma, laskelma)
    if (vertailulaskelma) {
      this._paivitaLaskelmarivienNakyvyydetSummienPerusteellaYhdestaLaskelmasta(laskelma, vertailulaskelma)
    }

  }

  private _paivitaLaskelmarivienNakyvyydetSummienPerusteellaYhdestaLaskelmasta(merkintaLaskelma: MuutoksetOmassaPaaomassaLaskelma, summalaskelma: MuutoksetOmassaPaaomassaLaskelma) {

    // Osakepääoma
    if (summalaskelma.osakepaaomaTilikaudenAlussa || summalaskelma.osakeanti) {
      merkintaLaskelma.osakepaaomaTilikaudenAlussaNakyvissa = true
      merkintaLaskelma.osakepaaomaTilikaudenLopussaNakyvissa = true
    }
    if (summalaskelma.osakeanti) {
      merkintaLaskelma.osakeantiNakyvissa = true
    }

    // Sidottu oma pääoma
    if (summalaskelma.sidottuOmaPaaoma) {
      merkintaLaskelma.sidottuOmaPaaomaNakyvissa = true
    }

    // Svop
    if (summalaskelma.svopTilikaudenAlussa || summalaskelma.svopLisayksetTilikaudenAikana || summalaskelma.svopVahennyksetTilikaudenAikana) {
      merkintaLaskelma.svopTilikaudenAlussaNakyvissa = true
      merkintaLaskelma.svopTilikaudenLopussaNakyvissa = true
    }
    if (summalaskelma.svopLisayksetTilikaudenAikana) {
      merkintaLaskelma.svopLisayksetTilikaudenAikanaNakyvissa = true
    }
    if (summalaskelma.svopVahennyksetTilikaudenAikana) {
      merkintaLaskelma.svopVahennyksetTilikaudenAikanaNakyvissa = true
    }

    // Edellisten vuosien voitto / tappio
    if (summalaskelma.edellistenTilikausienVoittoTaiTappioTilikaudenAlussa || summalaskelma.osingonjakoTilikaudella || summalaskelma.tilikaudellaOstetutOmatOsakkeet || summalaskelma.tilikaudellaMyydytOmatOsakkeet) {
      merkintaLaskelma.edellistenTilikausienVoittoTaiTappioTilikaudenAlussaNakyvissa = true
      merkintaLaskelma.edellistenTilikausienVoittoTaiTappioTilikaudenLopussaNakyvissa = true
    }
    if (summalaskelma.osingonjakoTilikaudella) {
      merkintaLaskelma.osingonjakoTilikaudellaNakyvissa = true
    }
    if (summalaskelma.edellistenTilikausienVirheidenOikaisut) {
      merkintaLaskelma.edellistenTilikausienVirheidenOikaisutNakyvissa = true
    }
    if (summalaskelma.tilikaudellaOstetutOmatOsakkeet) {
      merkintaLaskelma.tilikaudellaOstetutOmatOsakkeetNakyvissa = true
    }
    if (summalaskelma.tilikaudellaMyydytOmatOsakkeet) {
      merkintaLaskelma.tilikaudellaMyydytOmatOsakkeetNakyvissa = true
    }

    // Tilikauden voitto/tappio
    if (summalaskelma.tilikaudenVoittoTaiTappio) {
      merkintaLaskelma.tilikaudenVoittoTaiTappioNakyvissa = true
    }

    // Vapaa oma pääoma
    if (summalaskelma.vapaaOmaPaaoma) {
      merkintaLaskelma.vapaaOmaPaaomaNakyvissa = true
    }

    // Pääoma yhteensä
    if (summalaskelma.omaPaaomaYhteensa) {
      merkintaLaskelma.omaPaaomaYhteensaNakyvissa = true
    }

  }

  annaTyhjaLiitetiedotObject(nykyinenTilikausi: Tilikausi, edellinenTilikausi: Tilikausi): TilinpaatosLiitetiedot {
    return {
      tekstit: {},
      valitut: {},
      numerot: {},
      osionLisarivit: {},
      lisatietokentat: [],
      omanPaaomanMuutoksetLaskelma: this.annaTyhjaOmaPaaomaLaskelma(nykyinenTilikausi),
      omanPaaomanMuutoksetVertailulaskelma: this.annaTyhjaVertailulaskelma(edellinenTilikausi),
      vertailutiedot: null,
      osingonjako: null,
      osingonjakoAnnettuMaara: null,
      osingonjakoEsilaskettuMaara: null,
      osingonjakoEsilaskettuOsinkoPerOsake: null,
      osingonjakoEsilaskettuOsakkeidenLukumaara: null,
      osinkoPerOsake: null,
      osinkojaYhteensa: null,
      liitteet: [],
      jakokelpoinenOmaPaaomaRivit: [],
      avain: null,
      luoja: null,
      luotu: null,
      paivitetty: null,
      paivittaja: null,
      poistettu: null,
      tilintarkastetaan: null,
      osingotNostettavissa: undefined,
      osingotNostettavissaPvm: undefined,
      kokoLomakeTallennettu: null,
      allekirjoitetaanLemonaidissa: null
    }
  }

  annaTyhjaOmaPaaomaLaskelma(tilikausi: Tilikausi): MuutoksetOmassaPaaomassaLaskelma {
    const laskelma: MuutoksetOmassaPaaomassaLaskelma = {
      header: tilikausi ? this._dateService.muotoilePaikallinenPaiva(tilikausi.loppuu, 'fi') : '',
      osakepaaomaTilikaudenAlussa: 0,
      svopTilikaudenAlussa: 0,
      edellistenTilikausienVoittoTaiTappioTilikaudenAlussa: 0,
      tilikaudenVoittoTaiTappio: 0,
      edellistenTilikausienVoittoTaiTappioTilikaudenLopussa: 0,
      omaPaaomaEiTasmaa: false,
      omaPaaomaPlusPaaomalainat: 0,
      omaPaaomaYhteensa: 0,
      osakeanti: 0,
      osakepaaomaTilikaudenLopussa: 0,
      osingonjakoTilikaudella: 0,
      edellistenTilikausienVirheidenOikaisut: 0,
      sidottuOmaPaaoma: 0,
      svopLisayksetTilikaudenAikana: 0,
      svopVahennyksetTilikaudenAikana: 0,
      svopTilikaudenLopussa: 0,
      tilikaudellaOstetutOmatOsakkeet: 0,
      tilikaudellaMyydytOmatOsakkeet: 0,
      vapaaOmaPaaoma: 0,
      jakokelpoinenOmaPaaomaYhteensa: 0,
      osakepaaomaTilikaudenAlussaNakyvissa: true,
      osakeantiNakyvissa: true,
      osakepaaomaTilikaudenLopussaNakyvissa: true,
      sidottuOmaPaaomaNakyvissa: true,
      svopTilikaudenAlussaNakyvissa: true,
      svopLisayksetTilikaudenAikanaNakyvissa: true,
      svopVahennyksetTilikaudenAikanaNakyvissa: true,
      svopTilikaudenLopussaNakyvissa: true,
      edellistenTilikausienVoittoTaiTappioTilikaudenAlussaNakyvissa: true,
      osingonjakoTilikaudellaNakyvissa: true,
      edellistenTilikausienVirheidenOikaisutNakyvissa: true,
      tilikaudellaOstetutOmatOsakkeetNakyvissa: true,
      tilikaudellaMyydytOmatOsakkeetNakyvissa: true,
      edellistenTilikausienVoittoTaiTappioTilikaudenLopussaNakyvissa: true,
      tilikaudenVoittoTaiTappioNakyvissa: true,
      vapaaOmaPaaomaNakyvissa: true,
      omaPaaomaYhteensaNakyvissa: true
    }
    return laskelma
  }

  annaTyhjaVertailulaskelma(tilikausi: Tilikausi): MuutoksetOmassaPaaomassaLaskelma {
    const laskelma: MuutoksetOmassaPaaomassaLaskelma = {
      header: tilikausi ? this._dateService.muotoilePaikallinenPaiva(tilikausi.loppuu, 'fi') : '',
      osakepaaomaTilikaudenAlussa: null,
      svopTilikaudenAlussa: null,
      edellistenTilikausienVoittoTaiTappioTilikaudenAlussa: null,
      tilikaudenVoittoTaiTappio: null,
      edellistenTilikausienVoittoTaiTappioTilikaudenLopussa: null,
      omaPaaomaEiTasmaa: false,
      omaPaaomaPlusPaaomalainat: null,
      omaPaaomaYhteensa: null,
      osakeanti: null,
      osakepaaomaTilikaudenLopussa: null,
      osingonjakoTilikaudella: null,
      edellistenTilikausienVirheidenOikaisut: null,
      sidottuOmaPaaoma: null,
      svopLisayksetTilikaudenAikana: null,
      svopVahennyksetTilikaudenAikana: null,
      svopTilikaudenLopussa: null,
      tilikaudellaOstetutOmatOsakkeet: null,
      tilikaudellaMyydytOmatOsakkeet: null,
      vapaaOmaPaaoma: null,
      jakokelpoinenOmaPaaomaYhteensa: 0,
      osakepaaomaTilikaudenAlussaNakyvissa: true,
      osakeantiNakyvissa: true,
      osakepaaomaTilikaudenLopussaNakyvissa: true,
      sidottuOmaPaaomaNakyvissa: true,
      svopTilikaudenAlussaNakyvissa: true,
      svopLisayksetTilikaudenAikanaNakyvissa: true,
      svopVahennyksetTilikaudenAikanaNakyvissa: true,
      svopTilikaudenLopussaNakyvissa: true,
      edellistenTilikausienVoittoTaiTappioTilikaudenAlussaNakyvissa: true,
      osingonjakoTilikaudellaNakyvissa: true,
      edellistenTilikausienVirheidenOikaisutNakyvissa: true,
      tilikaudellaOstetutOmatOsakkeetNakyvissa: true,
      tilikaudellaMyydytOmatOsakkeetNakyvissa: true,
      edellistenTilikausienVoittoTaiTappioTilikaudenLopussaNakyvissa: true,
      tilikaudenVoittoTaiTappioNakyvissa: true,
      vapaaOmaPaaomaNakyvissa: true,
      omaPaaomaYhteensaNakyvissa: true
    }
    return laskelma
  }

  valmisteleTallennettava(liitetiedot: TilinpaatosLiitetiedot, kirjanpitajaUid: string, tilikausi: Tilikausi) {
    if (!liitetiedot.luoja) {
      liitetiedot.luoja = kirjanpitajaUid
    }
    if (!liitetiedot.luotu) {
      liitetiedot.luotu = this._timestampService.now()
    }

    liitetiedot.paivittaja = kirjanpitajaUid
    liitetiedot.paivitetty = this._timestampService.now()
    liitetiedot.avain = tilikausi.avain
  }
}
