<div class="tilinpaatos-container">

  <mat-form-field appearance="fill" style="margin-top: 20px;">
    <mat-label>Tilikausi</mat-label>
    <mat-select [value]="selectedTilikausiObservable | async" (selectionChange)="setTilikausiEvent($event)" [compareWith]="compareTilikaudet">
      <mat-option *ngFor="let tilikausi of tilikaudetObservable | async" [value]="tilikausi">{{ tilikausi?.loppuu?.year }}</mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container *ngIf="viewSettingsObservable | async let viewSettings">
    <div class="tilinpaatos-btn-group">
      <button class="tilinpaatos-btn" mat-button [class.tilinpaatos-btn-selected]="viewSettings.valilehti === 'aloitus'" (click)="setSelectedTab('aloitus')">
        <div>Aloitus</div>
      </button>
      <button class="tilinpaatos-btn" mat-button [class.tilinpaatos-btn-selected]="viewSettings.valilehti === 'tase-erittely'" [disabled]="viewSettings.tilinpaatosNotStarted" (click)="setSelectedTab('tase-erittely')">
        <div>Tase-erittely</div>
      </button>
      <button class="tilinpaatos-btn" mat-button [class.tilinpaatos-btn-selected]="viewSettings.valilehti === 'liitetiedot'" [disabled]="viewSettings.tilinpaatosNotStarted" (click)="setSelectedTab('liitetiedot')">
        <div>Liitetiedot</div>
      </button>
      <!-- <button *ngIf="tilintarkastetaanObservable | async" class="tilinpaatos-btn" mat-button [class.tilinpaatos-btn-selected]="viewSettings.valilehti === 'tilintarkastus'" [disabled]="viewSettings.tilinpaatosNotStarted" (click)="setSelectedTab('tilintarkastus')">
      <div>Tilintarkastus</div>
    </button> -->
      <button class="tilinpaatos-btn" mat-button [class.tilinpaatos-btn-selected]="viewSettings.valilehti === 'lahetys'" [disabled]="viewSettings.tilinpaatosNotStarted" (click)="setSelectedTab('lahetys')">
        <div>Lähetys</div>
      </button>
      <button class="tilinpaatos-btn" mat-button [class.tilinpaatos-btn-selected]="viewSettings.valilehti === 'rekisterointi'" [disabled]="viewSettings.tilinpaatosNotStarted" (click)="setSelectedTab('rekisterointi')">
        <div>Rekisteröinti</div>
      </button>
    </div>

    <ng-container>
      <div *ngIf="viewSettings.valilehti === 'aloitus'" app-kirjanpito-tilinpaatos-aloitus [selectedTilikausiObservable]="selectedTilikausiObservable" [tilinpaatosStatusObservable]="tilinpaatosStatusObservable" [liitetiedotObservable]="liitetiedotObservable" (startDone)="jatkaTilinpaatokseen()"></div>
      <div *ngIf="viewSettings.valilehti === 'tase-erittely'" app-kirjanpito-tilinpaatos-tase-erittely [selectedTilikausiObservable]="selectedTilikausiObservable" [paivitaArvotHiljaisestiObservable]="paivitaArvotHiljaisestiObservable" [tilinpaatosStatusObservable]="tilinpaatosStatusObservable" (kirjaustaKlikattiin)="emitKirjausData($event)"></div>
      <div *ngIf="viewSettings.valilehti === 'liitetiedot'" app-kirjanpito-tilinpaatos-liitetiedot [selectedTilikausiObservable]="selectedTilikausiObservable" [paivitaArvotHiljaisestiObservable]="paivitaArvotHiljaisestiObservable" [tilinpaatosStatusObservable]="tilinpaatosStatusObservable" [nykyisetLiitetiedotTietokannassaObservable]="liitetiedotObservable"></div>
      <!-- <div *ngIf="viewSettings.valilehti === 'tilintarkastus'" app-kirjanpito-tilinpaatos-tilintarkastus [selectedTilikausiObservable]="selectedTilikausiObservable" [paivitaArvotHiljaisestiObservable]="paivitaArvotHiljaisestiObservable" [tilinpaatosStatusObservable]="tilinpaatosStatusObservable"></div> -->
      <div *ngIf="viewSettings.valilehti === 'lahetys'" app-tilinpaatos-lahetys [selectedTilikausiObservable]="selectedTilikausiObservable" [paivitaArvotHiljaisestiObservable]="paivitaArvotHiljaisestiObservable" [tilinpaatosStatusObservable]="tilinpaatosStatusObservable"></div>
      <div *ngIf="viewSettings.valilehti === 'rekisterointi'" app-kirjanpito-tilinpaatos-rekisterointi [selectedTilikausiObservable]="selectedTilikausiObservable" [paivitaArvotHiljaisestiObservable]="paivitaArvotHiljaisestiObservable" [tilinpaatosStatusObservable]="tilinpaatosStatusObservable"></div>
    </ng-container>
  </ng-container>

</div>