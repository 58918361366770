import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { Subject, Observable, combineLatest, BehaviorSubject } from 'rxjs'
import { AsiakasService } from '../../_angular/service/asiakas/asiakas.service'
import { KirjanpitoImageHandlerService, TositeJaNaytettavaSivut } from '../../_angular/service/kirjanpito/kirjanpito.service'

import { map, switchMap } from 'rxjs/operators'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'
import { KirjattavaKuitti, KirjattavaLasku, KirjattavaRaahattuTiedosto } from 'app/_jaettu-lemonator/model/kirjanpito'
import { Asiakas } from 'app/_jaettu-lemonator/model/asiakas'
import { Laskuasetukset } from 'app/_jaettu/model/lasku'
import { NaytettavaKirjaus } from '../kirjanpito.component'

export type TositeTyyppi = 'kuitti' | 'lasku' | 'raahattu'

/**
 * Component that is served from asiakkaat/:asiakasAvain/kirjanpidon-tosite/:tositeTyyppi/tositeAvain
 */
@Component({
  templateUrl: './tosite-standalone.component.html',
  styleUrls: ['./tosite-standalone.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KirjanpitoTositeStandaloneComponent implements OnInit, OnDestroy {

  @ViewChild('mainDiv', { static: false }) mainDivRef: ElementRef<HTMLDivElement>

  private _ngUnsubscribe = new Subject<void>()

  naytettavatTositteetJaSivutObservable: Observable<TositeJaNaytettavaSivut[]>
  naytettavaKirjausObservable: Observable<NaytettavaKirjaus> = new BehaviorSubject(null)
  asiakkaanTiedotObservable: Observable<string>

  constructor(
    private _route: ActivatedRoute,
    private _firebase: FirebaseLemonator,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _kirjanpitoImageHandlerService: KirjanpitoImageHandlerService,
    private _asiakasService: AsiakasService
  ) { }

  ngOnInit() {

    this.asiakkaanTiedotObservable = this._asiakasService.nykyinenAsiakasObservable.pipe(
      map(asiakas => {
        if (asiakas) {
          return asiakas.nimi + ' (' + asiakas.ytunnus + ')'
        }
        return ''
      })
    )

    const paramMap = this._route.snapshot.paramMap
    // const asiakasAvain = paramMap.get('asiakasAvain')
    const tositeAvain = paramMap.get('tositeAvain')
    const tositeTyyppi = paramMap.get('tositeTyyppi')

    if (
      tositeTyyppi !== 'kuitti' &&
      tositeTyyppi !== 'lasku' &&
      tositeTyyppi !== 'raahattu'
    ) {
      throw new Error('Unknown tosite tyyppi "' + tositeTyyppi + '"')
    }

    this.naytettavatTositteetJaSivutObservable = this._asiakasService.nykyinenAsiakasObservable.pipe(
      switchMap((asiakas) => {
        return this.loadData(asiakas, tositeTyyppi, tositeAvain)
      })
    );


  }

  private async loadData(asiakas: Asiakas, tositeTyyppi: TositeTyyppi, tositeAvain: string): Promise<TositeJaNaytettavaSivut[]> {

    if (tositeTyyppi === 'kuitti') {
      const kirjattavaKuittiUri = this._kirjanpitoUriService.annaKirjattavanFirestoreTositteenUriAvaimella(asiakas.avain, tositeAvain)
      const kirjattavaKuitti = await this._firebase.firestoreDoc<KirjattavaKuitti>(kirjattavaKuittiUri).get()
      const kirjaukseenLiitetytSivut = this._kirjanpitoImageHandlerService.muunnaKirjattavaKuittiLiitetyiksiSivuiksi(kirjattavaKuitti)
      return this._kirjanpitoImageHandlerService.muunnaTositteiksiJaSivuiksi(asiakas, kirjaukseenLiitetytSivut, [], [], true)
    } else if (tositeTyyppi === 'lasku') {
      const kirjattavaLaskuUri = this._kirjanpitoUriService.annaKirjattavanLaskunUriAvaimella(asiakas.avain, tositeAvain)
      const kirjattavaLasku = await this._firebase.firestoreDoc<KirjattavaLasku>(kirjattavaLaskuUri).get()
      const kirjaukseenLiitetytSivut = this._kirjanpitoImageHandlerService.muunnaKirjattavaLaskuLiitetyiksiSivuiksi(kirjattavaLasku)
      return this._kirjanpitoImageHandlerService.muunnaTositteiksiJaSivuiksi(asiakas, kirjaukseenLiitetytSivut, [], [], true)

    } else if (tositeTyyppi === 'raahattu') {
      const kirjattavaRaahattuUri = this._kirjanpitoUriService.annaKirjattavanRaahatunUri(asiakas.avain, tositeAvain)
      const kirjattavaRaahattu = await this._firebase.firestoreDoc<KirjattavaRaahattuTiedosto>(kirjattavaRaahattuUri).get()
      const kirjaukseenLiitetytSivut = this._kirjanpitoImageHandlerService.muunnaKirjattavaRaahattuTiedostoLiitetyiksiSivuiksi(tositeAvain, kirjattavaRaahattu)
      return this._kirjanpitoImageHandlerService.muunnaTositteiksiJaSivuiksi(asiakas, kirjaukseenLiitetytSivut, [], [], true)
    }

  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
