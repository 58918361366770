import { KopioijaPalvelu } from '../../../_jaettu/service/kopioija.service'
import { Asiakas, AsiakkaanKirjanpidonTyyppi, LemonatorPaymentConfig } from '../../../_jaettu-lemonator/model/asiakas'
import { TimestampService } from '../../../_jaettu-angular/service/timestamp-service'
import { LaskunTyypit } from '../../../_jaettu/model/lasku'
import { AsiakkaanKirjanpidonPeruste, Yritysmuoto } from 'app/_jaettu/model/kayttaja'
import { Injectable } from '@angular/core'

export const EI_VIELA_NUMEROA_ASIAKASNUMERO = -1
@Injectable()
export class AsiakasKopioija {

  constructor(
    private timestampService: TimestampService,
    private kopioijaPalvelu: KopioijaPalvelu
  ) {

  }

  public annaUusiAsiakas(): Asiakas {
    return {
      asiakasId: EI_VIELA_NUMEROA_ASIAKASNUMERO,
      avain: null,
      nimi: '',
      luotu: this.timestampService.now(),
      luoja: '',
      paivitetty: this.timestampService.now(),
      paivittaja: '',
      poistettu: false,
      ytunnus: '',
      kasittelija: '',
      kasittelijavara: '',
      omaverosaldo: {
        aikaleima: null,
        saldo: null
      },
      synkkaustyot: {},
      katuosoite: '',
      maa: 'FIN',
      postinro: '',
      postitmp: '',
      laskunKieli: 'fi',
      laskunTyyppi: LaskunTyypit.TAVALLINEN.avain,
      laskunValuutta: 'EUR',
      laskunVastaanottajat: [],
      viitenumero: null,
      hinnat: [],
      hinnanPerusteet: '',
      sopimuskaudet: [],
      kkruutujenKaudet: [],
      kirjanpitajat: [],
      kirjanpitajatvara: [],
      alvIlmoitusjaksot: [],
      tilikaudet: [],
      tilinumerot: [],
      yritysmuoto: Yritysmuoto.TOIMINIMI,
      muistiinpanot: null,
      muistiinpanojenVari: null,
      kirjanpidonMuistiinpanot: null,
      kirjanpitoraporttienLahetystiedot: null,
      kayttoehtojaHyvaksymatta: true,
      sopimuksenHyvaksyntaStatus: 'ei-hyvaksytty',
      tuntemistietojenStatus: 'ei-annettu',
      suomiFiValtuutusPuuttuu: false,
      onkoPalaveriPidetty: false,
      palaveriTyyppi: null,
      palaveriPidettyPvm: null,
      sahkoinenLaskutusosoite: {
        sahkoinenOsoite: null,
        sahkoinenValittaja: null
      },
      areAllAccessibleAccountsInMaksutavat: null,
      areAllBankAccountsAccessible: null,
      uudessaKirjanpitoAppissaAlkaen: null,
      uudessaListassa: false,
      ytjDataLastSynced: this.timestampService.lisaaPaiviaTimestamp(this.timestampService.now(), -10 * 365), // 10 years in the past
      omaveronAlvKausi: null,
      ytjTiedotMuuttuneet: false
    }
  }

  public kopioiAsiakas(kopioitava: Asiakas): Asiakas {
    const asiakas: Asiakas = {
      asiakasId: this.kopioijaPalvelu.cloneDeep(kopioitava.asiakasId),
      avain: this.kopioijaPalvelu.cloneDeep(kopioitava.avain),
      nimi: this.kopioijaPalvelu.cloneDeep(kopioitava.nimi),
      luotu: this.kopioijaPalvelu.cloneDeep(kopioitava.luotu),
      luoja: this.kopioijaPalvelu.cloneDeep(kopioitava.luoja),
      paivitetty: this.kopioijaPalvelu.cloneDeep(kopioitava.paivitetty),
      paivittaja: this.kopioijaPalvelu.cloneDeep(kopioitava.paivittaja),
      poistettu: this.kopioijaPalvelu.cloneDeep(kopioitava.poistettu),
      ytunnus: this.kopioijaPalvelu.cloneDeep(kopioitava.ytunnus),
      kasittelija: this.kopioijaPalvelu.cloneDeep(kopioitava.kasittelija),
      kasittelijavara: this.kopioijaPalvelu.cloneDeep(kopioitava.kasittelijavara),
      omaverosaldo: this.kopioijaPalvelu.cloneDeep(kopioitava.omaverosaldo),
      synkkaustyot: this.kopioijaPalvelu.cloneDeep(kopioitava.synkkaustyot),
      katuosoite: this.kopioijaPalvelu.cloneDeep(kopioitava.katuosoite),
      maa: this.kopioijaPalvelu.cloneDeep(kopioitava.maa),
      postinro: this.kopioijaPalvelu.cloneDeep(kopioitava.postinro),
      postitmp: this.kopioijaPalvelu.cloneDeep(kopioitava.postitmp),
      laskunKieli: this.kopioijaPalvelu.cloneDeep(kopioitava.laskunKieli),
      laskunTyyppi: this.kopioijaPalvelu.cloneDeep(kopioitava.laskunTyyppi),
      laskunValuutta: this.kopioijaPalvelu.cloneDeep(kopioitava.laskunValuutta),
      laskunVastaanottajat: this.kopioijaPalvelu.cloneDeep(kopioitava.laskunVastaanottajat),
      viitenumero: this.kopioijaPalvelu.cloneDeep(kopioitava.viitenumero),
      hinnat: this.kopioijaPalvelu.cloneDeep(kopioitava.hinnat),
      sopimuskaudet: this.kopioijaPalvelu.cloneDeep(kopioitava.sopimuskaudet),
      kkruutujenKaudet: this.kopioijaPalvelu.cloneDeep(kopioitava.kkruutujenKaudet),
      kirjanpitajat: this.kopioijaPalvelu.cloneDeep(kopioitava.kirjanpitajat),
      kirjanpitajatvara: this.kopioijaPalvelu.cloneDeep(kopioitava.kirjanpitajatvara),
      alvIlmoitusjaksot: this.kopioijaPalvelu.cloneDeep(kopioitava.alvIlmoitusjaksot),
      tilikaudet: this.kopioijaPalvelu.cloneDeep(kopioitava.tilikaudet),
      tilinumerot: this.kopioijaPalvelu.cloneDeep(kopioitava.tilinumerot),
      yritysmuoto: this.kopioijaPalvelu.cloneDeep(kopioitava.yritysmuoto),
      muistiinpanot: this.kopioijaPalvelu.cloneDeep(kopioitava.muistiinpanot),
      muistiinpanojenVari: this.kopioijaPalvelu.cloneDeep(kopioitava.muistiinpanojenVari),
      kirjanpidonMuistiinpanot: this.kopioijaPalvelu.cloneDeep(kopioitava.kirjanpidonMuistiinpanot),
      kirjanpitoraporttienLahetystiedot: this.kopioijaPalvelu.cloneDeep(kopioitava.kirjanpitoraporttienLahetystiedot),
      kayttoehtojaHyvaksymatta: this.kopioijaPalvelu.cloneDeep(kopioitava.kayttoehtojaHyvaksymatta),
      sopimuksenHyvaksyntaStatus: this.kopioijaPalvelu.cloneDeep(kopioitava.sopimuksenHyvaksyntaStatus),
      suomiFiValtuutusPuuttuu: this.kopioijaPalvelu.cloneDeep(kopioitava.suomiFiValtuutusPuuttuu),
      tuntemistietojenStatus: this.kopioijaPalvelu.cloneDeep(kopioitava.tuntemistietojenStatus),
      onkoPalaveriPidetty: this.kopioijaPalvelu.cloneDeep(kopioitava.onkoPalaveriPidetty),
      palaveriTyyppi: this.kopioijaPalvelu.cloneDeep(kopioitava.palaveriTyyppi),
      palaveriPidettyPvm: this.kopioijaPalvelu.cloneDeep(kopioitava.palaveriPidettyPvm),
      sahkoinenLaskutusosoite: this.kopioijaPalvelu.cloneDeep(kopioitava.sahkoinenLaskutusosoite),
      areAllAccessibleAccountsInMaksutavat: this.kopioijaPalvelu.cloneDeep(kopioitava.areAllAccessibleAccountsInMaksutavat),
      areAllBankAccountsAccessible: this.kopioijaPalvelu.cloneDeep(kopioitava.areAllBankAccountsAccessible),
      uudessaKirjanpitoAppissaAlkaen: this.kopioijaPalvelu.cloneDeep(kopioitava.uudessaKirjanpitoAppissaAlkaen),
      uudessaListassa: this.kopioijaPalvelu.cloneDeep(kopioitava.uudessaListassa),
      ytjDataLastSynced: this.kopioijaPalvelu.cloneDeep(kopioitava.ytjDataLastSynced),
      omaveronAlvKausi: this.kopioijaPalvelu.cloneDeep(kopioitava.omaveronAlvKausi),
      ytjTiedotMuuttuneet: this.kopioijaPalvelu.cloneDeep(kopioitava.ytjTiedotMuuttuneet)
    }

    if (kopioitava.ytjDataLastSynced) {
      asiakas.ytjDataLastSynced = kopioitava.ytjDataLastSynced
    }
    if (kopioitava.ytjIntegrationLastError) {
      asiakas.ytjIntegrationLastError = kopioitava.ytjIntegrationLastError
    }
    if (kopioitava.ytjIntegrationLastSuccessSync) {
      asiakas.ytjIntegrationLastSuccessSync = kopioitava.ytjIntegrationLastSuccessSync
    }
    if (kopioitava.viitenumerotPaivitettyOmaverosta) {
      asiakas.viitenumerotPaivitettyOmaverosta = kopioitava.viitenumerotPaivitettyOmaverosta
    }
    if (kopioitava.ossRekisterissa) {
      asiakas.ossRekisterissa = 1
    }
    if (kopioitava.kasittelePalkatAlvittomana) {
      asiakas.kasittelePalkatAlvittomana = 1
    }
    if (kopioitava.testiasiakas) {
      asiakas.testiasiakas = 1
    }
    if (kopioitava.tilinpaatosRekisteroidaanBruttotuloslaskelmalla) {
      asiakas.tilinpaatosRekisteroidaanBruttotuloslaskelmalla = 1
    }
    if (kopioitava.saleDoneBy) {
      asiakas.saleDoneBy = kopioitava.saleDoneBy
    }
    if (kopioitava.estaAlvVerkkolaskujenLahetys) {
      asiakas.estaAlvVerkkolaskujenLahetys = 1
    }
    if (kopioitava.kirjanpitoviestienLisavastaanottajat) {
      asiakas.kirjanpitoviestienLisavastaanottajat = this.kopioijaPalvelu.cloneArrayDeep(kopioitava.kirjanpitoviestienLisavastaanottajat)
    }
    if (kopioitava.hinnanPerusteet) {
      asiakas.hinnanPerusteet = this.kopioijaPalvelu.cloneDeep(kopioitava.hinnanPerusteet)
    }
    if (kopioitava.paymentsNumberOfApprovalsRequired !== null && kopioitava.paymentsNumberOfApprovalsRequired !== undefined) {
      asiakas.paymentsNumberOfApprovalsRequired = kopioitava.paymentsNumberOfApprovalsRequired
    }
    if (kopioitava.paymentConfigs) {
      const copiedConfigs: LemonatorPaymentConfig[] = []
      for (const configToCopy of kopioitava.paymentConfigs) {
        const copiedConfig: LemonatorPaymentConfig = {
          avain: configToCopy.avain,
          start: configToCopy.start,
          end: configToCopy.end,
          iban: configToCopy.iban,
          bank: configToCopy.bank,
          debtorIdentifier: configToCopy.debtorIdentifier,
          type: configToCopy.type
        }
        copiedConfigs.push(copiedConfig)
      }
      asiakas.paymentConfigs = copiedConfigs
    }
    if (kopioitava.kirjanpidonPerusteet) {
      const copiedPerusteet: AsiakkaanKirjanpidonPeruste[] = []
      for (const perusteToCopy of kopioitava.kirjanpidonPerusteet) {
        const copiedPeruste: AsiakkaanKirjanpidonPeruste = {
          alkaa: perusteToCopy.alkaa,
          loppuu: perusteToCopy.loppuu,
          peruste: perusteToCopy.peruste
        }
        copiedPerusteet.push(copiedPeruste)
      }
      asiakas.kirjanpidonPerusteet = copiedPerusteet
    }
    if (kopioitava.kirjanpidonTyypit) {
      const copiedTyypit: AsiakkaanKirjanpidonTyyppi[] = []
      for (const perusteToCopy of kopioitava.kirjanpidonTyypit) {
        const copiedTyyppi: AsiakkaanKirjanpidonTyyppi = {
          alkaa: perusteToCopy.alkaa,
          loppuu: perusteToCopy.loppuu,
          tyyppi: perusteToCopy.tyyppi
        }
        copiedTyypit.push(copiedTyyppi)
      }
      asiakas.kirjanpidonTyypit = copiedTyypit
    }

    return asiakas
  }

}
