<div class="detail-page">

  <app-asiakas-otsikko otsikko="Tositteen tiedot" [naytaPalaa]="true" [paluuUrl]="paluuUrl" [asiakas]="asiakas"></app-asiakas-otsikko>

  <div *ngIf="naytaDebug" class="light-gray-text" style="margin-bottom: 2em;">
    Kuitin tekstuaalinen data Firestore: <a [href]="kuittiDataFirestoreUrl" target="_BLANK">{{kuittiDataFirestoreUri}}</a>
    <br>Kuitin tekstuaalinen data RealtimeDb: <a [href]="kuittiDataRealtimeDbUrl" target="_BLANK">{{kuittiDataRealtimeDbUri}}</a>
    <br>Kuitin binääridata Firebase: <a [href]="binaryDataUrlFirebase" target="_BLANK">Avaa</a>
    <br>Kuitin binääridata Google Cloud: <a [href]="binaryDataUrlGCP" target="_BLANK">Avaa</a>

    <br><br>Mahdollinen kirjattava kuitti: <a [href]="kirjattavaKuittiDataFirestoreUrl" target="_BLANK">{{kirjattavaKuittiDataFirestoreUri}}</a>

    <div style="margin: 10px 5px;">
      <button mat-button class="secondarybutton" (click)="reloadTosite()">Käynnistä tositteen tuonti Lemonaidista uudelleen</button>
    </div>
  </div>

  <div class="otsake-container">

    <div class="otsake">
      <div>
        <span>{{'kuitit.katsele.tosite' | translate | async}}</span>
        <h2 class="light-blue-text">{{otsakeObservable | async}}</h2>
      </div>
      <!-- <div *ngIf="naytaTavallinenOtsake()">
        <span>{{'lasku.katsele-muokattava-dialog.lasku' | translate | async}}</span>
        <h2 class="light-blue-text">{{laskuSharedService.annaMuotoiltuLaskunumero(kasiteltava)}} {{kasiteltava?.asiakas?.nimi}}</h2>
      </div>
      <div *ngIf="naytaMaksuaikaa()">
        <span>{{'lasku.katsele-muokattava-dialog.maksuaikaa' | translate | async}}</span>
        <h2 [class.light-blue-text]="annaMaksuaikaa() > -1" [class.red-text]="annaMaksuaikaa() < 0">{{annaMaksuaikaa()}} pv</h2>
      </div> -->
      <div>
        <span>{{'kuitit.katsele.summa' | translate | async}}</span>
        <h2 class="blue-text">{{tosite?.summa | lmoney: 'EUR' | async}}</h2>
      </div>
    </div>

  </div>

  <!-- <div class="nappula-container">
    <div class="nappulat">
      <button mat-button class="secondarybutton" (click)="lataaKuitti()">
        <span>{{ 'kuitit.katsele.lataa' | translate | async }}</span>
      </button>
    </div>
  </div> -->

  <div>{{tosite?.selite}}</div>
  <div *ngIf="tosite.userApiMessage" class="tosite-api-msg" style="margin-top: 1em;">{{tosite.userApiMessage}}</div>
  <!-- <div *ngIf="tosite?.alvVahennysoikeus !== null">{{'kuitit.katsele.alv' | translate | async}} {{ tosite?.alvVahennysoikeus }} %</div> -->

  <div *ngIf="naytaDebug && alkuperaiset" class="alkuperaiset-container light-gray-text">
    <h3 style="margin: 2em 0 0 0;">Alkuperäiset tiedostot</h3>
    <div *ngFor="let alkuperainen of alkuperaiset" style="margin-bottom: 1em;">
      {{alkuperainen.nimi}}
      <button mat-button class="secondarybutton" (click)="lataaAlkuperainen(alkuperainen)">
        <span>Lataa tiedosto</span>
      </button>
    </div>
  </div>

  <div class="tosite-sivut">
    <div *ngFor="let kuva of kuvat" [class.poistettu-kuva]="kuva.poistettu">
      <!-- <ng-container *ngIf="!kuva.poistettu || naytaDebug"></ng-container> -->
      <div class="kuva-container" style="position: relative; width: 100%; min-height: 196px; margin-top: 2em;">
        <img [src]="kuva.safeUrl" (load)="kuvanLataaminenValmis(kuva)" crossorigin="use-credentials" loading="lazy" class="mat-elevation-z4" style="max-width: 100%; margin-top: 15px;" />
        <div lemon-loading [show]="kuva.ladataan"></div>
      </div>
    </div>
  </div>

</div>