import { Component, ChangeDetectionStrategy, Input } from '@angular/core'
import { Kirjanpitotili, RaporttiAlvLaskelmaRow, RaporttiBruttoTuloslaskelmarivi, RaporttiOssCountryRow, RaporttiPaakirjaAccountRow, RaporttiTaselaskelmaAccountRow, RaporttiTuloslaskelmaAccountRow, RaporttiVirallinenTaselaskelmarivi, RaporttiVirallinenTuloslaskelmarivi } from 'app/_jaettu-lemonator/model/kirjanpito'
import { Observable } from 'rxjs'

type TiliRow =
  RaporttiVirallinenTaselaskelmarivi | // TODO: Rivi ei sisällä tilin numeroa, 'a', tarvitaanko ollenkaan tunnistetta?
  RaporttiPaakirjaAccountRow |
  RaporttiOssCountryRow |
  RaporttiBruttoTuloslaskelmarivi | // TODO: Rivi ei sisällä tilin numeroa, 'a', tarvitaanko ollenkaan tunnistetta?
  RaporttiVirallinenTuloslaskelmarivi | // TODO: Rivi ei sisällä tilin numeroa, 'a', tarvitaanko ollenkaan tunnistetta?
  RaporttiTuloslaskelmaAccountRow |
  RaporttiTaselaskelmaAccountRow |
  RaporttiAlvLaskelmaRow

// TODO: Mieti, onko järkevämpää tuottaa tieto jo backendissa (esim. asiakaskohtainen tilin tyyppi) tai vaihtoehtoisesti raporttikomponenteissa rivilistauksien muodostuksessa
@Component({
  selector: 'kirjanpito-raportit-tilirivin-tunniste',
  templateUrl: './kirjanpito-raportit-tilirivin-tunniste.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KirjanpitoRaportitTilirivinTunniste {
  @Input() row: TiliRow
  @Input() tilitMapObservable: Observable<Map<string, Kirjanpitotili>>
}