import { Component, ErrorHandler, EventEmitter, OnInit, Output } from '@angular/core'
import { CdkDragDrop } from '@angular/cdk/drag-drop'

import { MatDialog } from '@angular/material/dialog'

import { BehaviorSubject, Observable, combineLatest, firstValueFrom } from 'rxjs'
import { map, take, tap } from 'rxjs/operators'

import { TilikarttaService } from 'app/_angular/service/tilikartta.service'
import { Kirjanpitotili } from 'app/_jaettu-lemonator/model/kirjanpito'
import { AsiakkaanKirjanpitotiliMuokkaaDialog, AsiakkaanKirjanpitotiliMuokkaaDialogData } from './kirjanpitotili-muokkaa.dialog'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { AsiakkaanKirjanpitotiliMuokkaaPaatilikartanTiliaDialog, AsiakkaanKirjanpitotiliMuokkaaPaatilikartanTiliaDialogData } from './kirjanpitotili-muokkaa-paatilikartan-tilia.dialog'
import { HierarkiaKirjanpitotili } from 'app/_jaettu-lemonator/service/tilikartta-jaettu.service'
import { TuettuKieli } from 'app/_shared-core/model/common'
import { MatSelectChange } from '@angular/material/select'

/** Flat node with expandable and level information */
export interface FlatKirjanpitotili {
  expandable: boolean
  name: string
  level: number
  kirjanpitotili: Kirjanpitotili
}

@Component({
  selector: '[asiakkaan-tilikartta-edit]',
  templateUrl: './asiakkaan-tilikartta-edit.component.html',
  styleUrls: ['./asiakkaan-tilikartta-edit.component.css']
})
export class AsiakkaanTilikarttaEditComponent implements OnInit {

  @Output() tilikarttaaMuokattiin: EventEmitter<boolean> = new EventEmitter<boolean>()

  loading = true
  commonError = ''
  flatatyt: Observable<FlatKirjanpitotili[]>

  valittuKieliSubject: BehaviorSubject<TuettuKieli> = new BehaviorSubject('fi')

  constructor(
    private _errorHandler: ErrorHandler,
    private _dialog: MatDialog,
    private _tilikarttaService: TilikarttaService,
    private _asiakasService: AsiakasService
  ) { }

  ngOnInit(): void {
    this.flatatyt = combineLatest([this._tilikarttaService.nykyisenAsiakkaanTilikartanTilihierarkiaObservable, this.valittuKieliSubject]).pipe(
      map(([hierarkia, kieli]) => {
        const kaikki: FlatKirjanpitotili[] = []
        for (const juuri of hierarkia) {
          if (juuri.kirjanpitotili.aktiivinen) {
            this.flattaa(juuri, 1, kaikki, kieli)
          }
        }
        return kaikki
      }),
      tap(() => {
        this.loading = false
      })
    )
  }

  kieliMuuttui(change: MatSelectChange) {
    this.valittuKieliSubject.next(change.value)
  }

  private flattaa(hierarkia: HierarkiaKirjanpitotili, level: number, kaikki: FlatKirjanpitotili[], kieli: TuettuKieli) {
    kaikki.push(this.makeFlat(hierarkia, level, kieli))
    if (hierarkia.lapset) {
      for (const lapsi of hierarkia.lapset) {
        if (lapsi.kirjanpitotili.aktiivinen) {
          this.flattaa(lapsi, level + 1, kaikki, kieli)
        }
      }
    }
  }

  private makeFlat(node: HierarkiaKirjanpitotili, level: number, kieli: TuettuKieli): FlatKirjanpitotili {
    const nimi = this._annaNimi(node, kieli)
    return {
      expandable: !!node.lapset && node.lapset.length > 0,
      name: node.kirjanpitotili.numero + ' ' + nimi,
      level: level,
      kirjanpitotili: node.kirjanpitotili
    }
  }

  private _annaNimi(node: HierarkiaKirjanpitotili, kieli: TuettuKieli): string {
    if (node.kirjanpitotili.localisedName) {
      if (node.kirjanpitotili.localisedName[kieli]) {
        return node.kirjanpitotili.localisedName[kieli]
      }
    }
    return node.kirjanpitotili.nimi
  }

  async muokkaa(tili: Kirjanpitotili) {

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasObservable)
    const tilit = await firstValueFrom(this.flatatyt)

    if (asiakas) {
      if (tili.numero.length > 0 && tili.numero.length < 5) {
        const data: AsiakkaanKirjanpitotiliMuokkaaPaatilikartanTiliaDialogData = {
          asiakas: asiakas,
          lisaa: false,
          tili: tili,
          tilit: tilit
        }
        this._dialog.open<AsiakkaanKirjanpitotiliMuokkaaPaatilikartanTiliaDialog, AsiakkaanKirjanpitotiliMuokkaaPaatilikartanTiliaDialogData, boolean>(AsiakkaanKirjanpitotiliMuokkaaPaatilikartanTiliaDialog, { data: data, autoFocus: false })
          .afterClosed()
          .pipe(
            take(1)
          ).subscribe(tiliaMuokattiin => {
            if (tiliaMuokattiin) {
              this.tilikarttaaMuokattiin.emit(true)
            }
          })
      } else if (tili.numero.length === 5) {
        const data: AsiakkaanKirjanpitotiliMuokkaaDialogData = {
          asiakas: asiakas,
          lisaa: false,
          tili: tili,
          tilit: tilit
        }
        this._dialog.open<AsiakkaanKirjanpitotiliMuokkaaDialog, AsiakkaanKirjanpitotiliMuokkaaDialogData, boolean>(AsiakkaanKirjanpitotiliMuokkaaDialog, { data: data, autoFocus: false })
          .afterClosed()
          .pipe(
            take(1)
          ).subscribe(tiliaMuokattiin => {
            if (tiliaMuokattiin) {
              this.tilikarttaaMuokattiin.emit(true)
            }
          })
      }
    }

  }

  async lisaa() {
    const tilit = await firstValueFrom(this.flatatyt)

    this._asiakasService.nykyinenAsiakasObservable.pipe(
      take(1)
    ).subscribe(asiakas => {
      if (asiakas) {
        const data: AsiakkaanKirjanpitotiliMuokkaaDialogData = {
          asiakas: asiakas,
          lisaa: true,
          tili: {
            aktiivinen: true,
            alvTyyppi: null,
            nimi: null,
            numero: null,
            oletusAlvKasittely: null,
            vanhempi: null,
            localisedName: {} as any
          },
          tilit: tilit
        }
        this._dialog.open<AsiakkaanKirjanpitotiliMuokkaaDialog, AsiakkaanKirjanpitotiliMuokkaaDialogData, boolean>(AsiakkaanKirjanpitotiliMuokkaaDialog, { data: data, autoFocus: false })
          .afterClosed()
          .pipe(
            take(1)
          ).subscribe(tiliaMuokattiin => {
            if (tiliaMuokattiin) {
              this.tilikarttaaMuokattiin.emit(true)
            }
          })
      }
    })
  }

  drop(event: CdkDragDrop<FlatKirjanpitotili>) {

    if (event.currentIndex === event.previousIndex) {
      return
    }
    combineLatest([this.flatatyt, this._tilikarttaService.nykyisenAsiakkaanTilikartanTilihierarkiaObservable, this._asiakasService.nykyinenAsiakasAvainObservable]).pipe(
      take(1)
    ).subscribe(([flatatyt, hierarkia, asiakas]) => {

      if (!flatatyt || !hierarkia || !asiakas) {
        return
      }

      // Hae flatatyista
      const raahattu = flatatyt[event.previousIndex]

      // Vain asiakaskohtaisia voi dragata
      if (raahattu.kirjanpitotili.numero.length !== 5) {
        return
      }

      const uusiIsanta = flatatyt[event.currentIndex]

      // Hae raahattu hierarkiasta
      const raahattuHierarkiassa = this._tilikarttaService.etsiTiliHierarkiasta(raahattu.kirjanpitotili.numero, hierarkia)
      if (!raahattuHierarkiassa) {
        return
      }

      // Tarkista, ettei uusi isäntä ole jokin raahatun lapsista
      const uusiIsantaRaahatunLapsissa: HierarkiaKirjanpitotili = this._tilikarttaService.etsiTiliHierarkiasta(uusiIsanta.kirjanpitotili.numero, [raahattuHierarkiassa])
      if (uusiIsantaRaahatunLapsissa) {
        return
      }

      // Hae uusi isäntä
      let uusiVanhempi = uusiIsanta.kirjanpitotili.numero.length === 4 ? uusiIsanta.kirjanpitotili.numero : null
      if (!uusiVanhempi && event.currentIndex > 0) {
        const edellinen = flatatyt[event.currentIndex - 1]
        uusiVanhempi = edellinen.kirjanpitotili.numero.length === 4 ? edellinen.kirjanpitotili.numero : null
      }
      if (!uusiVanhempi && event.currentIndex < flatatyt.length - 1) {
        const seuraava = flatatyt[event.currentIndex + 1]
        uusiVanhempi = seuraava.kirjanpitotili.numero.length === 4 ? seuraava.kirjanpitotili.numero : null
      }
      if (!uusiVanhempi) {
        const vanhempi = this._tilikarttaService.etsiTiliHierarkiasta(uusiIsanta.kirjanpitotili.vanhempi, hierarkia)
        uusiVanhempi = vanhempi.kirjanpitotili.numero.length === 4 ? vanhempi.kirjanpitotili.numero : null
      }

      if (!uusiVanhempi) {
        return
      }

      raahattu.kirjanpitotili.vanhempi = uusiVanhempi
      this._tilikarttaService.tallennaAsiakkaanTilikartanTili(asiakas, raahattu.kirjanpitotili).catch(error => {
        this.commonError = 'Tallentamisen aikana tapahtui virhe. Ole hyvä ja ilmoita tästä ylläpitäjälle.'
        this._errorHandler.handleError(error)
      })
    })
  }

}
