<div class="list-page">

  <app-asiakas-otsikko otsikko="Pankkiyhteydet" paluuUrl="/asiakkaat" [asiakas]="asiakas"></app-asiakas-otsikko>

  <mat-tab-group>
    <mat-tab label="Valtuutukset">

      <div class="wrapper">

        <div style="padding-top: 20px;" class="ng-star-inserted" *ngIf="kirjanpitajaOnDevaajaObservable | async">
          <h3 style="margin: 0;">Urit</h3>
          <div style="padding-top: 1em; padding-left: 1em; padding-right: 1em;">Valtuutuksen data: <a [href]="consentUrlObservable | async" target="_blank">{{consentUriObservable | async}}</a></div>
        </div>

        <div class="title">Asiakkaan valtuutus</div>

        <div *ngIf="consentHtmlObservable | async as consent; else loadingConsent" [innerHTML]="consent">
        </div>
        <ng-template #loadingConsent>
          Valtuutusta ladataan...
        </ng-template>

        <ng-container *ngIf="historianMaaraObservable | async; let historianMaara">
          <div class="title">Valtuutuksen historia ({{historianMaara}} viimeistä)</div>
          <mat-radio-group (change)="historianMaaraChanged($event.value)" name="amnt" [value]="'' + historianMaara">
            <mat-radio-button value="50">50</mat-radio-button>
            <mat-radio-button value="100">100</mat-radio-button>
            <mat-radio-button value="500">500</mat-radio-button>
            <mat-radio-button value="1000">1000</mat-radio-button>
          </mat-radio-group>
        </ng-container>

        <table>
          <tr *ngFor="let historia of logsObservable | async" style="border-bottom: 1px solid gray;">
            <td>{{ historia.source }}</td>
            <td>{{ historia.changedTime | ltimestamptimel: 'fi'}}</td>
            <td>{{ historia.changedReason }}</td>
            <td>{{ historia.details }}</td>
          </tr>
        </table>
      </div>


    </mat-tab>
    <mat-tab label="Hae tapahtumat Holvista">
      <div app-holvi-transactions></div>
    </mat-tab>
    <mat-tab label="Hae tapahtumat Aiiasta">
      <div app-aiia-tilitapahtumat></div>
    </mat-tab>
    <mat-tab label="Aiia työt">
      <div app-aiia-tyohistoriat></div>
    </mat-tab>
  </mat-tab-group>

</div>