import { Component, OnInit, OnDestroy, ErrorHandler, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'

import { MatInput } from '@angular/material/input'

import { FormValidationService, FieldErrors } from '../_jaettu-angular/service/form-validation.service'
import { LadataanService } from '../_jaettu-angular/service/ladataan.service'

import { BehaviorSubject, Subject, takeUntil, takeWhile } from 'rxjs'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { AlvIlmoitusjakso, AsiakashakuAsiakas, AsiakashakuRequest, AsiakashakuResponse, EiYhtiomuotoaYhtiomuoto } from 'app/_jaettu-lemonator/model/asiakas'
import { Yritysmuoto } from 'app/_jaettu/model/kayttaja'
import { AsiakasService, LokalisoituAlvIlmoitusjakso, LokalisoituYritysmuoto } from 'app/_angular/service/asiakas/asiakas.service'
import { KirjanpitajanNimitiedot } from 'app/_jaettu-lemonator/model/kirjanpitaja'
import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { KirjanpitajanTiimiService } from 'app/_jaettu-lemonator/service/kirjanpitajan-tiimi.service'
import { DateService } from 'app/_shared-core/service/date.service'
import { KirjanpitajanRooli } from 'app/_jaettu/lemonator/model/kirjanpitaja'
import { CurrencyService } from 'app/_shared-core/service/currency.service'

interface AsiakashakuAsiakasExpanded extends AsiakashakuAsiakas {
  kirjanpitajanNimi?: string
}
@Component({
  templateUrl: './asiakashaku.component.html',
  styleUrls: ['./asiakashaku.component.css']
})
export class AsiakashakuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatInput, { static: true }) hakuInput: MatInput

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  hakuControl: FormControl<string> = new FormControl('')
  yhtiomuotoControl: FormControl<Yritysmuoto | EiYhtiomuotoaYhtiomuoto> = new FormControl(null)
  alvjaksoControl: FormControl<AlvIlmoitusjakso> = new FormControl(null)
  kirjanpitajaControl: FormControl<KirjanpitajanNimitiedot> = new FormControl(null)

  kirjanpitajienNimitiedot: KirjanpitajanNimitiedot[]
  tulokset: BehaviorSubject<AsiakashakuAsiakasExpanded[]> = new BehaviorSubject(null)

  form: FormGroup
  fieldErrors: FieldErrors = {}
  inputname = 'asdfewqrphoki' + Math.random()
  paivitaValidaatiot: any = null

  yritysmuodot: LokalisoituYritysmuoto[]
  alvjaksot: LokalisoituAlvIlmoitusjakso[]

  isDev: boolean
  isSuperUser: boolean

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _validationService: FormValidationService,
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _asiakasService: AsiakasService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _firebase: FirebaseLemonator,
    private _kirjanpitajanTiimiService: KirjanpitajanTiimiService,
    private _dateService: DateService,
    private _currencyService: CurrencyService
  ) {

    this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(async kirjanpitaja => {
      if (!kirjanpitaja) {
        return
      }
      this.isSuperUser = kirjanpitaja.rooli === KirjanpitajanRooli.SUPER

      const tiimi = await this._kirjanpitajanTiimiService.annaKirjanpitajanTiimi(kirjanpitaja.uid, this._dateService.currentNumberDate())
      this.isDev = tiimi?.avain === 'IT'
    })

    this.alvjaksot = this._asiakasService.alvIlmoitusjaksot
    this.yritysmuodot = this._asiakasService.yritysmuodotJaEiTiedossa

    this._kirjanpitajaService.kirjanpitajienNimitiedotObservable.pipe(
      takeWhile(nimitiedot => !nimitiedot?.length, true)
    ).subscribe(nimitiedot => {
      nimitiedot.sort((a, b) => a.etunimi.localeCompare(b.etunimi))

      this.kirjanpitajienNimitiedot = nimitiedot
    })
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  ngOnInit() {
    this.form = new FormGroup({
      'haku': this.hakuControl,
      'yhtiomuoto': this.yhtiomuotoControl,
      'alvjakso': this.alvjaksoControl,
      'kirjanpitaja': this.kirjanpitajaControl
    })
  }

  ngAfterViewInit(): void {
    // Activate the search input by default
    setTimeout(() => {
      this.hakuInput.focus()
      this._changeDetectorRef.markForCheck()
      this._asiakasService.asetaNykyinenAsiakas(null)
    }, 10)
  }

  // private ibanValidator: ValidatorFn = (c: AbstractControl): ValidationErrors | null => {
  //   // console.log(c.value, this.ibanService.isValidIban(c.value))
  //   if (c && c.value && !this._ibanService.isValidIban(c.value)) {
  //     return { 'iban': true }
  //   }
  //   return null
  // }

  // private bicValidator: ValidatorFn = (c: AbstractControl): ValidationErrors | null => {
  //   // console.log(c.value, this.ibanService.isValidIban(c.value))
  //   if (c && c.value && !this.bicService.validoiBic(c.value)) {
  //     return { 'bic': true }
  //   }
  //   return null
  // }

  async search() {

    if (this.form.invalid || !this.form.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.form)
      this.paivitaValidaatiot = {}
      return
    }

    this._ladataanService.aloitaLataaminen()

    try {
      const req: AsiakashakuRequest = {}
      if (this.hakuControl.value) { req.teksti = this.hakuControl.value }
      if (this.yhtiomuotoControl.value) { req.yhtiomuoto = this.yhtiomuotoControl.value }
      if (this.alvjaksoControl.value) { req.alvjakso = this.alvjaksoControl.value }
      if (this.kirjanpitajaControl.value) { req.kirjanpitaja = this.kirjanpitajaControl.value.avain }
      const resp = await this._firebase.functionsCall<AsiakashakuRequest, AsiakashakuResponse>('asiakasHaeAsiakasta', req, { timeout: 540 * 1000 })
      if (resp.e || !resp.asiakkaat) {
        throw new Error('Error: ' + resp.e)
      }
      if (this.isDev && this._currencyService.onkoMerkkijonoKokonaisluku(req.teksti)) {
        resp.asiakkaat.sort((a, b) => a.i - b.i)
      } else {
        resp.asiakkaat.sort((a, b) => {
          const lowera = a.n.toLowerCase()
          const lowerb = b.n.toLowerCase()
          if (lowera !== lowerb) {
            return lowera.localeCompare(lowerb)
          }
          return a.y.localeCompare(b.y)
        })
      }
      if (this.isSuperUser) {
        const asiakkaatPlusKirjanpitajanNimi = resp.asiakkaat.map(as => {
          const kirjanpitaja = this.kirjanpitajienNimitiedot.find(kn => kn.avain === as.v)

          return Object.assign({ kirjanpitajanNimi: kirjanpitaja ? kirjanpitaja.etunimi + ' ' + kirjanpitaja.sukunimi : 'Ei kirjanpitäjää' }, as)
        })
        this.tulokset.next(asiakkaatPlusKirjanpitajanNimi)
      } else {
        this.tulokset.next(resp.asiakkaat)
      }
    } catch (err) {
      this._errorHandler.handleError(err)
    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

  get haku(): FormControl<string> {
    return this.hakuControl
  }

}
