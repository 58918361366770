<div *ngIf="kirjanpitajaOnDevaajaObservable | async" style="padding-bottom: 20px;">
  <h3 style="margin: 0;">Urit</h3>
  <div style="padding-top: 1em; padding-left: 1em; padding-right: 1em;">Käyttäjän tallentamat tiedot: <a [href]="erittelyEncodedUriObservable | async" target="_BLANK">{{erittelyUriObservable | async}}</a></div>
</div>

<div style="display: flex; justify-content: space-between;">
  <button mat-icon-button type="button" (click)="downloadPdf()">
    <div class="pdf-btn">
      <div>PDF</div>
      <mat-icon style="font-size: 14px; height: 15px; line-height: 15px;">save_alt</mat-icon>
    </div>
  </button>
  <form [formGroup]="form">
    <div style="text-align: right;">
      Tee erittely päivälle
      <mat-form-field style="max-width: 110px;">
        <input style="text-align: center;" name="loppuu" type="text" [matDatepicker]="loppuuPicker" [min]="minLoppuuDateObservable | async" [max]="maxLoppuuDateObservable | async" formControlName="loppuu" placeholder="Päivä" matInput data-lpignore="true" />
        <mat-datepicker-toggle matSuffix [for]="loppuuPicker"></mat-datepicker-toggle>
        <mat-datepicker #loppuuPicker></mat-datepicker>
      </mat-form-field>
      <button style="margin-left: 10px;" mat-button class="primarybutton" (click)="tallennaLoppuu()">
        Tallenna
      </button>
    </div>
  </form>
</div>


<ng-container *ngIf="taseDataObservable | async; let data">
  <ng-container *ngIf="!(loadingSubject | async) else loading">

    <div *ngIf="data.lukittu" style="padding: 3em; font-weight: bold;">
      Tilinpäätös on rekisteröity. Tietoja ei voi enää muokata.
    </div>

    <div class="save-status">
      <span *ngIf="saveSuccessObservable | async">
        <span [@quickInSlowOut]="'in'" style="color: gray;">
          Lomake tallennettiin
        </span>
        <mat-icon class="save-success-icon" [@quickInSlowOut]="'in'" [ngStyle]="{'color':'#4bd76b','vertical-align':'middle'}">done</mat-icon>
      </span>
    </div>


    <table *ngIf="data.c == 'c2' || data.c == 'c1'" class="report-table" (click)="handleClick($event, data)">
      <thead>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{data.c1?.e | lnumberpvml:'fi'}}</td>
          <td></td>
        </tr>
      </thead>
      <tbody *ngFor="let row of data.r; trackBy: trackAccountRowByAccountNumberFn" [attr.data-a]="row.a">
        <tr [attr.data-tnro]="row.a" class="report-row-lvl-{{row.l}} report-row-acc-nmbr-length-{{row.a.length}} report-acc-row" [class.report-row-expanded]="row.e" [class.report-row-sum]="row.s">
          <td>
            <select [disabled]="data.lukittu" [ngModel]="row.kasittely" (ngModelChange)="tilinKasittelyMuuttui($event, row)" *ngIf="row.a.length > 3">
              <option [ngValue]="'e'">Ei eritellä</option>
              <option [ngValue]="'k'">Kaikki tilikaudet</option>
              <option [ngValue]="'r'">Raportin tilikausi</option>
              <option [ngValue]="'v'">Viimeinen kuukausi</option>
              <option [ngValue]="'l'">Erillinen liite</option>
              <option [ngValue]="'t'">Oma teksti</option>
            </select>
          </td>
          <td class="n" colspan="5">{{row.n}}</td>
          <td>{{row.s1 | number:'1.2-2'}}</td>
          <td></td>
        </tr>
        <tr *ngIf="row.kasittely === 't'" class="report-row-lvl-{{row.l}} report-table-detail-row">
          <td></td>
          <td colspan="7">
            <mat-form-field style="width: 95%; margin-left: 5%;">
              <mat-label>Oma teksti</mat-label>
              <textarea [disabled]="data.lukittu" [ngModel]="row.teksti" (ngModelChange)="tilinTekstiMuuttui($event, row)" (blur)="autosave()" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="100"></textarea>
            </mat-form-field>
          </td>
        </tr>
        <tr *ngIf="row.e && row.d == undefined" class="report-row-lvl-{{row.l}} report-table-detail-row">
          <td></td>
          <td colspan="7">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </td>
        </tr>
        <tr *ngIf="row.kasittely === 'l'" class="report-row-lvl-{{row.l}}">
          <td></td>
          <td colspan="7">
            <button mat-button type="button">
              <label style="cursor: pointer;" [for]="'fileInput-' + row.a">
                + Lisää erillinen liite
              </label>
            </button>
            <input [id]="'fileInput-' + row.a" type="file" style="display: none;" accept="*" multiple (change)="lataa($event, row.a)" />

            <div class="attachment-wrapper">
              <div class="attachment-row" *ngFor="let attachment of cachedAttachmentsMap.get(row.a);">
                <ng-container *ngIf="!attachment.poistettu">
                  <button mat-icon-button class="remove-btn small-icon-button" (click)="deleteAttachment(attachment, row.a)"><i class="icon ion-ios-trash icon-btn"></i></button>
                  <div class="attachment-text">{{ attachment.nimi }}</div>
                  <!-- <div class="attachment-size"> {{ attachment.koko > (100 * 1000) ? ((attachment.koko / (1000 * 1000)) | ldecimall: 1 : 'fi') : ((attachment.koko / (1000 * 1000)) | ldecimall: 3 : 'fi') }} MT</div> -->
                  <!-- <button class="lataa-pdf-button" type="button" type="button">
                <i class="fa fa-file-pdf-o"></i>
              </button> -->
                </ng-container>
              </div>
            </div>
          </td>
        </tr>
        <ng-container *ngIf="row.e && row.d" class="report-row-lvl-{{row.l}} report-table-detail-row">
          <tr *ngIf="row.d.length == 0">
            <td></td>
            <td colspan="7">
              Tilille ei löytynyt yhtään vientiä.
            </td>
          </tr>
          <ng-container *ngIf="row.d.length > 0">
            <tr class="alkusaldo report-row-lvl-{{row.l}} report-table-detail-row harmaa" [class.valittu]="row.alkusaldoValittu">
              <td></td>
              <td></td>
              <td></td>
              <td>Alkusaldo</td>
              <td></td>
              <td></td>
              <td>{{row.o1 | number:'1.2-2'}}</td>
              <td>
                <input [disabled]="data.lukittu" type="checkbox" [ngModel]="row.alkusaldoValittu" (ngModelChange)="alkusaldonValintaMuuttui($event, row)" />
              </td>
            </tr>
            <tr *ngFor="let d of row.d; let i = index;" [attr.data-i]="i" class="report-row-lvl-{{row.l}} report-table-detail-row harmaa" [class.laajennettu]="d.l" [class.valittu]="d.valittu">
              <td></td>
              <td [class.projekti-dot]="d.b" [class]="d.b ? 'a' + d.b : null" [attr.data-n]="d.n">{{d.n}}</td>
              <td>{{d.p | lnumberpvmlnarrow:'fi'}}</td>
              <td>
                <span *ngIf="d.a" class="blue-text">{{d.a}}</span>
                <span *ngIf="d.c" class="cursive-text">{{d.c}}</span>
                {{d.r}} {{d.e}}
              </td>
              <td>{{d.d | number:'1.2-2'}}</td>
              <td>{{d.k | number:'1.2-2'}}</td>
              <td>{{d.s | number:'1.2-2'}}</td>
              <td>
                <input [disabled]="data.lukittu" type="checkbox" [ngModel]="d.valittu" (ngModelChange)="vienninValintaMuuttui($event, row, d, i)" />
              </td>
            </tr>
            <tr class="report-row-lvl-{{row.l}} report-table-detail-row yhteensa">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colspan="3">
                {{row.valittuSumma | number:'1.2-2'}}
                <div *ngIf="row.valittuSumma != row.s1" style="color: red; font-weight: bold;">
                  Erottaa {{(row.s1 - row.valittuSumma) | number:'1.2-2'}}
                </div>
              </td>
              <td></td>
            </tr>
          </ng-container>
        </ng-container>
        <tr *ngIf="row.s && (row.a === '1' || row.a === '2')" class="report-row-lvl-{{row.l}} report-table-detail-row">
          <td colspan="3">
            <mat-form-field style="width: 95%; margin-left: 5%;">
              <mat-label style="text-transform: none;">Vapaa teksti</mat-label>
              <textarea [disabled]="data.lukittu" [ngModel]="row.teksti" (ngModelChange)="tilinTekstiMuuttui($event, row)" (blur)="autosave()" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="100"></textarea>
            </mat-form-field>
          </td>
        </tr>
      </tbody>
    </table>

    <div #latausvirhe class="red-text" style="margin: 20px 0;" *ngIf="latausVirheObservable | async; let latausVirhe">{{ latausVirhe }}</div>

    <div style="text-align: right; padding: 30px 0;">
      <button *ngIf="!data.lukittu" mat-button class="primarybutton" (click)="tallenna(data)" [disabled]="latausVirheObservable| async">
        Tallenna
      </button>
    </div>
  </ng-container>

</ng-container>

<ng-template #loading>
  <mat-spinner style="margin-left: auto; margin-right: auto; margin-top: 20px;"></mat-spinner>
</ng-template>

<!-- <input #fileInput type="file" style="visibility: hidden;" accept="*" multiple (change)="lataa($event)" /> -->