import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core'

import { Asiakas } from '../../../_jaettu-lemonator/model/asiakas'
import { ViiaSyncAccountTransactionsJobData } from '../../../_jaettu/model/aiia'

import { AsiakasService } from '../../../_angular/service/asiakas/asiakas.service'


import { Subject, Observable, of as observableof, combineLatest } from 'rxjs'
import { switchMap, startWith, map, takeUntil } from 'rxjs/operators'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { FirebaseLemonator, FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'
import { DebugService } from 'app/_angular/service/debug.service'
import { QueryDocumentSnapshot } from 'firebase/firestore'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'

interface ViiaSyncAccountTransactionsJobDataForDisplay extends ViiaSyncAccountTransactionsJobData {
  lastDelayReason: string
  uri: string
}

@Component({
  selector: '[app-aiia-tyohistoriat]',
  templateUrl: './aiia-tyot.component.html',
  styleUrls: ['./aiia-tyot.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AiiaTyotComponent implements OnInit, OnDestroy {

  asiakas: Asiakas = null
  namename = 'asdf ' + Math.random()

  kirjanpitajaOnDevaajaObservable: Observable<boolean>
  historiaObservable: Observable<ViiaSyncAccountTransactionsJobDataForDisplay[]>

  form: UntypedFormGroup

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    private _asiakasService: AsiakasService,
    private _firebase: FirebaseLemonator,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _debugService: DebugService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService
  ) { }

  ngOnInit() {

    // Debug thingies
    this.kirjanpitajaOnDevaajaObservable = this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable

    this._asiakasService.nykyinenAsiakasObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(result => {
      this.asiakas = result
    })

    this.form = new UntypedFormGroup({
      'historianMaara': new UntypedFormControl(null, Validators.required)
    })

    const uri = 'tyojono/viia/viia-update-transactions-jobs'
    this.historiaObservable = combineLatest([
      this._asiakasService.nykyinenAsiakasAvainObservable,
      this.form.get('historianMaara').valueChanges.pipe(startWith('50')),
      this.kirjanpitajaOnDevaajaObservable
    ]).pipe(
      switchMap(([asiakas, historianMaara, onDevaaja]) => {
        if (!asiakas) {
          return observableof<ViiaSyncAccountTransactionsJobDataForDisplay[]>([])
        }
        return this._firebaseLemonaid.firestoreCollection<ViiaSyncAccountTransactionsJobDataForDisplay>(uri).where('asiakasAvain', '==', asiakas.avain).limit(Number(historianMaara)).listenSnapshots().pipe(
          map(res => res.map(snap => {
            const a = snap.data()
            if (a.delayedReasons?.length > 0) {
              a.lastDelayReason = JSON.stringify(a.delayedReasons[a.delayedReasons.length - 1])
            } else {
              a.lastDelayReason = 'Not delayed yet'
            }
            if (onDevaaja) {
              a.uri = this._debugService.createFirestoreLinkLemonaid(uri + '/' + snap.id)
            }
            return a
          })),
          map(res => res.sort((a, b) => a.delayedTimes - b.delayedTimes))
        )
      })
    )

    this.form.get('historianMaara').setValue('50')
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
