import { Injectable } from '@angular/core'

import { FirebaseLemonator } from '../firebase-lemonator.service'

import { KirjattavaKuitti, KirjattavaLasku, KirjaukseenLiitettyjenTiedostojenSivut, KirjauksenKuitinTallennuspyynto, KirjauksenLaskunTallennuspyynto, KirjauksenRaahattavanTallennuspyynto, Kirjaus } from '../../../_jaettu-lemonator/model/kirjanpito'
import { AsiakkaanAvainTiedot } from '../asiakas/asiakas.service'
import { KirjanpitoImageHandlerService } from 'app/_angular/service/kirjanpito/kirjanpito.service'
import { KirjanpitoJaettuService } from 'app/_jaettu-lemonator/service/kirjanpito-jaettu.service'

import { NaytettavaRaahattava } from 'app/kirjanpito/tositteet/tosite-kirjaamattomat.component'
import { Laskuasetukset } from 'app/_jaettu/model/lasku'

@Injectable()
export class KirjanpitoTositeService {

  constructor(
    private _firebase: FirebaseLemonator,
    private _kirjanpitoJaettuService: KirjanpitoJaettuService,
    private _kirjanpitoImageHandlerService: KirjanpitoImageHandlerService
  ) {

  }

  async liitaRahaattuKirjaukseen(asiakas: AsiakkaanAvainTiedot, kirjaus: Kirjaus, kirjattavaRaahattava: NaytettavaRaahattava) {
    const tosite = await this._kirjanpitoImageHandlerService.muutaRaahattavaTositteeksi(asiakas, kirjattavaRaahattava)

    const raahattavanSivut: KirjaukseenLiitettyjenTiedostojenSivut = { p: 0, t: {} }
    raahattavanSivut.t[kirjattavaRaahattava.avain] = kirjattavaRaahattava.raahattava.s
    this._kirjanpitoImageHandlerService.tositteenLisaaminenAloitettiin(kirjaus, tosite)

    const lisattavaTiedosto: KirjauksenRaahattavanTallennuspyynto = {
      asiakasAvain: asiakas.avain,
      kirjausAvain: kirjaus.avain,
      raahattavanAvain: kirjattavaRaahattava.avain
    }

    return this._firebase.functionsCall<KirjauksenRaahattavanTallennuspyynto, void>('kirjanpitoKirjauksetRaahattuTallenna', lisattavaTiedosto).then(() => {
      this._kirjanpitoImageHandlerService.tositteenLisaaminenOnnistui(kirjaus, tosite)
    }).catch(err => {
      this._kirjanpitoImageHandlerService.tositteenLisaaminenEpaonnistui(kirjaus, tosite)
      throw err
    })
  }

  async liitaKuittiKirjaukseen(asiakas: AsiakkaanAvainTiedot, kirjaus: Kirjaus, kirjattavaKuitti: KirjattavaKuitti) {
    const tosite = await this._kirjanpitoImageHandlerService.muutaKuittiTositteeksi(asiakas, kirjattavaKuitti)
    const kuitti = kirjattavaKuitti.kuitti[kirjattavaKuitti.kuitti.length - 1]
    const kuitinSivut: KirjaukseenLiitettyjenTiedostojenSivut = { p: 0, t: {} }
    this._kirjanpitoJaettuService.muutaKuittiLiitetyiksiSivuiksi(kuitti, kuitinSivut)
    this._kirjanpitoImageHandlerService.tositteenLisaaminenAloitettiin(kirjaus, tosite)

    const lisattavaTiedosto: KirjauksenKuitinTallennuspyynto = {
      asiakasAvain: asiakas.avain,
      kirjausAvain: kirjaus.avain,
      kuitinAvain: kirjattavaKuitti.avain
    }

    return this._firebase.functionsCall<KirjauksenKuitinTallennuspyynto, void>('kirjanpitoKirjauksetKuittiTallennaIlmanYhdistelya', lisattavaTiedosto).then(() => {
      this._kirjanpitoImageHandlerService.tositteenLisaaminenOnnistui(kirjaus, tosite)
    }).catch(err => {
      this._kirjanpitoImageHandlerService.tositteenLisaaminenEpaonnistui(kirjaus, tosite)
      throw err
    })
  }

  async liitaLaskuKirjaukseen(asiakas: AsiakkaanAvainTiedot, kirjaus: Kirjaus, kirjattavaLasku: KirjattavaLasku) {
    const tosite = await this._kirjanpitoImageHandlerService.muutaLaskuTositteeksi(asiakas, kirjattavaLasku)

    const lasku = this._kirjanpitoJaettuService.annaLaskuKirjattavastaLaskusta(kirjattavaLasku)
    const kuitinSivut: KirjaukseenLiitettyjenTiedostojenSivut = { p: 0, t: {} }
    this._kirjanpitoJaettuService.muutaLaskuLiitetyiksiSivuiksi(lasku, kuitinSivut)
    this._kirjanpitoImageHandlerService.tositteenLisaaminenAloitettiin(kirjaus, tosite)

    const lisattavaTiedosto: KirjauksenLaskunTallennuspyynto = {
      asiakasAvain: asiakas.avain,
      kirjausAvain: kirjaus.avain,
      laskuAvain: kirjattavaLasku.avain
    }

    return this._firebase.functionsCall<KirjauksenLaskunTallennuspyynto, void>('kirjanpitoKirjauksetLaskutTallenna', lisattavaTiedosto).then(() => {
      this._kirjanpitoImageHandlerService.tositteenLisaaminenOnnistui(kirjaus, tosite)
    }).catch(err => {
      this._kirjanpitoImageHandlerService.tositteenLisaaminenEpaonnistui(kirjaus, tosite)
      throw err
    })
  }

}
