import { Injectable, ErrorHandler } from '@angular/core'

import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'

import { AlvIlmoitusjakso, EiYhtiomuotoaYhtiomuoto, KirjanpitoKuukausiruutu, ListausAsiakas as DatabaseListausAsiakas } from '../../_jaettu-lemonator/model/asiakas'
import { KirjanpitajanRooli } from '../../_jaettu/lemonator/model/kirjanpitaja'
import { KirjanpitajanSarake, KirjanpitajanSarakkeenTila, KirjanpitajanSarakkeidenArvotAsiakkaalle, KirjanpitajanAsetukset } from '../../_jaettu-lemonator/model/kirjanpitaja'

import { CurrencyService } from '../../_shared-core/service/currency.service'
import { DateService } from '../../_shared-core/service/date.service'
import { LocalMonth, KuukausiAikavali } from 'app/_shared-core/model/common'
import { KopioijaPalvelu } from 'app/_jaettu/service/kopioija.service'

import { KirjautunutKayttajaService } from '../../_angular/service/kirjautunut-kayttaja.service'
import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'

import { combineLatest, Observable, BehaviorSubject, of, merge, ReplaySubject, Subject } from 'rxjs'
import { map, switchMap, withLatestFrom, distinctUntilChanged, takeUntil } from 'rxjs/operators'

import { Yritysmuoto } from 'app/_jaettu/model/kayttaja'
import { AsiakasUriService } from 'app/_jaettu-lemonator/service/asiakas-uri.service'
import { KirjanpitoKuukausiStripService } from 'app/_angular/service/kirjanpito/kirjanpito-kuukausistrip.service'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { DocumentChange } from 'firebase/firestore'

export interface ListausAsiakkaanSarake {
  /** Aktiivinen */
  a?: boolean
  sarakeAvain: string
  tilaAvain: string
  arvo: string
  luokka: string
}
export interface ListausAsiakas extends DatabaseListausAsiakas {
  synkronoi?: true
  sopimusHyvaksymatta?: true
  palaveriPitamatta?: true
  pankkiyhteysPuuttuu?: true
  pankkiyhteysEiOleLisattyMaksutapoihin?: true
  riskiarvioTekematta?: true
  edustajaTunnistamatta?: true
  // asiakasId: number
  // hinta: string
  // nimi: string
  /** Tässä datassa on AINA kaikki sarakkeet! */
  sarakkeet: ListausAsiakkaanSarake[]
  yritysmuoto: string
  alvIlmoitusjakso: string
  kuukaudet: KuukausiruudunTiedot[]
  omaverosaldo: string
  luokat: string
}

export interface KuukausiruudunTiedot extends KirjanpitoKuukausiruutu {
  /**
   * Ruudun vasen alakulma
   */
  vasenAlakulma: any
  /**
   * Ruudun oikea alakulma
   */
  oikeaAlakulma: number
  /**
   * Ruudun vasen yläkulma
   */
  vasenYlakulma: number
  /**
   * CSS luokat
   */
  luokat: string
}

interface KirjanpitajanSarakeHaulla extends KirjanpitajanSarake {
  tilojenHakuMap: Map<string, KirjanpitajanSarakkeenTila>
}

export type LukemanTyyppiVanha = 'minuutit' | 'tehot'
export type LukemanTyyppi = LukemanTyyppiVanha | 'selvitettavat'

@Injectable()
export class AsiakkaatDataSourceStateService {

  // Suodatusvalinnat
  vapaaTekstihakuObservable: BehaviorSubject<string> = new BehaviorSubject('')
  vapaaTekstihakuOldListObservable: BehaviorSubject<string> = new BehaviorSubject('')
  lukemanTyyppiVanhaObservable: BehaviorSubject<LukemanTyyppiVanha> = new BehaviorSubject('tehot')
  lukemanTyyppiObservable: BehaviorSubject<LukemanTyyppi> = new BehaviorSubject('selvitettavat')
  naytaVainToimiaVaativatAsiakkaatObservable: BehaviorSubject<boolean> = new BehaviorSubject(false)
  rajaaKirjanpitajaAvainObservable: ReplaySubject<string> = new ReplaySubject(1)
  yhtiomuotoObservable: BehaviorSubject<Yritysmuoto | EiYhtiomuotoaYhtiomuoto> = new BehaviorSubject(null)
  alvjaksoObservable: BehaviorSubject<AlvIlmoitusjakso> = new BehaviorSubject(null)
  valittuAikavaliObservable: BehaviorSubject<KuukausiAikavali> = new BehaviorSubject({
    start: {
      month: 1, // alkaaPvm.getMonth() + 1,
      year: 2023 // alkaaPvm.getFullYear()
    },
    end: {
      month: 12, // loppuuPvm.getMonth() + 1,
      year: 2023 // loppuuPvm.getFullYear()
    }
  })
  naytaHolviPoolAsiakkaatSubject: BehaviorSubject<boolean> = new BehaviorSubject(false)

  naytaOmaVaiToisenKirjanpitajanSarakkeetValintaObservable: BehaviorSubject<'o' | 'k'> = new BehaviorSubject('k')
  kirjanpitajanAvainSarakkeitaVartenObservable: Observable<string> = combineLatest([
    this.naytaHolviPoolAsiakkaatSubject,
    this.rajaaKirjanpitajaAvainObservable,
    this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable,
    this.naytaOmaVaiToisenKirjanpitajanSarakkeetValintaObservable
  ]).pipe(
    map(([onkoHolvi, impersonoituKirjanpitaja, kirjanpitajanTiedot, omatVaiKirjanpitajanSarakkeet]) => {
      if (onkoHolvi) {
        return 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2'
      } else if (impersonoituKirjanpitaja && omatVaiKirjanpitajanSarakkeet === 'k') {
        return impersonoituKirjanpitaja
      } else if (kirjanpitajanTiedot) {
        return kirjanpitajanTiedot.uid
      }
      return null
    })
  )

  kaikkiSarakkeetObservable: Observable<KirjanpitajanSarake[]> = this.kirjanpitajanAvainSarakkeitaVartenObservable.pipe(
    switchMap(kirjanpitajaAvain => {
      if (kirjanpitajaAvain) {
        return this._firebase.firestoreCollection<KirjanpitajanSarake>('kirjanpitajat/' + kirjanpitajaAvain + '/kirjanpitajan-sarakkeet').listen()
      }
      return []
    }),
    map(sarakkeet => {
      return sarakkeet.sort((a, b) => {
        const ja = a.jarjestys || 0
        const jb = b.jarjestys || 0
        if (ja === jb) {
          return (a.nimi || '').localeCompare((b.nimi || ''))
        }
        return ja - jb
      })
    })
  )

  constructor(
    private _firebase: FirebaseLemonator,
    private _errorHandler: ErrorHandler,
    private _dateService: DateService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _kirjanpitajaService: KirjanpitajaService
  ) {
    this._kirjanpitajaService.kirjautuneenKayttajanAsetuksetObservable.pipe(
      distinctUntilChanged((a, b) => {
        return a?.kirjanpitajanAvain === b?.kirjanpitajanAvain
      })
    ).subscribe(kirjanpitaja => {
      if (kirjanpitaja?.asiakasListausAlkuKk || kirjanpitaja?.asiakasListausAlkuKk) {
        if (kirjanpitaja.asiakasListausAlkuKk) {
          this._setStartMonth(this._dateService.numberToLocalMonth(kirjanpitaja.asiakasListausAlkuKk))
        }
        if (kirjanpitaja.asiakasListausLoppuKk) {
          this._setEndMonth(this._dateService.numberToLocalMonth(kirjanpitaja.asiakasListausLoppuKk))
        }
        this.valittuAikavaliObservable.next(this.valittuAikavaliObservable.value)
      }
    })
  }

  getCurrentTimespan(): KuukausiAikavali {
    return this.valittuAikavaliObservable.value
  }

  private _setStartMonth(start: LocalMonth) {
    if (start.year > 2015 && start.year < 2100) {
      const current = this.valittuAikavaliObservable.value
      if (
        current.start?.year !== start.year ||
        current.start?.month !== start.month
      ) {
        current.start = start
        this.valittuAikavaliObservable.next(current)
      }
    }
  }

  async setStartMonth(start: LocalMonth) {
    if (start.year > 2015 && start.year < 2100) {
      this._setStartMonth(start)
      const kirjanpitaja = await this._kirjautunutKayttajaService.getKirjanpitajanTiedot()
      if (kirjanpitaja?.uid) {
        const update: Partial<KirjanpitajanAsetukset> = {
          kirjanpitajanAvain: kirjanpitaja.uid,
          asiakasListausAlkuKk: this._dateService.localMonthToNumber(start)
        }
        const uri = 'kirjanpitajat/' + kirjanpitaja.uid + '/kirjanpitajan-asetukset/' + kirjanpitaja.uid
        return this._firebase.firestoreSetData(uri, update, { merge: true }).catch(err => this._errorHandler.handleError(err))
      }
    }
  }

  private _setEndMonth(end: LocalMonth) {
    if (end.year > 2015 && end.year < 2100) {
      const current = this.valittuAikavaliObservable.value
      if (
        current.end?.year !== end.year ||
        current.end?.month !== end.month
      ) {
        current.end = end
        this.valittuAikavaliObservable.next(current)
      }
    }
  }

  async setEndMonth(end: LocalMonth) {
    if (end.year > 2015 && end.year < 2100) {
      this._setEndMonth(end)
      const kirjanpitaja = await this._kirjautunutKayttajaService.getKirjanpitajanTiedot()
      if (kirjanpitaja?.uid) {
        const update: Partial<KirjanpitajanAsetukset> = {
          kirjanpitajanAvain: kirjanpitaja.uid,
          asiakasListausLoppuKk: this._dateService.localMonthToNumber(end)
        }
        const uri = 'kirjanpitajat/' + kirjanpitaja.uid + '/kirjanpitajan-asetukset/' + kirjanpitaja.uid
        return this._firebase.firestoreSetData(uri, update, { merge: true }).catch(err => this._errorHandler.handleError(err))
      }
    }
  }

}

export class AsiakkaatDataSourceService {

  private _ngUnsubscribe: Subject<void> = new Subject<void>()
  public destroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  dataSource: MatTableDataSource<ListausAsiakas> = new MatTableDataSource([])
  dataSourceForOldList: MatTableDataSource<ListausAsiakas> = new MatTableDataSource([])
  kaikkiAsiakkaatObservable: BehaviorSubject<ListausAsiakas[]> = new BehaviorSubject([])

  naytettavatKuukaudetObservable: Observable<LocalMonth[]> = this._stateService.valittuAikavaliObservable.pipe(
    map(aikavali => {
      return this._dateService.puraAikavali(aikavali)
    })
  )

  private suodatetutAsiakkaat: Observable<ListausAsiakas[]> = combineLatest([this.kaikkiAsiakkaatObservable, this._stateService.yhtiomuotoObservable, this._stateService.alvjaksoObservable]).pipe(
    map(([asiakkaat, yhtiomuoto, alvjakso]) => {

      // console.log('Suodatetaan yhtiömuoto ja yritysmuoto')

      if (asiakkaat) {

        // console.log('Yritysmuoto', this.yhtiomuoto)

        if (yhtiomuoto && alvjakso) {
          if (yhtiomuoto === EiYhtiomuotoaYhtiomuoto.YHTIOMUOTO_EI_TIEDOSSA) {
            return asiakkaat.filter(asiakas => !asiakas.c && asiakas.a === alvjakso)
          }
          return asiakkaat.filter(asiakas => asiakas.c === yhtiomuoto && asiakas.a === alvjakso)
        } else if (yhtiomuoto) {
          if (yhtiomuoto === EiYhtiomuotoaYhtiomuoto.YHTIOMUOTO_EI_TIEDOSSA) {
            return asiakkaat.filter(asiakas => !asiakas.c)
          }
          return asiakkaat.filter(asiakas => asiakas.c === yhtiomuoto)
        } else if (alvjakso) {
          return asiakkaat.filter(asiakas => asiakas.a === alvjakso)
        }

      }

      return asiakkaat

    })
  )

  toimiaVaativatAsiakkaat: Observable<ListausAsiakas[]> = combineLatest([this.suodatetutAsiakkaat, this._stateService.naytaVainToimiaVaativatAsiakkaatObservable]).pipe(
    map(([asiakkaat, naytaVainTyotaVaativat]) => {

      // console.log('Suodatetaan toimia vaativat asiakkaat')

      if (naytaVainTyotaVaativat) {
        return asiakkaat.filter(asiakas => {

          if (asiakas && asiakas.kuukaudet) {
            for (const kuukausi of asiakas.kuukaudet) {
              if (

                // Uudet
                kuukausi.luokat.indexOf('a-l-r-alvmus') > -1 ||
                kuukausi.luokat.indexOf('a-l-r-alvpun') > -1 ||
                kuukausi.luokat.indexOf('a-l-r-alvsin') > -1 ||
                kuukausi.luokat.indexOf('a-l-r-alvora') > -1 ||

                // Vanhat
                kuukausi.luokat.indexOf('a-l-r-huutomus') > -1 ||
                kuukausi.luokat.indexOf('a-l-r-huutopun') > -1 ||
                kuukausi.luokat.indexOf('a-l-r-pallurasin') > -1

              ) {
                return true
              }
            }
          }

          return false
        })
      }

      return asiakkaat

    })
  )

  aktiivisetSarakkeetObservable = this._stateService.kaikkiSarakkeetObservable.pipe(
    map(sarakkeet => {
      return sarakkeet.filter(a => a.aktiivinen)
    })
  )

  private kirjanpitajanSarakkeetHaulla: Observable<KirjanpitajanSarakeHaulla[]> = this._stateService.kaikkiSarakkeetObservable.pipe(
    map(sarakkeet => {
      if (sarakkeet) {
        const tyhja: KirjanpitajanSarakkeenTila = { kuvaus: '', nimi: '', avain: null, oletus: false, vari: null }
        return sarakkeet.map(a => {
          const haulla = this._kopiojaPalvelu.cloneObjectDeep(a) as KirjanpitajanSarakeHaulla
          haulla.tilojenHakuMap = new Map()
          if (a.tilat) {
            let oletus: KirjanpitajanSarakkeenTila = null
            for (const t of a.tilat) {
              haulla.tilojenHakuMap.set(t.avain, t)
              if (t.oletus) {
                oletus = t
              }
            }
            haulla.tilojenHakuMap.set('oletus', oletus ? oletus : tyhja)
          }
          return haulla
        })
      }
      return []
    })
  )

  lataaObservable: BehaviorSubject<boolean> = new BehaviorSubject(false)

  constructor(
    private _firebase: FirebaseLemonator,
    private _errorHandler: ErrorHandler,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _currencyService: CurrencyService,
    private _dateService: DateService,
    private _kopiojaPalvelu: KopioijaPalvelu,
    private _asiakasUriService: AsiakasUriService,
    private _kirjanpitoKuukausistripService: KirjanpitoKuukausiStripService,
    private _stateService: AsiakkaatDataSourceStateService
  ) {

    let kuukaudetCache: LocalMonth[] = this._dateService.puraAikavali(this._stateService.valittuAikavaliObservable.value)
    const cacheIndexes: Map<string, number> = new Map()
    const cache: ListausAsiakas[] = []
    const sarakeArvotCache: Map<string, KirjanpitajanSarakkeidenArvotAsiakkaalle> = new Map()
    const ruudutCache: Map<string, KirjanpitoKuukausiruutu> = new Map()
    const sarakeCache: KirjanpitajanSarakeHaulla[] = []

    this.naytettavatKuukaudetObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(kuukaudet => {
      kuukaudetCache = kuukaudet
      // console.log('Aseta kuukaudet', kuukaudet)
      for (const asiakas of cache) {
        // const kopiotu = this._asiakasKopioija.kopioiAsiakas(asiakas)
        // const listausAsiakas = this.kasitteleAsiakas(kopiotu)
        // this.kasitteleAsiakkaanSarakkeet(listausAsiakas, sarakeCache, sarakeArvotCache)
        this.kasitteleAsiakkaanKuukausiruudut(asiakas, ruudutCache, kuukaudet)
        // const index = cacheIndexes.get(asiakas.avain)
        // cache[index] = listausAsiakas
        // console.log('AIKAKONEPAIVITETTY', asiakas.avain, 'indeksissä', index)
      }
      this.kaikkiAsiakkaatObservable.next(cache)
    })

    // Jos näytettävän lukeman tyyppi muuttuu, päivitä näytettävät arvot
    this._stateService.lukemanTyyppiVanhaObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(() => {
      for (const asiakas of cache) {
        this.kasitteleAsiakkaanKuukausiruudut(asiakas, ruudutCache, kuukaudetCache)
      }
      this.kaikkiAsiakkaatObservable.next(cache)
    })

    // Jos näytettävän lukeman tyyppi muuttuu, päivitä näytettävät arvot
    this._stateService.lukemanTyyppiObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(() => {
      for (const asiakas of cache) {
        this.kasitteleAsiakkaanKuukausiruudut(asiakas, ruudutCache, kuukaudetCache)
      }
      this.kaikkiAsiakkaatObservable.next(cache)
    })

    // Jos kirjanpitäjän sarakkeet muuttuvat, päivitä sarakkeet ja niiden arvot
    this.kirjanpitajanSarakkeetHaulla.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(sarakkeet => {
      sarakeCache.length = 0
      sarakeCache.push(...sarakkeet)
      for (const asiakas of cache) {
        this.kasitteleAsiakkaanSarakkeet(asiakas, sarakkeet, sarakeArvotCache)
      }
      this.kaikkiAsiakkaatObservable.next(cache)
    })

    this.toimiaVaativatAsiakkaat.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(asiakkaat => {
      const uudet: ListausAsiakas[] = []
      const vanhat: ListausAsiakas[] = []
      for (const asiakas of asiakkaat) {
        if (asiakas.u || asiakas.p === 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2') {
          uudet.push(asiakas)
        } else {
          vanhat.push(asiakas)
        }
      }
      // console.log('Set uudet', uudet.length, 'Set vanhat', vanhat.length)
      this.dataSource.data = uudet
      this.dataSourceForOldList.data = vanhat
    }, error => {
      this._errorHandler.handleError(error)
    })

    this.kirjanpitajanSarakkeetHaulla.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(sarakkeet => {
      this.dataSource.sortData = (data: ListausAsiakas[], sort: MatSort): ListausAsiakas[] => {

        const active = sort.active
        const direction = sort.direction

        if (!active || direction === '') { return data }

        // console.log('sort', active, direction)

        const directionMultiplier = direction === 'asc' ? 1 : -1

        if (active === 'verotilinsaldo') {
          return data.sort((a, b) => {
            const valuea = a.o ?? null
            const valueb = b.o ?? null
            if (valuea === null && valueb === null) {
              return 0
            } else if (valuea === null) {
              return 1 * directionMultiplier
            } else if (valueb === null) {
              return -1 * directionMultiplier
            }
            return (valuea - valueb) * directionMultiplier
          })
        }

        // if (active === 'verotilintila') {
        //   return data.sort((a, b) => {
        //     const valuea = a.omaverosaldo ? a.omaverosaldo.maksujaMyohassa : null
        //     const valueb = b.omaverosaldo ? b.omaverosaldo.maksujaMyohassa : null
        //     return (valuea === valueb ? 0 : valuea ? 1 : -1) * directionMultiplier
        //   })
        // }

        // if (active === 'vastuukirjanpitaja') {
        //   return data.sort((a, b) => {
        //     const valuea = a.kasittelija ? a.kasittelija : ''
        //     const valueb = b.kasittelija ? b.kasittelija : ''
        //     return valuea.localeCompare(valueb) * directionMultiplier
        //   })
        // }

        // if (active === 'tuuraaja') {
        //   return data.sort((a, b) => {
        //     const valuea = a.kasittelijavara ? a.kasittelijavara : ''
        //     const valueb = b.kasittelijavara ? b.kasittelijavara : ''
        //     return valuea.localeCompare(valueb) * directionMultiplier
        //   })
        // }

        if (active === 'synkronoi') {
          return data.sort((a, b) => {
            if (a.synkronoi && b.synkronoi) {
              return 0
            } else if (a.synkronoi) {
              return 1 * directionMultiplier
            } else if (b.synkronoi) {
              return -1 * directionMultiplier
            }
            return 0
          })
        }

        if (active === 'yritysmuoto') {
          return data.sort((a, b) => {
            const valuea = a.yritysmuoto ? a.yritysmuoto : ''
            const valueb = b.yritysmuoto ? b.yritysmuoto : ''
            return valuea.localeCompare(valueb) * directionMultiplier
          })
        }

        if (active === 'alvjakso') {
          return data.sort((a, b) => {
            const valuea = a.alvIlmoitusjakso ? a.alvIlmoitusjakso : ''
            const valueb = b.alvIlmoitusjakso ? b.alvIlmoitusjakso : ''
            return valuea.localeCompare(valueb) * directionMultiplier
          })
        }

        if (active === 'asiakasId') {
          return data.sort((a, b) => {
            if (a.i === null && b.i === null) {
              return 0
            } else if (a.i === null) {
              return 1 * directionMultiplier
            } else if (b.i === null) {
              return -1 * directionMultiplier
            }
            return (a.i - b.i) * directionMultiplier
          })
        }

        const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

        if (active === 'hinta') {
          return data.sort((a, b) => {
            const valuea = a.h ?? null
            const valueb = b.h ?? null
            if (valuea === null && valueb === null) {
              return 0
            } else if (valuea === null) {
              return -1 * directionMultiplier
            } else if (valueb === null) {
              return 1 * directionMultiplier
            }
            return collator.compare(valuea, valueb) * directionMultiplier
          })
        }

        // if (active === 'verotilinhuomautus') {
        //   return data.sort((a, b) => {
        //     const valuea = a.omaverosaldo ? a.omaverosaldo.huomautus : null
        //     const valueb = b.omaverosaldo ? b.omaverosaldo.huomautus : null
        //     return collator.compare(valuea, valueb) * directionMultiplier
        //   })
        // }

        // if (active === 'ytunnus') {
        //   return data.sort((a, b) => {
        //     return collator.compare(a.ytunnus, b.ytunnus) * directionMultiplier
        //   })
        // }

        if (active === 'nimi') {
          // console.log('sort nimi')
          return data.sort((a, b) => {
            // console.log('Verrataan')
            return collator.compare(a.n, b.n) * directionMultiplier
          })
        }

        if (sarakkeet) {
          for (const sarake of sarakkeet) {
            if (sarake.avain === active) {
              return data.sort((a, b) => {

                const ak = a.sarakkeet.find(sar => sar.sarakeAvain === sarake.avain)
                const aa = ak ? ak.tilaAvain : null
                const atila = sarake.tilojenHakuMap.get(aa ? aa : 'oletus')

                const bk = b.sarakkeet.find(sar => sar.sarakeAvain === sarake.avain)
                const ba = bk ? bk.tilaAvain : null
                const btila = sarake.tilojenHakuMap.get(ba ? ba : 'oletus')

                if (!atila?.nimi && !btila?.nimi) {
                  return 0
                } else if (!atila?.nimi) {
                  return 1 * directionMultiplier
                } else if (!btila?.nimi) {
                  return -1 * directionMultiplier
                }
                return atila.nimi.localeCompare(btila.nimi) * directionMultiplier
              })
            }
          }
        }

        return data

      }
      this.dataSourceForOldList.sortData = this.dataSource.sortData
    })

    const raakadataObservable: Observable<DocumentChange<DatabaseListausAsiakas>[]> = combineLatest([this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable, this._stateService.rajaaKirjanpitajaAvainObservable, this._stateService.naytaHolviPoolAsiakkaatSubject]).pipe(
      switchMap(([kirjanpitajanTiedot, valitunKirjanpitajanAvain, naytaHolviPoolAsiakkaat]) => {
        // console.log('HAE LISTAUSASIAKKAAT', kirjanpitajanTiedot, valitunKirjanpitajanAvain, naytaHolviPoolAsiakkaat)
        // Nollaa data source
        this.dataSource.data = []
        this.dataSourceForOldList.data = []
        // this.dataSource.paginator.pageIndex = 0
        // this.dataSource.filter = null
        // this.dataSourceForOldList.filter = null
        cache.length = 0
        cacheIndexes.clear()
        if (kirjanpitajanTiedot) {
          this.lataaObservable.next(true)
          if (naytaHolviPoolAsiakkaat) {
            // console.log('Palautetaan Herra Holvin asiakkaat.')
            const varsinainenObservable = this._firebase.firestoreCollectionGroup<DatabaseListausAsiakas>('asiakaslistaus').where('p', '==', 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2').listenChanges()
            const tuuraajaObservable = this._firebase.firestoreCollectionGroup<DatabaseListausAsiakas>('asiakaslistaus').where('s', '==', 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2').listenChanges()
            return merge(varsinainenObservable, tuuraajaObservable)
          } else if (kirjanpitajanTiedot.rooli === KirjanpitajanRooli.SUPER) {
            // console.log('Palautetaan kaikki asiakkaat.')
            if (valitunKirjanpitajanAvain) {
              const varsinainenObservable = this._firebase.firestoreCollectionGroup<DatabaseListausAsiakas>('asiakaslistaus').where('p', '==', valitunKirjanpitajanAvain).listenChanges() // kirjanpitajanTiedot.uid
              const tuuraajaObservable = this._firebase.firestoreCollectionGroup<DatabaseListausAsiakas>('asiakaslistaus').where('s', '==', valitunKirjanpitajanAvain).listenChanges()
              return merge(varsinainenObservable, tuuraajaObservable)
            }
            return this._firebase.firestoreCollectionGroup<DatabaseListausAsiakas>('asiakaslistaus').listenChanges()
          } else {
            // console.log('Palautetaan vastuuasiakkaat.')
            const varsinainenObservable = this._firebase.firestoreCollectionGroup<DatabaseListausAsiakas>('asiakaslistaus').where('p', '==', kirjanpitajanTiedot.uid).listenChanges() // kirjanpitajanTiedot.uid
            const tuuraajaObservable = this._firebase.firestoreCollectionGroup<DatabaseListausAsiakas>('asiakaslistaus').where('s', '==', kirjanpitajanTiedot.uid).listenChanges()
            return merge(varsinainenObservable, tuuraajaObservable)
          }
        } else {
          return of<DocumentChange<DatabaseListausAsiakas>[]>([])
        }
      })
    )
    this._stateService.kirjanpitajanAvainSarakkeitaVartenObservable.pipe(
      switchMap(uid => {
        // console.log('Hae raakadata aloitettu', uid)
        // console.time('raakaArvodata')
        if (uid) {
          const uri = this._asiakasUriService.annaListausSarakkeidenCollectionUri(uid)
          // console.log('Listen', uri)
          return this._firebase.firestoreCollection<KirjanpitajanSarakkeidenArvotAsiakkaalle>(uri).listenChanges()
        }
        return of<DocumentChange<KirjanpitajanSarakkeidenArvotAsiakkaalle>[]>([])
      }),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(arvotAsiakkaille => {
      // console.timeEnd('raakaArvodata')
      for (const arvoAsiakkaalleChange of arvotAsiakkaille) {
        const arvoAsiakkaalle = arvoAsiakkaalleChange.doc.data()

        if (arvoAsiakkaalleChange.type === 'removed') {
          sarakeArvotCache.delete(arvoAsiakkaalle.a)
        } else {
          sarakeArvotCache.set(arvoAsiakkaalle.a, arvoAsiakkaalle)
        }

        const index = cacheIndexes.get(arvoAsiakkaalle.a)
        if (index || index === 0) {
          const asiakas: ListausAsiakas = cache[index]
          if (asiakas) {
            this.kasitteleAsiakkaanSarakkeet(asiakas, sarakeCache, sarakeArvotCache)
          }
        }
      }

      this.kaikkiAsiakkaatObservable.next(cache)

    })
    const raakadataRuutuObservable: Observable<DocumentChange<KirjanpitoKuukausiruutu>[]> = combineLatest([
      this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable,
      this._stateService.rajaaKirjanpitajaAvainObservable,
      this._stateService.naytaHolviPoolAsiakkaatSubject,
      this._stateService.valittuAikavaliObservable
    ]).pipe(
      switchMap(([kirjanpitajanTiedot, rajaaKirjanpitajanMukaan, naytaHolviPoolAsiakkaat, aikavali]) => {

        const start = this._dateService.localMonthToNumber(aikavali.start)
        const end = this._dateService.lisaaKuukausiaKuukaudenNumero(this._dateService.localMonthToNumber(aikavali.end), 1)
        // console.log('LADATAAN RUUTUJA', rajaaKirjanpitajanMukaan, start, end)
        if (kirjanpitajanTiedot) {
          // this.lataaObservable.next(true)
          if (naytaHolviPoolAsiakkaat) {
            // console.log('Palautetaan Herra Holvin ruudut.')
            return this._firebase.firestoreCollectionGroup<KirjanpitoKuukausiruutu>('asiakaslistauksen-kuukausiruutu')
              .where('p', '==', 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2')
              .where('k', '>=', start)
              .where('k', '<=', end)
              .listenChanges()
          } else if (kirjanpitajanTiedot.rooli === KirjanpitajanRooli.SUPER) {

            if (rajaaKirjanpitajanMukaan) {
              const varsinainenObservable = this._firebase.firestoreCollectionGroup<KirjanpitoKuukausiruutu>('asiakaslistauksen-kuukausiruutu')
                .where('p', '==', rajaaKirjanpitajanMukaan)
                .where('k', '>=', start)
                .where('k', '<=', end)
                .listenChanges()
              const tuuraajaObservable = this._firebase.firestoreCollectionGroup<KirjanpitoKuukausiruutu>('asiakaslistauksen-kuukausiruutu')
                .where('s', '==', rajaaKirjanpitajanMukaan)
                .where('k', '>=', start)
                .where('k', '<=', end)
                .listenChanges()
              return merge(varsinainenObservable, tuuraajaObservable)
            }

            // console.log('Palautetaan kaikki ruudut välillä', start, end)
            return this._firebase.firestoreCollectionGroup<KirjanpitoKuukausiruutu>('asiakaslistauksen-kuukausiruutu')
              .where('k', '>=', start)
              .where('k', '<=', end)
              .listenChanges()

          } else {
            // console.log('Palautetaan vastuuruudut.')
            const varsinainenObservable = this._firebase.firestoreCollectionGroup<KirjanpitoKuukausiruutu>('asiakaslistauksen-kuukausiruutu')
              .where('p', '==', kirjanpitajanTiedot.uid)
              .where('k', '>=', start)
              .where('k', '<=', end)
              .listenChanges()
            const tuuraajaObservable = this._firebase.firestoreCollectionGroup<KirjanpitoKuukausiruutu>('asiakaslistauksen-kuukausiruutu')
              .where('s', '==', kirjanpitajanTiedot.uid)
              .where('k', '>=', start)
              .where('k', '<=', end)
              .listenChanges()
            return merge(varsinainenObservable, tuuraajaObservable)
          }
        } else {
          return of<DocumentChange<KirjanpitoKuukausiruutu>[]>([])
        }
      })
    )
    raakadataRuutuObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(ruudutChanges => {
      const asiakkaatSet: Set<string> = new Set()
      for (const change of ruudutChanges) {
        const ruutu = change.doc.data()

        // if (!ruutu.a) {
        //   console.log('RIKKINÄINEN!!!', change.doc.id, ruutu)
        // }

        if (change.type === 'removed') {
          ruudutCache.delete(ruutu.a + ruutu.k)
        } else {
          ruudutCache.set(ruutu.a + ruutu.k, ruutu)
        }

        asiakkaatSet.add(ruutu.a)
      }
      // console.log('Käsitellään kuukaudet', kuukaudetCache)
      for (const asiakasAvain of asiakkaatSet.keys()) {
        const index = cacheIndexes.get(asiakasAvain)
        if (index || index === 0) {
          const asiakas: ListausAsiakas = cache[index]
          if (asiakas) {
            this.kasitteleAsiakkaanKuukausiruudut(asiakas, ruudutCache, kuukaudetCache)
            // console.log(asiakas)
          }
        }
      }
      this.kaikkiAsiakkaatObservable.next(cache)
    })

    const kasitellytObervable = raakadataObservable.pipe(
      withLatestFrom(this.naytettavatKuukaudetObservable),
      map(([changes, kuukaudet]) => {
        if (changes) {
          for (const change of changes) {
            if (change.type === 'added') {
              const listausAsiakas = this.kasitteleAsiakas(change.doc.data())
              this.kasitteleAsiakkaanSarakkeet(listausAsiakas, sarakeCache, sarakeArvotCache)
              this.kasitteleAsiakkaanKuukausiruudut(listausAsiakas, ruudutCache, kuukaudetCache)
              const index = cacheIndexes.get(change.doc.id)
              if (index !== undefined && index !== null) {
                cache[index] = listausAsiakas
                // console.log('Muokattu', change.doc.id, 'indeksissä', index)
              } else {
                cacheIndexes.set(change.doc.id, cache.length)
                cache.push(listausAsiakas)
                // console.log('Lisätty', change.doc.id)
              }
            } else if (change.type === 'modified') {
              const listausAsiakas = this.kasitteleAsiakas(change.doc.data())
              this.kasitteleAsiakkaanSarakkeet(listausAsiakas, sarakeCache, sarakeArvotCache)
              this.kasitteleAsiakkaanKuukausiruudut(listausAsiakas, ruudutCache, kuukaudetCache)
              const index = cacheIndexes.get(change.doc.id)
              cache[index] = listausAsiakas
              // console.log('Muokattu', change.doc.id, 'indeksissä', index)
            } else if (change.type === 'removed') {
              const index = cacheIndexes.get(change.doc.id)
              cacheIndexes.delete(change.doc.id)
              cache.splice(index, 1)
              let i = 0
              for (const a of cache) {
                cacheIndexes.set(a.k, i)
                i++
              }
              // console.log('Poistettu', change.doc.id, 'indeksistä', index)
            }
          }
        }
        return cache
      })
    )

    kasitellytObervable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(asiakkaat => {
      this.lataaObservable.next(false)
      this.kaikkiAsiakkaatObservable.next(asiakkaat)
    }, err => {
      this.lataaObservable.next(false)
      this._errorHandler.handleError(err)
    })

  }

  private kasitteleAsiakkaanSarakkeet(asiakas: ListausAsiakas, sarakkeet: KirjanpitajanSarakeHaulla[], sarakeArvotCache: Map<string, KirjanpitajanSarakkeidenArvotAsiakkaalle>) {
    if (!asiakas.sarakkeet) { asiakas.sarakkeet = [] } else { asiakas.sarakkeet.length = 0 }
    const arvot: KirjanpitajanSarakkeidenArvotAsiakkaalle = sarakeArvotCache.get(asiakas.k)
    const asiakkaanTiedotKirjanpitajalle = arvot?.v ?? {}
    for (const sarake of sarakkeet) {
      if (sarake.aktiivinen) {
        const sarakkeenArvo = asiakkaanTiedotKirjanpitajalle[sarake.avain]
        const tila = sarakkeenArvo?.v ? sarake.tilojenHakuMap.get(sarakkeenArvo.v) : sarake.tilojenHakuMap.get('oletus')
        asiakas.sarakkeet.push({
          sarakeAvain: sarake.avain,
          tilaAvain: tila ? tila.avain : null,
          arvo: tila ? tila.nimi : '',
          luokka: tila ? tila.vari : ''
        })
      }
    }
  }

  private kasitteleAsiakkaanKuukausiruudut(asiakas: ListausAsiakas, ruudutCache: Map<string, KirjanpitoKuukausiruutu>, kuukaudet: LocalMonth[]) {
    if (!asiakas.kuukaudet) {
      asiakas.kuukaudet = []
    } else {
      asiakas.kuukaudet.length = 0
    }

    for (const kuukausi of kuukaudet) {

      // Nykyinen kuukausi
      const kk = this._dateService.localMonthToNumber(kuukausi)
      const kuukaudenAvain = asiakas.k + kk
      let ruutu = ruudutCache.get(kuukaudenAvain)
      if (!ruutu) {
        ruutu = this._kirjanpitoKuukausistripService.annaUusiKirjanpitoRuutuListaus(asiakas, kk)
        ruudutCache.set(kuukaudenAvain, ruutu)
      }

      // Seuraava kuukausi
      const nextKk = this._dateService.lisaaKuukausiaKuukaudenNumero(kk, 1)
      const nextKuukaudenAvain = asiakas.k + nextKk
      let nextRuutu = ruudutCache.get(nextKuukaudenAvain)
      if (!nextRuutu) {
        nextRuutu = this._kirjanpitoKuukausistripService.annaUusiKirjanpitoRuutuListaus(asiakas, nextKk)
        ruudutCache.set(nextKuukaudenAvain, nextRuutu)
      }

      if (asiakas.u || asiakas.p === 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2') {
        this.kasitteleAsiakkaanKuukausiruutu(asiakas, ruutu, nextRuutu, this._stateService.lukemanTyyppiObservable.value)
      } else {
        this.kasitteleAsiakkaanKuukausiruutuVanha(asiakas, ruutu, nextRuutu, this._stateService.lukemanTyyppiVanhaObservable.value)
      }

    }
    // console.log(asiakas.kuukaudet)
  }

  private kasitteleAsiakkaanKuukausiruutu(asiakas: ListausAsiakas, ruutu: KirjanpitoKuukausiruutu, seuraavaRuutu: KirjanpitoKuukausiruutu, lukemanTyyppi: LukemanTyyppi) {

    const tiedot = ruutu as KuukausiruudunTiedot
    tiedot.luokat = this._kirjanpitoKuukausistripService.keraaRuudunLuokatUusin(asiakas, tiedot, seuraavaRuutu, 'lista', lukemanTyyppi)
    this._kirjanpitoKuukausistripService.asetaOikeaAlakulma(tiedot)
    this._kirjanpitoKuukausistripService.asetaVasenAlakulmaLista(tiedot, lukemanTyyppi)

    // if (asiakas.kuukaudet.length > index) {
    //   asiakas.kuukaudet[index] = tiedot
    // } else {
    asiakas.kuukaudet.push(tiedot)
    // }

  }

  private kasitteleAsiakkaanKuukausiruutuVanha(asiakas: ListausAsiakas, ruutu: KirjanpitoKuukausiruutu, seuraavaRuutu: KirjanpitoKuukausiruutu, lukemanTyyppi: LukemanTyyppiVanha) {

    const tiedot = ruutu as KuukausiruudunTiedot
    tiedot.luokat = this._kirjanpitoKuukausistripService.keraaRuudunLuokatVanha(asiakas, tiedot, seuraavaRuutu)
    this.asetaVasenAlakulmaVanha(tiedot)
    this.asetaOikeaAlakulmaVanha(tiedot)
    this.asetaVasenYlakulmaVanha(tiedot, lukemanTyyppi)

    // const existing = asiakas.kuukaudet.findIndex(a => a.a === ruutu.a && a.k === ruutu.k)
    // if (existing > -1) {
    //   asiakas.kuukaudet[existing] = tiedot
    // } else {
    asiakas.kuukaudet.push(tiedot)
    //   asiakas.kuukaudet.sort((a, b) => a.k - b.k)
    // }

  }

  private kasitteleAsiakas(asiakas: DatabaseListausAsiakas): ListausAsiakas {

    const la = asiakas as ListausAsiakas
    la.yritysmuoto = this.annaYhtiomuoto(asiakas.c)
    la.alvIlmoitusjakso = this.annaAlvjakso(la.a)
    la.omaverosaldo = (asiakas.o || asiakas.o === 0) ? this._currencyService.formatoiDesimaaliSailytaNollat(asiakas.o, 2, 'fi') : ''
    la.luokat = ''

    if (asiakas.r) {
      la.luokat += ' a-l-r-tr-o-h'
    }
    if (asiakas.bov) {
      la.luokat += ' a-l-r-tr-o-m'
    }

    if (asiakas.f === 'pun') {
      la.luokat += ' a-l-r-tr-pun'
    } else if (asiakas.f === 'sin') {
      la.luokat += ' a-l-r-tr-sin'
    } else if (asiakas.f === 'vsi') {
      la.luokat += ' a-l-r-tr-vsi'
    } else if (asiakas.f === 'kel') {
      la.luokat += ' a-l-r-tr-kel'
    } else if (asiakas.f === 'vih') {
      la.luokat += ' a-l-r-tr-vih'
    } else if (asiakas.f === 'vio') {
      la.luokat += ' a-l-r-tr-vio'
    }

    if (asiakas.bs) { la.synkronoi = true } else { delete la.synkronoi }
    if (asiakas.bsh) { la.sopimusHyvaksymatta = true } else { delete la.sopimusHyvaksymatta }
    if (asiakas.bpp) { la.palaveriPitamatta = true } else { delete la.palaveriPitamatta }
    if (asiakas.bpy) { la.pankkiyhteysPuuttuu = true } else { delete la.pankkiyhteysPuuttuu }
    if (asiakas.bpm) { la.pankkiyhteysEiOleLisattyMaksutapoihin = true } else { delete la.pankkiyhteysEiOleLisattyMaksutapoihin }
    if (asiakas.ria) { la.riskiarvioTekematta = true } else { delete la.riskiarvioTekematta }
    if (asiakas.rie) { la.edustajaTunnistamatta = true } else { delete la.edustajaTunnistamatta }

    return la
  }

  private asetaVasenYlakulmaVanha(ruutu: KuukausiruudunTiedot, valinta: LukemanTyyppiVanha) {
    if (valinta === 'minuutit') {
      ruutu.vasenYlakulma = ruutu.ka ? ruutu.ka : null
    } else if (valinta === 'tehot') {
      ruutu.vasenYlakulma = ruutu.ka && ruutu.h ? this.laskeTeho(ruutu) : null
    } else {
      ruutu.vasenYlakulma = null
    }
  }

  private asetaVasenAlakulmaVanha(ruutu: KuukausiruudunTiedot) {
    // if (ruutu.ka && ruutu.h) {
    //   ruutu.vasenAlakulma = this.laskeTeho(ruutu)
    // } else {
    ruutu.vasenAlakulma = null
    // }
  }

  private asetaOikeaAlakulmaVanha(ruutu: KuukausiruudunTiedot) {
    if (ruutu?.la && !ruutu?.le) { // Pelkästään käsiteltyjä: harmaa
      ruutu.oikeaAlakulma = ruutu.la
    } else if (!ruutu?.la && ruutu?.le) { // Pelkästään uusia: sininen
      ruutu.oikeaAlakulma = ruutu.le
    } else if (ruutu?.la && ruutu?.le) { // Sekä että: punainen, näytetään vaan uudet + boldina
      ruutu.oikeaAlakulma = ruutu.le
    } else {
      ruutu.oikeaAlakulma = null
    }
  }

  private laskeTeho(ruutu: KirjanpitoKuukausiruutu): number {
    return Math.round(ruutu.h / ruutu.ka * 60)
  }

  private annaAlvjakso(alvIlmoitusjakso: AlvIlmoitusjakso): string {
    switch (alvIlmoitusjakso) {
      case AlvIlmoitusjakso.KK1:
        return '1kk'
      case AlvIlmoitusjakso.KK3:
        return '3kk'
      case AlvIlmoitusjakso.KK12:
        return '12kk'
      case AlvIlmoitusjakso.EI:
        return 'Ei alv'
      default:
        return 'Ei tied.'
    }
  }

  private annaYhtiomuoto(muoto: Yritysmuoto | EiYhtiomuotoaYhtiomuoto): string {
    switch (muoto) {
      case EiYhtiomuotoaYhtiomuoto.YHTIOMUOTO_EI_TIEDOSSA:
        return 'Ei tied.'
      case Yritysmuoto.AVOINYHTIO:
        return 'Ay'
      case Yritysmuoto.KOMMANDIITTIYHTIO:
        return 'Ky'
      case Yritysmuoto.OSAKEYHTIO:
        return 'Oy'
      case Yritysmuoto.OSUUSKUNTA:
        return 'Osk'
      case Yritysmuoto.TOIMINIMI:
        return 'Tmi'
      case Yritysmuoto.YHDISTYS:
        return 'Ry'
      default:
        return 'Ei tied.'
    }
  }

}
